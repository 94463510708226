@import "../common/variables.scss";

.score-card-container {
  min-height: 60vh;
  flex-direction: row;
  margin: 0;
  border-radius: 5px;
  padding: 8px;
  margin-top: 20px;

  @media (min-width: $breakpoint-md) {
    display: flex;
    padding: 20px;
  }
}

.players-card-stats {
  flex: 1;
  align-content: center;
  background: linear-gradient(
      88.57deg,
      rgba(101, 177, 255, 0.33) 1.14%,
      rgba(101, 177, 255, 0) 128.17%
    ),
    linear-gradient(338.51deg, rgba(255, 174, 223, 0.8) -12.22%, rgba(255, 255, 255, 0.8) 97.71%);
  background-repeat: no-repeat;
  min-width: 340px;
  border-radius: 5px;
  z-index: 2;
  background-position: 42% 20%, center, center;
  background-size: 100%, 100%;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-sm) and (max-width: 1023px) {
    background: linear-gradient(
        88.57deg,
        rgba(101, 177, 255, 0.33) 1.14%,
        rgba(101, 177, 255, 0) 128.17%
      ),
      linear-gradient(338.51deg, rgba(255, 174, 223, 0.8) -12.22%, rgba(255, 255, 255, 0.8) 97.71%);
    background-position: 37% 49%, center, center;
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }

  @media (min-width: $breakpoint-md) {
    margin-bottom: 100px;
  }
}

.players-card-boarder {
  background-image: url(../../images/playerBorder.png);
  background-repeat: no-repeat;
  z-index: 200;
  background-position: 42% 20%;
  background-size: 80%;
}

.player-category-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 9px 40px 25px;
  list-style: none;

  @media (min-width: $breakpoint-sm) {
    border-left: 2px solid rgba(88, 44, 114, 0.5);
    margin: 15px 0;
    padding: 0;
  }

  @media (min-width: $breakpoint-md) {
    align-content: space-around;
    border-left: unset;
    flex: 1;
    margin: 0;
  }
}

.player-stats-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  @media (min-width: $breakpoint-md) {
    flex-direction: column;
    height: 100%;
    justify-content: unset;
  }
}

.col-2 {
  display: flex;
  flex-direction: column;
  flex: 5;
}

.content {
  display: flex;
  flex-direction: row;
}

.content > score-card-table {
  flex: 3;
  min-height: 52vh;
}

header,
footer {
  background: yellowgreen;
  height: 20vh;
}

.players-card-header2 {
  margin: 30px 0 10px 30px;
  height: 40px;
  left: 525px;

  background: rgba(206, 32, 40, 0.41);
  box-shadow: 2px 2px 10px #ce2028;
  box-shadow: 2px -1px 13px 8px rgba(206, 32, 40, 0.41);
}

.score-card-table {
  padding-left: 30px;
  flex: 3;
  min-height: 50vh;
}

.players-card-header {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0 10px 30px;
  height: 40px;
  left: 525px;
  background: rgba(206, 32, 40, 0.41);
  box-shadow: 2px 2px 10px #ce2028;
  box-shadow: 2px -1px 13px 8px rgba(206, 32, 40, 0.41);
}

.item {
  width: 48%;
  height: 100px;
  margin-bottom: 2%;
}

.player-rank-label {
  font-style: normal;
  font-family: "WorkSans";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding-right: 20px;

  @media (max-width: $breakpoint-sm) {
    font-size: 14px;
  }
}

.season-board-link {
  font-style: normal;
  font-family: "WorkSans";
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #df1e2d;
  padding-right: 20px;

  @media (max-width: $breakpoint-sm) {
    font-size: 14px;
    padding-right: 0px;
  }
}

.content-align-end {
  align-content: end;
  display: inline-flex;
  flex-wrap: wrap;
}

.players-card-label {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 20px;
}

.players-card-background {
  width: 337px;
  height: 613px;
  left: 130px;
  top: 445px;

  background: linear-gradient(
    302.36deg,
    rgba(206, 32, 40, 0.25) 18.15%,
    rgba(6, 85, 165, 0.25) 78.07%
  );
  border-radius: 5px;
}

.name-company-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    width: 40%;
  }

  @media (min-width: $breakpoint-md) {
    width: 100%;
  }
}

.players-name {
  align-items: center;
  color: #0054a6;
  display: flex;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  justify-content: center;
  line-height: 41px;
  margin: 0 25px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $breakpoint-sm) {
    border-bottom: unset;
    padding: 35px 0 0 0;
  }
}

.players-company-name {
  align-items: center;
  border-bottom: 2px solid rgba(88, 44, 114, 0.5);
  color: #0054a6;
  display: flex;
  font-family: "WorkSans";
  font-weight: 400;
  font-size: 20px;
  justify-content: center;
  line-height: 24px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  min-width: 88%;

  @media (min-width: $breakpoint-sm) {
    border-bottom: unset;
    margin: 0;
    padding: 0;
  }

  @media (min-width: $breakpoint-md) {
    border-bottom: 2px solid rgba(88, 44, 114, 0.5);
    margin-top: 30px;
    padding-bottom: 30px;
  }
}

.player-stats-label {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $dark;
  line-height: 19px;
}

.player-stats-category {
  padding: 5px;
  height: 90px;
  margin-top: 10px;
  line-height: 150px;
  color: white;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  width: 50%;

  @media (min-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    justify-content: center;
  }

  @media (min-width: $breakpoint-md) {
    height: 100px;
    width: 248px;
  }
}

.season-points {
  margin-top: 80px;
}

.season-figures {
  font-family: "WorkSans";
  font-style: normal;
  color: $dark;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-top: 15px;
}

.green-rank {
  color: #49f250;
}

.blue-rank {
  color: #0054a6;
}

.season-winning {
  background: linear-gradient(88.33deg, #0655a5 -3.53%, #ce2028 129.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.players-card-table {
  margin-top: -45px;
}
