@import "../common/variables.scss";

.search-box {
  background: url(../../images/magnifyingGlass.png);
  background-repeat: no-repeat;
  padding: 3px;
  width: 430px;
  font-size: 1em;
  padding-left: 20px;
  margin-left: 7px;
  text-overflow: "";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $light;
  padding: 4px 17px 4px 10px;
  margin: 0px 10px;
  height: 50px;
  border: 1px solid #999;
  font-size: 16px;
  border-radius: 5px;
  background-position: 365px;
  position: relative;
}

.manage-players,
.winners-list {
  .search-button {
    border-radius: 5px;
    background-color: black;
    color: white;
    width: 130px;
    text-align: center;
    border: none;
    height: 35px;
    outline: none;
  }

  .search-banner {
    margin-bottom: 23px;
    display: flex;
    position: relative;
    max-width: 600px;
    z-index: 2;
  }

  .search-icon {
    position: absolute;
    top: 4px;
    left: 11px;
    z-index: 2;
  }
}

.search-banner__results {
  position: absolute;
  width: 430px;
  min-height: 57px;
  border: 2px solid #e5e5e5;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: #071427;
  z-index: 5;
  font-size: 16px;
  line-height: 17px;
  display: none;
  margin-left: 10px;
  margin-top: -5px;
  &--show {
    display: block;
  }

  &- {
    // search-banner__results-row
    &row {
      height: 57px;
      display: flex;
      color: $light;
      align-items: center;
      position: relative;
      padding: 0 21px;
      background-color: #071427;

      &:first-of-type {
        padding: 22px;
        padding-left: 6px;
        margin-left: 17px;
        border-top: 2px solid #e5e5e5;
        width: calc(100% - 38px);
        /* background: #a9e2ff; */
        margin-top: 12px;
      }

      &:not(:last-of-type):after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 15px;
        bottom: 0;
        height: 1px;
        width: calc(100% - 30px);
        background: rgba(74, 68, 124, 0.1);
      }

      &:last-of-type {
        border-radius: 0 0 6px 6px;
      }

      &:hover,
      &.active {
        // background-color: #d7d1d6;
        font-weight: bold;
      }
    }
    // search-banner__results-row-data
    &row-data {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // search-banner__results-name
    &name {
      font-weight: 600;
      width: 31%;
    }
    // search-banner__results-company
    &company {
      width: 34%;
    }
    // search-banner__results-email
    &email {
      width: 35%;
    }
    // search-banner__results-phone
    &phone {
      width: 105px;
    }
  }
}

.typeahead-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: none;

  // typeahead-background--show
  &--show {
    display: block;
  }
}
