@import "../common/variables.scss";

.hof.sign-up-form-container {
  background: linear-gradient(351.8deg, rgba(255, 0, 13, 0.5) -90%, rgba(73, 164, 255, 0.3) 85.45%);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: color;
  padding: 36px 10px;
  text-align: center;

  form {
    padding-top: 42px;
    margin: 0 auto;
    max-width: 500px;
  }

  input {
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .section-title {
    color: #1c3142;
    font-size: 24px;

    @media (min-width: 1024px) {
      font-size: 36px;
      margin-bottom: 38px;
    }
  }

  .sign-up-button {
    background-color: #582c72;
    border-radius: 82px;
    color: white;
    padding: 14px 50px;
    margin-bottom: 50px;
  }

  @media (min-width: 1024px) {
    input {
      margin-bottom: 28px;
    }
  }
}

.splash.sign-up-form-container {
  background: linear-gradient(
      339.51deg,
      rgba(0, 84, 166, 0.3) -6.34%,
      rgba(88, 44, 114, 0.3) 49.09%,
      rgba(206, 32, 40, 0.3) 99.09%
    ),
    url("../../images/marketing/sign-up-form-building.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: color;
  position: relative;

  .content-container {
    padding: 32px 10px;
  }

  .login-signup-modal {
    position: absolute;
  }

  .title {
    color: #24335a;
    font-size: 22px;
    font-weight: 700;
    padding: 20px;
  }

  .button {
    background-color: $purple;
    border: unset;
    border-radius: 82px;
    display: block;
    color: white;
    font-weight: 700;
    height: 64px;
    margin: 0 auto;
    padding: 20px;
    text-decoration: none;
    width: 314px;
  }

  .text {
    margin: 20px 10px;
    max-width: 760px;
  }

  .video-player-container {
    margin: 0 auto;
    max-width: 700px;
  }

  @media (min-width: $breakpoint-sm) {
    .content-container {
      padding: 32px 50px;
    }

    .text {
      margin: 20px auto;
    }
  }
  @media (min-width: $breakpoint-md) {
    .title {
      font-size: 36px;
      padding-top: 40px;
    }
  }
}
