@import "bootstrap.min.css";
@import "../../content/styles/common/variables.scss";
/* PLEASE DO NOT ADD ADDITIONAL IMPORTS IN HERE - IMPORT IN THE COMPONENT OR APP AREA */

/* Work Sans */

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Light.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-Bold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Poppins"; // TODO
  src: url("/fonts/Poppins-ExtraBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: WorkSans, Arial, Helvetica, sans-serif;
  margin: 0;
  font-weight: normal;
  background-image: linear-gradient(302.36deg, #1f1f1f 0%, #32383e 100%);
  height: 100%;
  font-size: 16px;
  background-repeat: no-repeat;

  // TODO remove these styles once all locked() logic is removed. Access is now based on eligibility from server
  // body.locked {
  //   overflow-y: hidden !important;
  //   position: sticky;
  //   top: 0;
  //   touch-action: none;
  //   -ms-touch-action: none;
  //   -webkit-overflow-scrolling: none;
  //   overscroll-behavior: none;
  // }

  // body.locked:before {
  //   position: absolute;
  //   content: " ";
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 1000%;
  //   background: rgba(0, 0, 0, 0.6);
  //   z-index: 3;
}

body.modal-active {
  overflow-y: hidden !important;
}

.modal-wrapper.active {
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  position: fixed;
  max-width: 1918px;
  width: 100%;
  z-index: 20;
  top: 0;
}

/* HELPER CLASSES */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

@media only screen and (max-width: 1440px) {
  .indent-col {
    padding-left: 10px !important;
  }
}

/* Global Setting */
.page-heading {
  text-align: left !important;
  text-transform: uppercase;
  color: $purple !important;
  font-size: 36px !important;
  line-height: 1em !important;
  margin-bottom: 34px !important;
  font-weight: 700;
  position: sticky;
  top: 60px;
  z-index: 4;

  &.indent-title {
    padding-left: 30px;
    margin-top: 30px;
    margin-left: -20px;
    width: calc(100% + 20px);
  }
}
