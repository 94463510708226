@import "variables.scss";

.game-schedule {
    font-family: "WorkSans";

    &.hidden {
        display: none;
    }

    .game-schedule-overlay {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    .game-schedule-modal {
        position: fixed;
        background-color: #fff;
        top: 40%;
        left: 50%;
        width: 100%;
        max-width: 418px;
        transform: translate(-50%, -20%);
        border-radius: 5px;
        z-index: 2000;
        height: 300px;

        @media (max-height: 400px) {
            height: 265px;
            max-height: 265px;
        }


        @media (min-width: $breakpoint-sm) {
            height: 394px;
            width: 97%;
            top: 21%;
            max-width: 739px;
        }

        @media (min-width: $breakpoint-md) {
            top: 30%;
            width: 75%;
        }

        @media (min-width: $breakpoint-lg) {
            height: 493px;
            width: 50%;
            top: 40%;
            min-width: 981px;
        }

        @media only screen and (max-width: 600px) {
            margin: 0px;
            box-sizing: border-box;
        }

        .game-schedule-modal-header {
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            line-height: 15px;
            padding-top: 20px;

            @media (min-width: $breakpoint-sm) {
                font-size: 20px;
                line-height: 21px;
                padding-top: 25px;
            }

            @media (min-width: $breakpoint-lg) {
                font-size: 24px;
                line-height: 26px;
                padding-top: 54px;
            }
        }
    }

    .btn-close {
        border: 0 none;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: #000;
        opacity: 1;

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }

    .game-schedule-table-container {
        font-size: 14px;
        min-height: 250px;
        max-width: 500px;
        width: 100%;
        padding: 5px;

        @media (min-width: $breakpoint-sm) {
            padding: 8px;
            max-width: 728px;
            margin: 0 auto;
        }

        @media (min-width: $breakpoint-md) {
            max-width: 750px;
            margin: initial;
            font-size: 16px;
        }

        @media (min-width: $breakpoint-lg) {
            max-width: 982px;
        }

        .schedule-table {
            max-width: 100%;
            min-width: 100%;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 30px;
            overflow: hidden;
            border-collapse: collapse;

            thead {
                position: relative;
                background-color: transparent;

                tr {
                    background-color: $purple;
                    border: none !important;
                }

                th {
                    padding: 3px 10px;
                    line-height: 18px;
                    font-size: 12px;
                    font-weight: 500;
                    border-right: solid 2px #fff;
                    color: #fff;
                    text-align: center;


                    @media (min-width: $breakpoint-sm) {
                        padding: 10px;
                        line-height: 20px;
                        font-size: 20px;
                    }

                    @media (min-width: $breakpoint-md) {
                        padding: 7px;
                        line-height: 24px;
                        font-size: 16px;
                    }

                    @media (min-width: $breakpoint-lg) {
                        font-size: 24px;
                    }
                }
            }

            tbody {
                tr:nth-of-type(odd) {
                    background-color: #fff;
                }

                tr:nth-of-type(even) {
                    background-color: rgba(37, 6, 19, 0.03);
                }

                tr>td {
                    border-right: solid 3px #fff;
                }

                td {
                    padding: 5px 7px;
                    text-align: center !important;
                    font-size: 14px;
                    background-color: initial;

                    @media (min-width: $breakpoint-sm) {
                        padding: 10px;
                        font-size: 16px;
                    }

                    @media (min-width: $breakpoint-md) {}

                    @media (min-width: $breakpoint-lg) {
                        font-size: 20px;
                    }
                }

                td:first-child {
                    width: initial;
                }

            }



        }
    }

}