@import "../common/variables.scss";

/* TODO: This stylesheet has very powerful styles and we have to use !important to override its children. Let's fix this. */

.table-container {
  font-size: 24px;
  min-height: 500px;
  margin: 0 auto;
  width: 100%;
  max-width: 1500px;
  padding: 20px;

  @media only screen and (max-width: 550px) {
    padding: 0px 5px 0px 5px;
  }

  .table-wrapper {
    padding-bottom: 35px;
    position: relative;
  }

  table {
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
  }

  thead {
    color: $dark;
    font-family: "WorkSans";
    font-size: 16px;
    letter-spacing: -0.09px;
    line-height: 26px;
    text-align: left;
    position: relative;
    background-color: transparent;
    border-bottom: 1px solid #0056b6;

    td {
      padding: 18px 0;
      font-size: 16px;
      font-weight: bold;
      color: $dark;

      &.header-status {
        text-align: right;
      }
    }

    td.textleft {
      text-align: left;
    }

    td:last-child {
      text-align: right;
    }
  }

  tbody {
    td {
      font-size: 16px;
      color: $dark;
      padding: 10px 0;
      text-align: left;

      &.faq-data {
        position: relative;
      }

      .inputbid {
        font-size: 16px;
        text-align: right;
      }

      .action-button__wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        @media (min-width: $scoreboard-edit-buttons-breakpoint1) {
          flex-direction: row;
        }
      }

      button.action-button {
        background-color: #582c72;
        border: 0;
        border-radius: 4px;
        font-size: 13px;
        padding: 8px 11px;
        color: #fff;
        text-transform: uppercase;
        border-radius: 40px;

        &.edit {
          @media (min-width: $scoreboard-edit-buttons-breakpoint1) {
            margin-right: 0.5rem;
          }
        }
      }

      button.action-button-reverse {
        background: transparent;
        border: 1px solid #582c72;
        border-radius: 4px;
        font-size: 13px;
        padding: 8px 11px;
        color: #582c72;
        text-transform: uppercase;
        margin-top: 0.3125rem;
        border-radius: 40px;

        @media (min-width: $scoreboard-edit-buttons-breakpoint1) {
          margin-top: 0;
        }
      }

      .action-button,
      .action-button-reverse {
        width: 4.75rem;
        height: 2.0625rem;

        @media (min-width: $scoreboard-edit-buttons-breakpoint1) {
          width: initial;
        }
      }

      .bet-controls {
        display: flex;
      }

      .input-scoreboard-bet {
        color: $dark;
        background-color: transparent;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #7b7e8a;
        border-right: none !important;
        font-family: WorkSans;
        font-weight: 300;
        width: 78%;
        height: 32px;
        font-size: 16px;
        text-align: left;
        padding: 2px 5px;
        margin-bottom: 3px;
        transition: all ease-in 0.2s;
        min-width: 75px;

        &.purple,
        &.purple:focus {
          border: 1px solid $purple;
        }

        &:focus,
        &:active {
          border-color: $purple;
          outline: none !important;
        }

        @media (max-width: 768px) {
          flex-grow: 0;
          width: 45%;
          padding-left: 5px !important;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          width: 55%;
        }
      }

      button.action-button-bet {
        width: 190px;
        height: 32px;
        border-radius: 0 5px 5px 0;
        border: 1px;
        background-color: #582c72;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        min-width: min-content;

        @media (max-width: 768px) {
          width: 36%;
          min-width: min-content;
        }

        &:disabled {
          border: 1px solid #627487;
          background: #627487;
        }

        &.edit {
          @media (min-width: $scoreboard-edit-buttons-breakpoint1) {
            margin-right: 0.5rem;
          }
        }

        & span {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid rgba(0, 86, 182, 0.3);

      &.scoreboard {
        height: 69px;

        @media only screen and (max-width: 550px) {
          height: 49px;
        }
      }

      &.scoreboard-score-card-page {
        height: 50px;
      }
    }

    td span.content {
      display: inline-block;
    }

    td input.content {
      width: 80%;
      padding: 3px 5px;
      font-size: 15px;
      text-align: right;
    }

    .status.time-extended {
      display: none;
    }

    .property-address a {
      color: #0054a6;
      font-weight: 400;
      padding-left: 10px;
      display: block;
      text-decoration: none;

      @media (max-width: 550px) {
        text-overflow: ellipsis;
        width: 160px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    tr:last-child td span {
      border-bottom: 0 none;
    }
  }

  .faq-section {
    padding: 0 8px;
  }
}

/* ---------------------------------------------------------
	Responsive Layout
	---------------------------------------------------------- */
@media only screen and (max-width: 550px) {
  .property-address {
    text-overflow: ellipsis;
    max-width: 220px;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
  }

  table td:first-child {
    width: 160px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
}

@media only screen and (max-width: $scoreboard-breakpoint4) {
  .scoreboard-cell-property {
    .content {
      max-width: 100%;
    }
  }

  .property-address {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .table-wrapper {
    overflow-x: scroll;
    border-radius: 10px;
    scrollbar-color: #d62929 #f5f5f5;
    scrollbar-width: thin;

    /*
			*  STYLE 2
			*/
    &#scroll-x-style::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &#scroll-x-style::-webkit-scrollbar {
      width: 12px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &#scroll-x-style::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
  }

  table {
    width: 100%;
    min-width: $table-width;
  }

  table td:first-child,
  table td:nth-child(2) {
    position: sticky;
  }

  table td:first-child {
    background-color: $light;
    left: 0px;
    z-index: 1;
  }

  table.broker-experts td:nth-child(2) {
    background-color: $background-black;
    max-width: 75px;
    left: 10px;

    .broker-name {
      max-width: 100%;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 4px;
      vertical-align: middle;
    }
  }

  .logo-wrapper > * {
    flex: 1 1 100%;
  }

  @media only screen and (max-width: $scoreboard-breakpoint4) {
    .broker-name::after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      right: -12px;
      z-index: 1;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%);
      width: 12px;
    }
  }
}

.header-reserve {
  color: #fff;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}

.green-text {
  color: #49f250;
}

.right-table-padding {
  padding-right: 30px;
}

.left-table-padding {
  padding-left: 5px;
}

.bet-cell-wrapper {
  padding-left: 10px;
  min-width: 235px;
}

.bet-text-readonly {
  padding-left: 15px;
}
