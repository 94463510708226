.confetti-cannon-div {
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2010;
    

    .confetti-cannon-canvas {
        width: 100%;
        height: 100%;
    }
}
