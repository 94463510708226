@import "./variables.scss";

.can-i-play-container {
  padding: 25px 10px 100px;
  text-align: center;

  .title {
    padding-bottom: 12px;
  }

  .subtitle {
    line-height: 32px;
  }

  .bob-logo-container {
    display: none;
  }

  .company-logos {
    display: none;
  }

  .list-company-container {
    margin-top: 30px;
  }

  .can-i-play-list {
    padding-left: 0;

    li {
      display: flex;
      line-height: 30px;
      list-style: none;
      text-align: left;

      + li {
        margin-top: 30px;
      }

      &:before {
        background: url("../../images/colored-checkmark.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 24px;
        margin-top: 8px;
        width: 24px;
      }

      &:last-of-type::before {
        background: none;
      }

      &:last-of-type:hover {
        color: rgba(0, 84, 166, 1);
        text-decoration: underline;
      }
    }
  }

  .logo {
    max-width: 350px;
    width: 100%;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 124px 48px 130px;

    .list-company-container {
      display: flex;
      margin-top: 70px;
    }

    .can-i-play-list {
      width: 50%;
      li + li {
        margin: 55px 0;
      }
    }

    .company-logos {
      display: flex;
      flex-direction: column;
      margin-left: 90px;
      width: 50%;
    }

    .logo-container {
      text-align: center;
    }

    .logo-container + .logo-container {
      margin-top: 70px;
    }
  }

  @media (min-width: $breakpoint-md) {
    .bob-logo-container {
      display: block;
      margin: 0 auto;
      max-width: 570px;
      width: 33%;

      .rules {
        margin-top: 30px;
        cursor: pointer;

        &:hover {
          color: rgba(0, 84, 166, 1);
          text-decoration: underline;
        }
      }

      .jet {
        margin-top: 50px;
        max-width: 240px;
      }
    }

    .list-company-container {
      justify-content: space-between;
      margin-left: 10px;
    }

    .can-i-play-list,
    .company-logos {
      width: 33%;
      
      li:last-of-type {
        display: none;
      }
    }

    .company-logos {
      margin-left: 0;
    }

    .bob-logo-container {
      margin: 0 30px;
    }
  }

  @media (min-width: $breakpoint-lg) {
    .list-company-container {
      margin-left: 50px;
    }
  }
}

#rules-modal-container {
  text-align: initial;
}