@import "./variables.scss";

.how-it-works-container {
  background: linear-gradient(
      182.59deg,
      rgba(255, 255, 255, 0.8) 4.23%,
      rgba(94, 167, 242, 0.336) 30.21%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    url("../../images/marketing/mobile-runway.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 28px 10px 50px 10px;

  .game-explanation-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 42px;
  }

  .game-explanation-subtext {
    font-size: 20px;
    padding: 0 30px;
  }

  .explanation-box {
    padding: 10px 0;
    text-align: left;
    width: 50%;

    .number {
      color: #0054a6;
      font-family: "Poppins";
      font-size: 100px;
      line-height: 1;
      opacity: 0.4;
    }

    .subtitle,
    .description {
      font-family: "WorkSans";
    }

    .subtitle {
      color: #0655a5;
      font-size: 22px;
      font-weight: 600;
    }

    &:nth-of-type(even) {
      .left-border {
        border-left: 1px solid rgba(0, 84, 166, 0.3);
        padding-left: 10px;
      }
    }

    &:nth-of-type(odd) {
      padding-right: 10px;
    }
  }

  .learn-more-button {
    background-color: $purple;
    border-radius: 82px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    padding: 24px 50px;
    margin: 0 auto;
    margin-top: 26px;
    max-width: 314px;

    &:hover {
      -webkit-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      -moz-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    }

    a {
      text-decoration: none;
      &:hover {
        color: unset;
      }
    }
  }

  .highlighted-color {
    background-clip: text;
    background-image: linear-gradient(255.13deg, #ce2028 4.95%, #0054a6 93.35%);
    color: transparent;
    font-size: 22px;
    font-weight: 700;
    -webkit-background-clip: text;
  }

  @media (min-width: $breakpoint-sm) {
    background: linear-gradient(
        182.59deg,
        rgba(255, 255, 255, 0.8) 4.23%,
        rgba(94, 167, 242, 0.336) 30.21%,
        rgba(255, 255, 255, 0.8) 100%
      ),
      url("../../images/marketing/tablet-runway.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .explanation-box {
      width: 33%;

      &:last-child {
        .left-border {
          border: 0;
        }
      }

      &:nth-of-type(even),
      &:nth-of-type(odd) {
        padding: 0 20px;
      }

      &:nth-of-type(even) {
        .left-border {
          border-left: unset;
        }
      }

      + .explanation-box {
        .number {
          padding-left: 20px;
        }
        .left-border {
          border-left: 1px solid rgba(0, 84, 166, 0.3);
          padding-left: 20px;
          min-height: 200px;
        }
      }

      &:nth-of-type(4) {
        .number {
          padding-left: 0;
        }
        .left-border {
          border-left: unset;
          padding-left: 0;
        }
      }
    }

    .game-explanation-subtext {
      margin: 0 auto;
      padding: 0;
    }
  }

  @media (min-width: $breakpoint-md) {
    background: linear-gradient(
        182.59deg,
        rgba(255, 255, 255, 0.8) 4.23%,
        rgba(94, 167, 242, 0.336) 30.21%,
        rgba(255, 255, 255, 0.8) 100%
      ),
      url("../../images/marketing/desktop-runway.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    .game-explanation-container {
      margin: 14px 0;
      justify-content: center;
    }

    .explanation-box {
      max-width: 300px;
      padding-right: 20px;
      width: 20%;

      .subtitle {
        max-width: 300px;
      }

      .description {
        font-size: 18px;
      }

      &:last-child {
        .left-border {
          border: 0;
        }
      }

      &:nth-of-type(even),
      &:nth-of-type(odd) {
        padding: 0 10px;
        .left-border {
          border-left: unset;
          padding-left: 20px;
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(4) {
        .number,
        .left-border {
          padding-left: 20px;
        }
      }

      + .explanation-box {
        .left-border {
          border-left: 1px solid rgba(0, 84, 166, 0.3);
        }
      }
    }
  }
}

.bthen-header {
  margin-bottom: 52px;
}
