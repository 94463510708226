@import "variables.scss";

.accept-rules,
.static-rules,
.limit-reached,
.winner-has-address,
.review-properties {
  .btn-close {
    border: 0 none;
    background: transparent;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    color: #000;
    opacity: 1;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &.hidden {
    display: none;
  }

  .rules-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .review-properties-modal,
  .limited-reached-modal {
    height: auto !important;

    @media only screen and (max-width: 600px) {
      width: calc(100% - 40px);
      position: fixed;
      margin: 0px;
      box-sizing: border-box;
    }
  }

  .winner-has-address-modal {
    justify-content: center;
    height: 450px !important;
    max-height: 450px !important;
  }

  .congrats-header {
    font-size: 17px;
    font-weight: 700;
    display: flex;
    justify-content: center;
  }

  .congrats-background {
    background: url("../../images/purple_reath.png");
    text-align: center;
    height: 250px;
    background-size: 325px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .congrats-text {
    font-size: 25px;
    color: #24335a;
    font-weight: 700;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .instruction-text {
    font-size: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .limited-reached-modal {
    height: 225px !important;
    max-height: 225px !important;
    @media only screen and (max-width: 600px) {
      height: 270px !important;
      max-height: 270px !important;
    }
  }

  .rules-modal,
  .rules-content-modal {
    position: fixed;
    background-color: #ececec;
    top: 20%;
    width: 100%;
    max-width: 904px;
    left: 50%;
    transform: translate(-50%, -20%);
    border-radius: 5px;
    z-index: 2000;
    height: 80vh;
    max-height: 80vh;

    @media (max-height: 400px) {
      height: 265px;
      max-height: 265px;
    }

    @media only screen and (max-width: 600px) {
      width: calc(100% - 40px);
      position: fixed;
      margin: 0px;
      box-sizing: border-box;
      height: 620px;
      max-height: 620px;
    }

    .rules-header {
      text-align: center;
      background-color: $light;
      border-radius: 4px 4px 0 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      h2 {
        padding-top: 24px;
        padding-bottom: 24px;
        font-weight: bold;
        font-size: 16px !important;
        text-transform: uppercase;
        color: #000;

        @media (max-height: 400px) {
          padding-top: 15px;
          padding-bottom: 12px;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #808080;
    }

    .rules-body-accept {
      padding-bottom: 80px;

      @media only screen and (max-height: 800px) {
        bottom: 0 !important;
        padding-bottom: 90px;
      }

      @media (max-width: 600px) {
        bottom: 0 !important;
        padding-bottom: 142px;
      }

      @media only screen and (min-height: 800px) {
        bottom: 67px !important;
      }
    }

    .rules-body {
      position: absolute;
      top: 77px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      color: $dark;
      margin-left: 7px;
      overflow-y: auto;

      @media (max-height: 400px) {
        top: 55px;
      }

      table {
        width: 100%;
      }

      h3 {
        font-weight: normal !important;
        font-size: 14px !important;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 14px !important;
      }

      h5 {
        font-size: 14px !important;
      }

      p {
        font-size: 14px !important;
      }

      li {
        font-size: 14px !important;
      }
    }

    .review-proprties-body {
      height: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      color: $dark;

      table {
        width: 100%;
      }

      h3 {
        font-weight: normal !important;
        font-size: 14px !important;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 14px !important;
      }

      h5 {
        font-size: 14px !important;
      }

      p {
        font-size: 14px !important;
      }

      li {
        font-size: 14px !important;
      }
    }
    .rules-controls {
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      background-color: $light;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 0 0 5px 5px;
      padding: 19px 38px 0 38px;
      z-index: 2;

      @media (max-width: 1024px) {
        padding: 19px 20px 0 20px;
      }

      @media (max-width: 600px) {
        padding-top: 10px;
        bottom: 0;
      }

      @media only screen and (max-height: 800px) {
        bottom: -26px;
        padding-top: 5px;
      }

      .btn {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media only screen and (max-height: 850px) {
        .btn {
          width: calc(50% - 10px);
          max-width: 402px;
          padding-bottom: 20px;
          padding-top: 20px;
          color: $light;
          font-weight: bold;
          margin: 10px 20px 10px 0;
          height: 40px;
          padding: 0;

          &.cancel-button {
            // background-color: #9f9f9f;
            color: rgba(108, 166, 224, 0.77);
            border-radius: 20px;
            border: solid 1px rgba(108, 166, 224, 0.77);
            @media only screen and (max-width: 850px) {
              margin-right: 20px;
            }

            @media only screen and (max-width: 500px) {
              font-size: calc(1rem - 6px);
            }
          }

          &.accept-button {
            background-color: #582c72;
            margin-right: 0;
            border-radius: 20px;

            @media only screen and (max-width: 850px) {
              margin-right: 0;
            }

            @media only screen and (max-width: 500px) {
              font-size: calc(1rem - 6px);
            }
          }
        }
      }

      @media only screen and (min-height: 850px) {
        .btn {
          margin: 19px 20px 20px 0;
          width: calc(50% - 10px);
          max-width: 402px;
          padding-bottom: 20px;
          padding-top: 20px;
          color: $light;
          font-weight: bold;

          @media only screen and (max-width: 500px) {
            width: calc(50% - 15px);
          }

          &.cancel-button {
            background-color: #9f9f9f;

            @media only screen and (max-width: 500px) {
              font-size: calc(1rem - 6px);
              margin-left: 0;
            }
          }

          &.accept-button {
            background-color: #582c72;
            margin-right: 0;

            @media only screen and (max-width: 500px) {
              font-size: calc(1rem - 6px);
            }

            @media only screen and (max-width: 500px) {
              margin-left: 0;
            }
          }
        }
      }
    }

    .check-box-wrap {
      margin-top: 19px;
    }

    .checkbox {
      height: 17px;
      width: 17px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .checkbox-text {
      font-size: 14px;
      vertical-align: top;

      @media (max-width: 600px) {
        font-size: 13px;
      }
    }
  }
}

.static-rules .rules-modal,
.static-rules .rules-content-modal {
  position: fixed;
  top: 20%;
  width: 100%;
  max-width: 904px;
  left: 50%;
  transform: translate(-50%, -20%);
}

.static-rules .rules-overlay,
.limit-reached .rules-overlay,
.winner-has-address .rules-overlay,
.review-properties .rules-overlay {
  position: fixed;
}

.limit-reached .rules-modal,
.winner-has-address .rules-modal,
.review-properties .rules-modal {
  @media only screen and (min-width: 600px) {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 556px;
    transform: translate(-50%, -50%);
  }
}

.winner-has-address .rules-header,
.limit-reached .rules-header {
  display: block;
  overflow: hidden;
  position: relative;
  height: 50px;
  background-color: transparent !important;
}

.winner-has-address .rules-modal .rules-body {
  height: auto;
  padding: 0 30px 30px 30px;
}

.limit-reached .rules-modal .rules-body {
  height: auto;
  padding: 0 40px 30px 40px;
}

.winner-has-address .rules-modal .rules-body {
  h2 {
    font-weight: bold !important;
    text-align: center;
    font-size: 16px !important;
  }

  p {
    text-align: center;
    font-size: 14px !important;
  }
}

.review-properties .rules-modal .review-proprties-body {
  text-align: center;
  color: #3c3c3c;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;

  &.noscroll {
    overflow: hidden;
  }

  .black-background {
    background: url("../../images/marketing/hero-cars-mobile.png");
    background-size: cover;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.85);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .btn-close {
      color: #fff;
    }

    .inside-text {
      padding: 50px 30px 30px 30px;
      font-size: 20px;

      @media only screen and (max-width: 325px) {
        font-size: 12px;
      }

      @media only screen and (min-width: 326px) and (max-width: 420px) {
        font-size: 15px;
      }

      .blue-text {
        color: #1982ec;
        margin-bottom: 12px;
      }
      .red-text {
        color: #ce2028;
      }
    }
  }

  .modal-text {
    padding: 30px 50px;
  }

  .review-link {
    margin-top: 10px;
    font-size: 16px;
    color: #0056b6;
    text-decoration: underline;
  }
}

.review-properties .rules-modal .rules-controls {
  background-color: #ececec;
}

table.rules-table {
  width: 100%;
  min-width: 100%;
  margin-bottom: 1rem;

  td {
    background-color: transparent !important;
  }
}

.accept-text-bold {
  font-weight: 600;
  vertical-align: top;
}
