@import "style_main.scss";
@import "style_section.scss";
@import "landing.scss";
@import "game-rules.scss";
@import "../common/header.scss";
@import "../common/footer.scss";

.slash-section-wrapper {
  color: #000000;
  font-weight: 300;
  margin-top: 0 !important;
  background: #ffffff;
  padding: 4.5rem 3rem;
  line-height: 1.5;
  font-size: 18px;

  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#pagecontent {
  padding: 10px 0;
}

#cs-body-wrapper #cs-section-content.landing-page {
  overflow-x: hidden;
}

// Tablet Settings
@media only screen and (max-width: 768px) {
  .open-video-modal-button-tablet {
    display: inline !important;
    height: 107px;
    left: 0;
    right: 0;
    top: 265px;
  }
}

// Tablet Settings
@media only screen and (max-height: 768px) and (max-width: 1201px) {
  .open-video-modal-button-tablet {
    display: inline !important;
    height: 107px;
    left: 0;
    right: 0;
    top: 315px;
  }
}

// Phone Settings Portrait
@media only screen and (max-width: 480px) {
  .open-video-modal-button-mobile {
    display: inline !important;
    height: 107px;
    left: 0;
    right: 0;
    top: 225px;
  }
}

// Phone Settings Landscape
@media only screen and (max-height: 480px) {
  .open-video-modal-button-mobile {
    display: inline !important;
    height: 107px;
    left: 0;
    right: 0;
    top: 210px;
  }
}
