@import "../common/variables.scss";

section#cs-header {
  position: fixed;
  width: 100%;
  max-width: $body-max-width;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.95);

  .lock-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: none;
  }

  @media only screen and (max-width: 1201px) {
    background-color: white;
  }

  nav.navbar {
    width: 100%;
    max-width: $body-max-width;
    height: 65px;
    padding: 0;
    z-index: 1;
  }

  nav a {
    text-decoration: none;
    line-height: 20px;
    font-size: 15px;
    font-weight: bold;
    padding-right: 10px !important;

    @media (max-width: $video-update-breakpoint4) {
      line-height: 60px;
    }

    img {
      height: 35px;
      width: auto;
    }
  }

  nav a.navbar-brand {
    display: block;
    height: auto;

    @media only screen and (min-width: 600px) {
      padding-right: 30px;
    }
  }
}

.justify-center {
  justify-content: center;
}

.white-background-color {
  background-color: white;
}

.header-section {
  max-width: 1440px;
  min-width: 360px;
  margin: 0 auto;
  padding: 0 4rem 131px;

  @media (max-width: $video-update-breakpoint4) {
    padding: 0 2rem 69px;
  }

  @media (max-width: $video-update-breakpoint6) {
    padding: 0 0.5rem 54px;
  }
}

.hero-content-wrapper {
  height: 100%;
  margin-bottom: -1px;

  @media (max-width: $video-update-breakpoint6) {
    margin-bottom: -23px;
  }
}

#winning-companies {
  max-width: 1536px;
  margin: 0 auto;
  padding: 0 4rem 15px;
}

.sidebar-login-logout-button {
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  text-transform: uppercase;
  margin: 25px 8px;
  padding: 10px 20px;
  border: 1px solid $light-blue;
  border-radius: 40px;
}

.modal-box {
  background-color: #fff;
  color: #000;
  width: 95%;
  max-width: 650px;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translate(-50%, 0);
  padding: 15px;
  border-radius: 5px;
  z-index: 2;

  .close-button {
    font-size: 20px;
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  .flex-modal-box {
    display: flex;
    font-size: 20px;
    padding: 10px;
    justify-content: center;

    div {
      border: 2px solid #000;
      text-align: center;
      padding: 20px 30px;
      margin: 20px;
      border-radius: 5px;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 380px) {
      div {
        padding: 10px 10px;
      }
    }
  }
}

.navbar-light .navbar-brand#logo-tenx2 {
  margin-right: 1rem;
  min-width: 60px;
}

.navbar-light .navbar-toggler {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
  padding: 0;
  margin: 0 0.5rem;
  font-family: WorkSans;
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;

  &.selected {
    span {
      padding-bottom: 2px;
      border-bottom: 1px solid rgba(255, 0, 0, 1);
    }
  }
}

.nav-watch-link {
  margin-top: 20px;
}

.nav-gradient {
  background: linear-gradient(90.5deg, #0054a6 -2.77%, #582c72 51.16%, #ce2028 104.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.navbar-light .navbar-nav .nav-item .caret {
  position: relative;
  transform: rotateX(0);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
  }

  &::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 6px;
    border-top: 5px solid #000;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.navbar-light .navbar-nav .nav-item:hover {
  & .caret::after {
    transform: rotateX(180deg);
  }
}

.navbar-light .navbar-nav .nav-item {
  .dropdown {
    display: none;
    position: absolute;
    background-color: #fff;
    color: #000;
    font-weight: 400;
    border-radius: 2px;
    top: 20px;
    max-height: 260px;
    overflow-y: hidden;
    z-index: 3;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15));

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      background-color: #ffffff;
      text-transform: uppercase;
      padding: 15px;
      font-size: 15px;
      line-height: 24px;
      height: 55px;

      &:hover {
        border: 1px solid #582c72;
        background: rgba(88, 44, 114, 0.2);
        font-weight: 600;
      }

      a {
        all: unset !important;
      }
    }
  }

  &:hover {
    & .dropdown {
      display: block;
      transition: transform ease-in-out 0.5s;
    }
  }
}

.winners-circle.dropdown {
  width: 174px;
}

.winners-nav {
  width: 165px;
}

.navbar-light .navbar-nav .nav-item.active {
  height: 0px;
  box-shadow: 0px 13px 40px 9px rgba(255, 0, 0, 1);
}

.navbar-light .navbar-nav .nav-item.active a {
  text-decoration: line-through;
  text-decoration-color: rgba(206, 32, 39, 0.5);
}

.navbar-light .navbar-nav .more-item.active a {
  text-decoration: underline;
}

.navbar-light .navbar-nav li.nav-item#login-btn .nav-link,
.navbar-light .navbar-nav li.nav-item#signup-btn .nav-link {
  padding: 0.3rem 0.5rem;
}

.navbar-light .navbar-nav .nav-item#login-btn {
  color: #ffffff !important;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;

  .show-border {
    display: flex;
    align-items: center;
    border: 1px solid $light-blue;
    border-radius: 80px;
    padding: 5px 10px;
  }

  .profile-menu {
    position: absolute;

    ul {
      position: absolute;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #414141;
      color: #414141;
      list-style-type: none;
      padding: 0;
      top: 44px;
      right: -17px;
      width: 263px;
      z-index: 1;

      li {
        padding: 10px 15px;
        cursor: pointer;
      }

      li:nth-child(even) {
        background-color: #eff2f7;
        border-radius: 5px;
      }
    }
  }

  img {
    object-fit: cover;
    object-position: 30% 10%;
    border-radius: 50%;

    &.profile-image {
      width: 50px;
      height: 50px;
    }

    &.profile-icon {
      width: 30px;
      height: 30px;
    }
  }
}

.navbar-light .navbar-nav li.nav-item#signup-btn {
  border: 0.8px solid #0655a5;
  color: #ffffff !important;
  padding: 0;
  margin-left: 0.7rem;
}

.navbar-light .navbar-nav .dropdown-menu {
  border-radius: 0;
  padding: 0;
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 15px;
}

.navbar-light .navbar-nav .dropdown-menu li {
  border-bottom: 2px solid #ce2028;
}

.navbar-light .navbar-nav .dropdown-menu li:last-child {
  border-bottom: none;
}

.navbar-light .navbar-nav .dropdown-menu li a {
  color: #2c224e;
  font-weight: 700;
  padding: 0.7rem 0;
}

.navbar-light .navbar-nav .dropdown-menu li a:hover {
  background-color: inherit;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.navbar-light .navbar-toggler {
  padding: 0.75rem 0.531rem !important;
  transition: background 0.2s;
  background: 0 0 !important;
  border-radius: 0;
  border: 0 !important;

  &:focus {
    box-shadow: none !important;
  }
}

.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("../../images/hamburger.svg");
}

.burger-icon {
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.burger-icon,
.burger-icon::after,
.burger-icon::before {
  display: block;
  width: 23px;
  height: 2px;
  background-color: #212121;
  outline: 1px solid transparent;
  border-radius: 10px;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
}

.burger-icon::after,
.burger-icon::before {
  position: absolute;
  content: "";
}

.burger-icon::before {
  top: -7px;
}

.burger-icon::after {
  top: 7px;
}

.navbar-toggler:not(.collapsed) .burger-icon {
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-toggler:not(.collapsed) .burger-icon,
.navbar-toggler:not(.collapsed) .burger-icon::after,
.navbar-toggler:not(.collapsed) .burger-icon::before {
  display: block;
  width: 23px;
  height: 2px;
  background-color: #212121;
  outline: 1px solid transparent;
  border-radius: 10px;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
}

.navbar-toggler:not(.collapsed) .burger-icon::after,
.navbar-toggler:not(.collapsed) .burger-icon::before {
  position: absolute;
  content: "";
}

.navbar-toggler:not(.collapsed) .burger-icon::before {
  top: -7px;
}

.navbar-toggler:not(.collapsed) .burger-icon::after {
  top: 7px;
}

.navbar-toggler:not(.collapsed) .burger-icon {
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) .burger-icon::before {
  transform: translateY(7px) rotate(45deg);
}

.navbar-toggler:not(.collapsed) .burger-icon::after {
  transform: translateY(-7px) rotate(-45deg);
}

nav.navbar > .container-responsive {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

nav.navbar .navbar-nav {
  margin-left: auto;

  @media only screen and (max-height: 414px) and (orientation: landscape) {
    height: 90%;
    overflow-y: scroll;
  }

  .nav-item {
    position: relative;
  }
}

.closeIcon {
  text-align: right;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 25px;
  display: none;
}

.menu-options {
  margin-left: 0 !important;
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  nav a {
    font-size: 13px;
  }

  .navbar-light .navbar-nav .nav-link {
    margin: 0 0.3rem;
  }
}

@media only screen and (max-width: 460px) {
  .navbar-light .container-fluid a.navbar-brand img {
    height: 35px;
    width: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-light .container-fluid {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    > .header {
      flex-basis: 100%;
    }

    a.navbar-brand {
      margin: 0 !important;
      padding: 0 0.3em 0 0;
    }

    .header-logo {
      flex-basis: 100%;

      a.navbar-brand {
        margin: 0 !important;
        padding: 0 0.3em 0 0;

        img {
          width: 100%;
        }
      }
    }
  }

  .navbar-light .navbar-nav li.nav-item.active {
    height: auto;
    box-shadow: none;
  }

  .navbar-light .navbar-nav#menu-buttons {
    flex-direction: row;
  }

  .navbar-light ul#menu-buttons .nav-item {
    border-color: #ffffff !important;
    border-radius: 2.5px;
    padding-bottom: 0;
    margin-top: 1rem;
  }

  .navbar-light ul#menu-buttons .nav-item a {
    font-size: 18px;
    line-height: 60px;
    font-weight: normal;
    padding: 0.5rem 1rem !important;
  }

  .navbar-light .navbar-collapse {
    background: #fff;
    padding: 1rem;
    z-index: 1;

    .nav-item .dropdown {
      top: 48px;
    }
  }

  .collapse {
    //  transition: all 0.4s ease-in-out;
  }

  .collapse:not(.show) {
    position: absolute;
    top: 0;
    height: 100vh;
    left: -280px;
    width: 280px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .closeIcon {
    display: block;
  }

  .nav-right {
    .header-logo {
      display: none;
    }
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.brightcove-react-player-loader {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#cs-body-wrapper #bg-video {
  position: relative;
  margin: 0 auto;
}

#cs-body-wrapper #bg-video #bg-video-con {
  padding-top: 56.25%;
}

/* Hide the player control bar */

#cs-body-wrapper #bg-video .video-js .vjs-control-bar {
  display: none;
}

/* Remove click events on the player */

#cs-body-wrapper #bg-video .video-js {
  pointer-events: none;
}

#cs-body-wrapper .hero-block {
  position: relative;

  &:not(#hero-block__video) {
    overflow: hidden;
  }

  &:before {
    background: linear-gradient(
      348deg,
      rgba(206, 32, 40, 0.77) 15.16%,
      rgba(6, 85, 165, 0.77) 70.89%
    );
    mix-blend-mode: color;

    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    content: " ";
  }
}

#cs-body-wrapper #cs-hero {
  .header-title-slogan {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 50px;
    text-transform: uppercase;
    color: #0054a6;
  }

  .bob-info-block {
    h2,
    p {
      width: 100%;
    }
  }

  #hero-content-con {
    top: 0;
    margin: 0 auto;
    padding: 100px 0 50px;
    z-index: 2;
  }
}

.hero-text-block {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #303030;
  max-width: 69%;
  margin: auto;

  @media (max-width: $video-update-breakpoint1) {
    margin-top: 38px;
  }

  @media (max-width: $video-update-breakpoint2) {
    margin-top: 80px;
  }

  @media (max-width: 1665px) {
    margin-top: 31px;
    max-width: 77%;
  }

  @media (max-width: $video-update-breakpoint3) {
    font-size: 19px;
    margin-top: 0px;
  }

  @media (max-width: $video-update-breakpoint5) {
    max-width: 100%;
  }

  @media (max-width: $video-update-breakpoint6) {
    max-width: 85%;
    font-size: 15px;
  }
}

.hero-bullet-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: $video-update-breakpoint7) {
    justify-content: left;
    margin-left: 10px;
    min-width: 440px;
  }
}

.hero-bullet {
  display: flex;
  align-items: center;
  flex-basis: calc(20% - 10px);
  margin-bottom: 10px;
  margin-right: 9px;
  min-width: 208px;
  max-width: 228px;
  box-sizing: border-box;
  color: #303030;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  @media (max-width: $video-update-breakpoint5) {
    font-size: 20px;
  }

  @media (max-width: $video-update-breakpoint6) {
    font-size: 16px;
    min-width: 265px;
  }

  @media (max-width: $video-update-breakpoint7) {
    min-width: 175px;
    font-size: 12px;
  }
}

.hero-bullet-container > :nth-child(1) {
  @media (min-width: $video-update-breakpoint1) {
    min-width: 244px;
  }
}

.hero-bullet-container > :nth-child(2) {
  @media (max-width: $video-update-breakpoint6) {
    order: 2;
  }

  @media (max-width: $video-update-breakpoint7) {
    min-width: 175px;
  }
}

.hero-bullet-container > :nth-child(3) {
  min-width: 295px;

  @media (max-width: $video-update-breakpoint6) {
    min-width: 415px;
    order: 5;
  }

  @media (max-width: $video-update-breakpoint7) {
    min-width: 275px;
  }
}

.hero-bullet-container > :nth-child(4) {
  @media (min-width: $video-update-breakpoint1) {
    min-width: 267px;
  }

  @media (min-width: $video-update-breakpoint6) {
    min-width: 260px;
  }
}

.hero-bullet img {
  max-width: 4vw;
  min-width: 25px;
  margin-right: 9px;
}

@media only screen and (max-width: 1024px) {
  #cs-body-wrapper #bg-video {
    display: none;
    visibility: hidden;
  }

  #cs-body-wrapper #cs-hero .row .bob-logo-bg {
    background-image: none;
    padding-bottom: 0;
  }

  .placard-layout h2.properties {
    padding-left: 30px;
    margin-bottom: 30px;
  }

  #cs-body-wrapper #cs-hero #hero-content-con {
    position: relative;
    padding: 30px 0 0 0 !important;
  }

  #cs-body-wrapper h2.page-heading {
    margin-top: 0;
  }

  nav.navbar .navbar-nav {
    flex-direction: column;
  }

  .navbar-expand-lg .collapse.show.navbar-collapse {
    display: block !important;
  }
}

@media (max-width: $header-breakpoint1) {
  #cs-body-wrapper #cs-hero .bob-info-block {
    h2,
    p {
      max-width: none;
    }

    h2 {
      margin: 2rem auto;
    }

    p {
      margin: 0 auto 1rem;
    }
  }
}

@media only screen and (min-width: $header-breakpoint1) {
  .header-section {
    padding: 0;
  }

  #cs-body-wrapper #cs-hero .bob-info-block {
    margin-left: 50%;
    max-width: 50%;
  }

  #cs-body-wrapper #bg-video {
    display: block;
    overflow: hidden;
  }

  .sidebar-login-logout-button {
    display: none;
  }

  div#menu-buttons {
    width: 140px;
  }
}

.watch-now-mobile {
  margin: 0px 92px 0px 0px;

  @media only screen and (min-width: $header-breakpoint1) {
    display: none;
    margin: 30px;
  }
}

.menu-watch-now {
  color: red;
  min-width: 135px;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid red;
  border-radius: 80px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300 !important;
  line-height: 20px;
  cursor: pointer;
}

.float-right {
  float: right;
}
.watch-dot {
  color: #60c939;
  height: auto;
  width: 20px;
  font-size: 60px;
  line-height: 0px;
  vertical-align: middle;

  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out; /* IE */
  animation: blink normal 1.5s infinite ease-in-out; /* Opera */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
} /* Firefox */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
} /* Webkit */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
} /* IE */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
} /* Opera */

.bob-info-block {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1;
}

.bob-info-block h2 {
  font-family: "WorkSans";
  font-weight: bold;
  font-size: 2em;
}

.arrow-button {
  color: white;
  font-size: 32px;
}

.open-video-button {
  background-color: #ce2028;
  border: none;
  margin-top: 14px;
  margin-left: 14px;
  height: 59px;
  width: 59px;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 60px;
  cursor: pointer;

  @media (max-width: $video-update-breakpoint5) {
    line-height: 0px;
  }
}


.video-circle {
  background-color: rgba(206, 32, 40, 0.2);
  height: 87px;
  width: 87px;
  border-radius: 54px;
  position: absolute;
  top: 530px;
  left: 85%;
  transform: translate(-50%, -50%);
  display: flex;

  @media (max-width: $video-update-breakpoint1) {
    top: 460px;
  }

  &.with-jets {
    top: 448px;
    left: 85%;
    @media (max-width: $video-update-breakpoint1) {
      left: 1295px;
    }
  }


  @media (max-width: 767px) {
    display: none;
  }
}

.counter-time-text {
  font-size: 18px;
  font-weight: bold;

  @media (max-width: $video-update-breakpoint7) {
    font-size: 11px;
  }
}

.play-button {
  width: 200px;
}

.play-text {
  min-width: 300px;
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
  margin: 20px 14px;
  cursor: pointer;

  @media (max-width: $video-update-breakpoint6) {
    visibility: hidden;
  }
}

.header-countdown {
  background: #ffffff;
  mix-blend-mode: hard-light;
  box-shadow: 0px -4px 54px rgba(2, 74, 148, 0.25);
  border-radius: 76px;
  position: relative;
  .countdown-text-container {
    padding-left: 15px;
    padding-top: 6px;
  }
}

.date-start-text-desktop {
  display: none;
  @media (min-width: $breakpoint-sm) {
    display: block;
  }
}

.date-start-text {
  font-size: 12px;
  padding-left: 20px;
  position: absolute;

  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

.header-section .first-col {
  @media (min-width: 1023px) {
    height: 109px;
  }

  @media (min-width: $video-update-breakpoint4) {
    height: 115.91px;
  }
}

.header-section .last-col {
  @media (min-width: 993px) {
    flex: 1;
    height: 60.47px;
  }

  @media (min-width: 1023px) {
    height: 109px;
  }

  @media (min-width: $video-update-breakpoint4) {
    height: 115.91px;
  }
}

.hero-count-down {
  border-radius: 50px 0px 0px 50px;
  padding-left: 40px;
}

.hero-count-text {
  font-weight: bold;
  display: inline-block;
  line-height: 22.5px;
}

// update for video

#cs-body-wrapper {
  #hero-block__video {
    background-color: white;

    .bob-top.container {
      @media (min-width: 576px) {
        min-width: 100%;
      }
    }

    #cs-hero .row #hero-ticker .game-entry.inactive {
      background-color: $transparent-white;
      box-shadow: none;

      a {
        height: 100%;
      }
    }

    .game-entry {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    .game-entry:hover {
      -webkit-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      -moz-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    }

    #cs-hero .bob-info-block {
      margin: 218px 0 0 0;
      max-width: 100%;

      @media (max-width: $video-update-breakpoint1) {
        margin: 127px 0 62px;
        margin-left: 50px;
      }

      @media (max-width: $video-update-breakpoint2) {
        margin: 103px 0 -16px 50px;
      }

      @media (max-width: $video-update-breakpoint3) {
        margin: 91px 0 0 0;
      }

      @media (max-width: $video-update-breakpoint4) {
        max-width: 100% !important;
        margin: 58px 0 0 0;
        text-align: left;
      }

      @media (max-width: $video-update-breakpoint5) {
        margin: 0px 0 0 0;
      }

      @media (max-width: $video-update-breakpoint6) {
        padding: 0 16px;
      }

      @media (max-width: $video-update-breakpoint7) {
        margin: 0px;
        height: 220px;
      }
    }

    .open-video-modal-button {
      @media (max-width: $video-update-breakpoint2) {
        height: 80px !important;
        width: 80px !important;
        top: 350px !important;
        left: 1024px !important;
      }

      @media (max-width: $video-update-breakpoint3) {
        left: 882px !important;
      }

      @media (max-width: $video-update-breakpoint4) {
        top: 289px !important;
        left: 828px !important;
      }

      @media (max-width: $video-update-breakpoint5) {
        left: 750px !important;
        height: 65px !important;
        width: 65px !important;
      }

      @media (max-width: $video-update-breakpoint6) {
        top: 234px !important;
        left: 591px !important;
        height: 65px !important;
        width: 65px !important;
      }

      @media (max-width: $video-update-breakpoint7) {
        top: 207px !important;
        left: 293px !important;
        height: 65px !important;
        width: 65px !important;
      }

      &.with-jets {
      @media (max-width: $video-update-breakpoint2) {
        height: 80px !important;
        width: 80px !important;
        top: 383px !important;
        left: 1024px !important;
      }

      @media (max-width: $video-update-breakpoint3) {
        top: 346px !important;
        left: 882px !important;
      }

      @media (max-width: $video-update-breakpoint4) {
        top: 289px !important;
        left: 828px !important;
      }

      @media (max-width: $video-update-breakpoint5) {
        top: 266px !important;
        left: 674px !important;
        height: 65px !important;
        width: 65px !important;
      }

      @media (max-width: $video-update-breakpoint6) {
        top: 234px !important;
        left: 591px !important;
        height: 65px !important;
        width: 65px !important;
      }

      @media (max-width: $video-update-breakpoint7) {
        top: 207px !important;
        left: 293px !important;
        height: 65px !important;
        width: 65px !important;
      }
    }

      button {
        @media (max-width: $video-update-breakpoint2) {
          height: 60px !important;
          width: 60px !important;
          margin-top: 10px !important;
          margin-left: 10px !important;
        }

        @media (max-width: $video-update-breakpoint5) {
          height: 46px !important;
          width: 46px !important;
          margin-top: 10px !important;
          margin-left: 10px !important;

          span {
            font-size: 20px !important;
          }
        }

        @media (max-width: $video-update-breakpoint6) {
          height: 46px !important;
          width: 46px !important;
          margin-top: 10px !important;
          margin-left: 10px !important;

          span {
            font-size: 20px !important;
          }
        }
      }
    }

    .header-section {
      .first-col {
        @media (max-width: $video-update-breakpoint5) {
          flex: 0.4 0;
        }

        @media (max-width: $video-update-breakpoint6) {
          padding: 0px 3px 0 11px !important;
        }
      }

      .second-col {
        @media (max-width: $video-update-breakpoint5) {
          flex: 1;
        }
      }
    }
  }
}
