.login-signup-modal {
  position: absolute;
  width: 375px;
  background-color: #fff;
  z-index: 10;
  padding: 15px 20px;

  .error-message {
    color: #ec2028;
    padding: 5px;
    font-size: 14px;
  }

  &.login {
    transform: translateX(-222px);
  }

  &.signup {
    transform: translateX(-305px);
  }

  &.prize-money-modal {
    margin: 0 auto;
    width: 90%;
    max-width: 442px;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    border-radius: 4px;

    @media only screen and (max-height: 414px) and (orientation: landscape) {
      top: 60px;
      height: 75vh;
      overflow-y: scroll;
    }

    .header {
      font-size: 17px;
      font-weight: 700;
      margin: 30px 0 10px;
      text-align: center;
    }

    .description {
      font-size: 15px;
      text-align: center;
    }

    .select-tshirt-size {
      font-size: 18px;
      font-weight: 700;
      margin: 15px 0;
      text-align: center;
    }

    .tshirt-selections {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &.login-modal {
    // padding: 50px;
    margin: 0 auto;
    width: 90%;
    max-width: 470px;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.5);

    @media only screen and (max-height: 414px) and (orientation: landscape) {
      top: 60px;
      height: 75vh;
      overflow-y: scroll;
    }

    .header {
      font-size: 17px;
      font-weight: 700;
      margin: 30px 0 10px;
      text-align: center;
    }

    .description {
      font-size: 15px;
      text-align: center;
    }
  }

  >div {
    margin: 0;
    padding: 0;
  }

  .close-modal {
    text-align: right;
    font-size: 20px;
    color: #000;
    padding: 0;
    margin: 10px 0;
    position: absolute;
    top: 0;
    right: 20px;

    span {
      cursor: pointer;
    }
  }

  .login-modal-wrapper {
    padding: 0 22px 32px;
  }

  .modal-header {
    font-weight: 600;
    color: #000;
    padding: 20px 0;
    margin: 0;
    text-align: center;
    display: block;
    border-bottom: 0 none;
    font-size: 14px;
  }

  .modal-header2 {
    font-weight: 600;
    color: #000;
    padding: 40px 0 20px 0;
    text-align: center;
  }

  .login-signup-header {
    font-weight: 600;
    color: #000;
    padding: 20px 0;
    margin: 0;
    display: block;
    border-bottom: 0 none;
    font-size: 25px;
    font-family: "CoStar Brown", sans-serif;
  }

  .modal-frame-hidden {
    display: none;
  }

  .login-modal-header {
    text-align: center;
    font-weight: 600;
    color: #000;
    padding: 15px 0 20px;
    margin: 0;
    display: block;
    border-bottom: 0 none;
    font-size: 17px;
    font-family: "CoStar Brown", sans-serif;
  }

  .input-wrapper {
    margin: 10px 0px;
  }

  .login-modal-content {
    font-weight: 500;
    color: #000;
    text-align: center;
    margin: 0;
    display: block;
    border-bottom: 0 none;
    font-size: 14px;
    font-family: "CoStar Brown", sans-serif;
  }

  .float-column {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .address-fields {
      width: 100%;
    }

    >div {
      margin: 0;
      padding: 0;
    }

    .autocomplete-dropdown-container {
      width: 100%;
    }
  }

  .input-field {
    width: calc(50% - 5px);
    font-size: 14px;
    padding: 10px;
    margin: 5px 0;
    background-color: #e5e5e5;
    border-radius: 4px;
    border: 0;

    &.empty {
      border: 1px solid #ce2028;
    }

    &:hover {
      outline: none;
    }
  }

  .login-input-field {
    font-family: "CoStar Brown", sans-serif;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    outline: none;

    &:hover {
      border-color: #1890ff;
    }
  }

  .read-only {
    color: grey;
    background-color: #e7e7e7;
  }

  .button-continue {
    background-color: #582c72;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    font-family: "CoStar Brown", sans-serif;
    color: #fff;
    width: 100%;
    border: 0;
    padding: 14px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;

    &:disabled {
      background-color: #6d5a79;
    }
  }

  .button-confirm {
    float: right;
    width: 45%;
  }

  .confirm {
    margin: 30px 0 0 0;
    width: 100%;
    background: #ffffff;
    border: 1px solid #582c72;
    color: #582c72;
  }

  .button-deny {
    float: left;
    width: 45%;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .deny {
    margin: 30px 0 0 0;
    width: 100%;
  }

  .modal-footer {
    background-color: #ececec;
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 10px 0;
    color: #000;
    display: block;
    text-transform: uppercase;

    span {
      text-decoration: underline;
      color: #ce2028;
      font-weight: 600;
      margin: 0;
      padding: 5px 0;
      text-transform: initial;
    }
  }
}

.prize-money {
  .prize-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
}

.join-mailing-list-container {
  height: 450px;
}

.request-invite-container {
  height: 500px;

  @media only screen and (min-width: 768px) {
    height: 700px;
  }
}