@import "../common/variables.scss";

.max-1920 {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
}

.max-1440 {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 4rem;
}

.transparent-bg {
  background-color: transparent !important;
}

.section-num {
  position: absolute;
  z-index: 2;
  float: left;
  margin-top: -30rem;
  margin-left: -5px;
}

.section-num .scroll-number {
  color: #ce2028;
  background-image: url("../../images/marketing/scroll-slide.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 200px;
  padding: 83px 0 0 33px;
  font-size: 20px;
  height: 180px;
}

.section-num .scroll-dot {
  color: #ffffff;
  padding-left: 22px;
}

.section-num .scroll-dot.below {
  margin-top: -15px;
}

.section-num .scroll-dot.above {
  margin-bottom: -30px;
}

.see-link {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  margin-top: -30rem;
  margin-left: -22px;
}

.see-link span {
  color: #ffffff;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 13px;
  font-weight: 100;
  letter-spacing: 8px;
  padding-right: 50px;
  margin-left: -15px;
  padding-top: 100px;
}

.see-link img {
  width: 85px;
  margin-top: -90px;
}

#cs-section-content .section-num,
#cs-section-content .see-link {
  margin-top: 100px;
}

#cs-section-content .see-link span {
  padding-right: 49px;
}

#cs-section-content .section-num .scroll-dot {
  padding-left: 24px;
}

/*************** SECTION: cs-hero *******************/
.header-cars-section {
  position: absolute;
  top: 4.5rem;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 0.3;
  z-index: 0;
}

.header-bob-section {
  position: absolute;
  top: 3.5rem;
  bottom: 35px;
  left: 0;
  width: 100%;
  z-index: 0;
}

#cs-body-wrapper #cs-hero {
  z-index: 1;
  position: relative;
}

#cs-body-wrapper #cs-hero .row .bob-logo-bg {
  padding: 4.5rem 0 0;
}

#cs-body-wrapper #cs-hero .row .bob-logo-bg .inner-content {
  margin-left: 0rem;
  margin-top: 14px;
}

#cs-body-wrapper #cs-hero .row .bob-logo-bg .inner-content img {
  width: 65%;
}

#cs-body-wrapper #cs-hero h2 {
  color: #ce2028;
  width: 90%;
  max-width: 725px;
  margin-bottom: 2rem;
  font-size: 24px;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
}

#cs-body-wrapper #cs-hero p {
  color: #303030;
  width: 90%;
  max-width: 725px;
  font-family: WorkSans, Helvetica, Arial, sans-serif;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  padding-top: 20px;

  @media only screen and (max-width: 550px) {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

#cs-body-wrapper #cs-hero .row #hero-ticker {
  color: #000000;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg {
  font-size: 18px;
  align-self: center;

  @media (max-width: $video-update-breakpoint5) {
    font-size: 15px;
  }

  @media (max-width: $video-update-breakpoint6) {
    font-size: 14px;
  }

  @media (max-width: $video-update-breakpoint7) {
    font-size: 10px;
  }
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg strong {
  font-size: 21px;
  line-height: 26px;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg p {
  font-size: 22px;
  line-height: 24px;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.first-col {
  padding: 28px 0 45px 27px;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col {
  .row .col-4 {
    display: flex;
    align-items: center;
  }
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col strong {
  font-size: 32px;
  font-weight: 700;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  padding: 0 1rem;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col p {
  font-size: 24px;
  line-height: 26px;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  padding: 0 1rem;
  margin: 0;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col p {
  font-size: 24px;
  line-height: 26px;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  padding: 0 1rem;
  margin: 0;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col .row .col-4 {
  line-height: 1;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .red-bg {
  background-color: #582c72;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 700;
  line-height: 3.5;
  align-self: center;
  padding: 0;
  border-radius: 82px;
  height: 62px;
  width: 22%;
  margin-right: 11px;

  @media (max-width: $video-update-breakpoint4) {
    font-size: 14px;
    padding-top: 9px;
  }

  @media (max-width: $video-update-breakpoint6) {
    font-size: 14px;
  }

  @media (max-width: $video-update-breakpoint7) {
    display: none;
  }
}

#cs-body-wrapper #cs-hero .row #hero-ticker .bg-sign-up {
  background-color: #582c72;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: white;
  font-weight: 700;
  align-self: center;
  padding: 7px;
  border-radius: 82px;
  width: 92px;
  height: 42px;
  margin-right: 10px;

  background-position: 15px;

  @media (min-width: $video-update-breakpoint7) {
    display: none;
  }
}

#cs-body-wrapper #cs-hero .row #hero-ticker .red-bg a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

#cs-body-wrapper #cs-hero .row #hero-ticker .red-bg a:hover {
  color: inherit;
}

/*************** SECTION: cs-winning-companies *******************/

#cs-body-wrapper #cs-hero .row #winning-companies {
  margin-top: 10px;
  align-self: center;
  padding: 2.5em 0.8em 1em 3.3em;
  font-weight: 700;
  color: white;
  line-height: 1.2em;
}

#cs-body-wrapper #cs-hero .row #winning-companies strong {
  font-size: 2rem;
  line-height: 2.25rem;
}

#cs-body-wrapper #cs-hero .row #winning-companies .red-bg {
  background-color: #ce2028;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5rem;
  align-self: center;
  padding: 1rem;
  box-shadow: 0px 0px 25px 15px rgba(255, 0, 0, 0.35);
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

/*************** SECTION: cs-section-2 *******************/

#cs-body-wrapper #cs-section-content {
  color: $dark;
  background-color: #ffffff;
}

/*** PAGE: ABOUT TEX-X page ***/

#cs-body-wrapper .black {
  background: #000000;
  color: #ffffff;
}

#cs-body-wrapper #cs-section-content .heading-container h2 {
  font-size: 40px;
  line-height: 50px;
}

#cs-body-wrapper #cs-section-content .stat-container h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 35px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

#cs-body-wrapper #cs-section-content .stat-container p {
  font-size: 22px;
  font-weight: 400px;
  padding-bottom: 2.5rem;
}

#cs-body-wrapper #cs-section-content .stat-container p.red-border-bottom {
  border-bottom: 3px solid #ce2028;
}

#cs-body-wrapper #cs-section-content .stat-container p.blue-border-bottom {
  border-bottom: 3px solid #0655a5;
}

#cs-body-wrapper #cs-section-content .iconbox-container {
  background-image: url("../../images/marketing/iconbox-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;

  img {
    height: 245px;
  }

  @media only screen and (min-width: 769px) {
    .red-bg-box.speed {
      margin: 1.5em 0 !important;
    }

    .red-bg-box.control {
      margin: 1.5em 0 !important;
    }

    .blue-bg-box.reach {
      margin-bottom: 1.5em;
    }

    .blue-bg-box.certainty {
      margin-top: 1.5em;
    }

    .h-table {
      width: 1424px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .red-bg-box.speed {
      margin-bottom: 0 !important;
    }

    .red-bg-box.control {
      margin-bottom: 1.5em !important;
    }

    .blue-bg-box.reach {
      margin-bottom: 1.5em;
    }

    .blue-bg-box.certainty {
      margin-top: 1.5em;
    }
  }
}

#cs-body-wrapper #cs-section-content .iconbox-container h3 {
  font-size: 36px;
  line-height: 47px;
}

#cs-body-wrapper #cs-section-content .iconbox-container p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  height: 80px;
}

#cs-body-wrapper #cs-section-content .iconbox-container .red-bg-box,
#cs-body-wrapper .black .casestudy-container {
  background: #390e14 linear-gradient(96.48deg, rgba(206, 32, 40, 0.01) 22.53%, rgba(206, 32, 40, 0.01) 191.91%);
  border-radius: 10px;

  @media only screen and (max-width: 850px) {
    margin: 0 !important;
  }
}

#cs-body-wrapper #cs-section-content .iconbox-container .blue-bg-box {
  background: #12182d linear-gradient(96.48deg, rgba(18, 82, 249, 0.3) 22.53%, rgba(18, 82, 249, 0) 191.91%);
  border-radius: 10px;
}

#cs-body-wrapper .black .personas-contaioner h2,
#cs-body-wrapper .black .personas-contaioner h3,
#cs-body-wrapper .black .personas-contaioner p {
  font-family: WorkSans, Helvetica, Arial, sans-serif;
}

#cs-body-wrapper .black .personas-contaioner h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
}

#cs-body-wrapper .black .personas-contaioner h3 {
  font-size: 28px;
  line-height: 33px;
  font-weight: 700;
}

#cs-body-wrapper .black .personas-contaioner p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

#cs-body-wrapper .black .personas-contaioner .text-content {
  min-height: 400px;

  @media only screen and (max-width: 1160px) {
    min-height: 500px;
  }

  @media only screen and (max-width: 992px) {
    min-height: 350px;
  }
}

#cs-body-wrapper .black .personas-contaioner .for-buyers {
  background-color: #1982ec;
}

#cs-body-wrapper .black .personas-contaioner a.btn {
  font-size: 14px;
  line-height: 16.5px;
  font-weight: 700;
  padding: 15px 24px;
}

#cs-body-wrapper .black .personas-contaioner .for-buyers a.btn {
  color: #685897;
}

#cs-body-wrapper .black .contactform-container {
  border: 1px solid rgba(255, 0, 0, 0.45);
  border-radius: 10px;
}

#cs-body-wrapper .black .contactform-container .btn-red {
  background-color: #ce2028;
  color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1rem;
}

#cs-body-wrapper .black .contactform-container input {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 14px;
  width: 100%;
  padding: 0.7rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

#cs-body-wrapper .black .contactform-container input.half {
  width: 48%;
  margin-right: 4%;
  float: left;
}

#cs-body-wrapper .black .contactform-container input.half.last {
  margin-right: 0;
}

#cs-body-wrapper .black .casestudy-container img {
  width: 100%;
}

#cs-body-wrapper .black .casestudy-container .casestudy-detail-box p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 2rem;
}

#cs-body-wrapper .black .casestudy-container .casestudy-detail-box p.title {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #ce2028;
  padding: 0 0 1rem;
}

#cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box {
  border-right: 1px solid #ce2028;
  margin-bottom: 1rem;
}

#cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box.last {
  border-right: none;
}

#cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0.7rem;
}

#cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box p.stat {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

#cs-body-wrapper .three-sections {
  border-top: 1px solid #9d9d9d;
  padding-top: 0 !important;
}

/************** media query: small mobile ****************/

@media only screen and (max-width: 769px) {
  #cs-body-wrapper .black .contactform-container input.half {
    width: 100%;
    margin-right: 0;
    float: none;
  }

  #cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box {
    border-right: none;
  }

  #cs-body-wrapper #cs-hero .row .bob-logo-bg .inner-content img {
    width: 100%;
  }
}

/************** media query: mobile ****************/

@media only screen and (max-width: 992px) {
  .max-1440 {
    padding: 1rem;
  }

  .section-num,
  .see-link {
    display: none;
  }

  #cs-body-wrapper #cs-hero {
    padding-bottom: 0;
  }

  #cs-body-wrapper #cs-hero .row .cars-bg {
    display: none;
  }

  #cs-body-wrapper #cs-hero .row .bob-logo-bg {
    background-size: auto 100%;
    background-position: top center;
    padding: 6rem 0 1rem;

    @media (max-width: $video-update-breakpoint7) {
      padding: 0;
    }
  }

  #cs-body-wrapper #cs-hero .row .bob-logo-bg .inner-content {
    margin-left: 0;
    padding: 0 1rem;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .row {
    padding: 0;
  }

  #cs-body-wrapper #cs-footer a.btn-primary {
    width: fit-content;
    display: block;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.first-col-min {
    padding: 0 0 0 20px;
    max-width: 21%;
    min-width: 78px;
    background-color: transparent;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.first-col-max {
    padding: 0 0 0 20px;
    max-width: 31%;
    background-color: transparent;
  }

  #cs-body-wrapper #cs-hero h2 {
    margin-top: 2rem;

    @media only screen and (max-width: 550px) {
      text-align: center;
      width: 100%;
    }
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col {
    text-align: center;

    @media (max-width: $video-update-breakpoint7) {
      font-size: 12px;
      margin: -18px 0px -21px;
      min-height: 95px;
    }
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col strong {
    padding: 0;
    font-size: 2em;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .red-bg {
    padding: 3px;

    a {
      padding: 0.8rem;
    }
  }

  #cs-body-wrapper #cs-section-content .heading-container h2 {
    font-size: 30px;
    line-height: 35px;
  }

  #cs-body-wrapper #cs-section-content .about-tenx-page-header {
    padding: 0 !important;
  }

  #cs-body-wrapper #cs-section-content .about-tenx-page-header h2 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em !important;
    margin-bottom: 0;
  }

  #cs-body-wrapper #cs-section-content .stat-container h3 {
    font-size: 32px;
  }

  .iconbox-container {
    padding: 0;
    margin: 0 auto;

    >* {
      padding: 0;
    }

    .mx-3 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  #cs-body-wrapper #cs-section-content .iconbox-container h3 {
    font-size: 24px;
    line-height: 35px;
  }

  #cs-body-wrapper #cs-section-content .iconbox-container p {
    font-size: 15px;
    line-height: 20px;
  }

  #cs-body-wrapper .hero-block {
    overflow: hidden !important;
  }

  .placard-layout {
    position: relative;
    margin-top: 20px;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies {
    font-weight: 400;
    line-height: 1.5em;
    padding: 1.5em;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies strong {
    font-size: 24px;
    line-height: 2em;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies .red-bg {
    height: 81px;
    width: 242px;
    margin: 45px auto;
  }
}

/************** media query: tablet ****************/

@media only screen and (min-width: 768px) and (max-width: 992px) {
  #cs-body-wrapper #cs-hero .row .bob-logo-bg {
    background-size: 100% auto;
    background-position: bottom center;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col {
    font-size: 20px;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .red-bg {
    font-size: 16px;
  }

  #cs-body-wrapper .black .casestudy-container .casestudy-stat .stat-box.last-md {
    border-right: none;
  }

  #cs-body-wrapper #cs-hero .row .bob-logo-bg {
    margin-left: 0;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies {
    font-weight: 400;
    line-height: 1.5em;
    padding: 20px 60px;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies strong {
    font-size: 26px;
    line-height: 2em;
  }

  #cs-body-wrapper #cs-hero .row #winning-companies .red-bg {
    height: 81px;
    width: 242px;
    margin: 30px auto;
  }
}

/************** media query: all laptop ****************/

@media only screen and (min-width: 993px) and (max-width: 1440px) {
  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg {
    font-size: 1.2em;
    padding-left: 3%;
  }

  #cs-body-wrapper #cs-section-content .stat-container p {
    font-size: 17px;
  }

  #cs-body-wrapper #cs-footer a.btn-primary {
    width: fit-content;
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}

/************** media query: small laptop ****************/

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg {
    font-size: 18px;
    padding-left: 3%;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col strong {
    font-size: 28px;
    padding: 0;
  }

  #cs-body-wrapper #cs-hero .row #hero-ticker .white-bg.second-col p {
    font-size: 24px;
    line-height: 28px;
    padding: 0;
  }

  #cs-body-wrapper #cs-section-content .iconbox-container h3 {
    font-size: 27px;
    line-height: 35x;
  }

  #cs-body-wrapper #cs-section-content .iconbox-container p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: $header-breakpoint1) {
  .bob-logo-position-desktop {
    max-width: 95%;
  }

  .bob-main-logo-desktop {
    margin-right: 0 !important;
  }
}

/************** media query: bigger than 1200px ****************/

@media only screen and (min-width: 1201px) {
  #cs-body-wrapper #cs-section-content .iconbox-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  #cs-body-wrapper .black .personas-contaioner .for-brokers,
  #cs-body-wrapper .black .personas-contaioner .for-buyers,
  #cs-body-wrapper .black .personas-contaioner .for-sellers {
    position: relative;
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  #cs-body-wrapper .black .casestudy-container .casestudy-detail-box {
    padding-left: 2rem;
  }

  #cs-body-wrapper .black .personas-contaioner .for-brokers:after,
  #cs-body-wrapper .black .personas-contaioner .for-sellers:after {
    content: " ";
    position: absolute;
    top: -2px;
    left: 3em;
    width: 100px;
    height: 3px;
    background-color: #ce2028;
  }
}

/************** media query: bigger than 1440px ****************/

@media only screen and (min-width: 1441px) {
  .max-1440 {
    padding: 0;
  }
}

// update for video

#cs-body-wrapper {
  #hero-block__video {
    #cs-hero .row .bob-logo-bg {
      margin: 307px 0 0;

      @media (max-width: $video-update-breakpoint6) {
        margin: 290px 0 0;
      }
    }

    #cs-hero .row .bob-logo-bg .inner-content {
      margin-top: 75px;

      @media (max-width: $video-update-breakpoint1) {
        margin: 302px 398px 0;
      }

      @media (max-width: $video-update-breakpoint5) {
        margin: 303px 0 0;
      }

      @media (max-width: $video-update-breakpoint6) {
        margin: 303px 398px 0;
      }

      img {
        width: 1146px;

        @media (max-width: $video-update-breakpoint1) {
          width: 70%;
        }

        @media (max-width: $video-update-breakpoint6) {
          width: 325px;
        }
      }
    }

    #cs-hero h2 {
      color: #ce2028;
      width: 100%;
      max-width: 955px;
      margin-bottom: 22px;
      font-size: 36px;
      font-family: WorkSans, Helvetica, Arial, sans-serif;
      font-weight: 400;

      @media (max-width: $video-update-breakpoint2) {
        font-size: 30px;
      }

      @media (max-width: $video-update-breakpoint4) {
        margin-left: 0;
      }

      @media (max-width: $video-update-breakpoint6) {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    #cs-hero p {
      &:first-of-type {
        margin-bottom: 20px;

        @media (max-width: $video-update-breakpoint7) {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        @media (max-width: $video-update-breakpoint4) {
          margin-bottom: 0;
        }

        @media (max-width: $video-update-breakpoint7) {
          display: none;
        }
      }
    }

    .count-down-box {
      padding-left: 70px;

      @media (max-width: $video-update-breakpoint2) {
        padding-left: 10px;
      }

      @media (max-width: $video-update-breakpoint4) {
        padding-left: 34px;
        margin-right: -3px;
      }

      @media (max-width: $video-update-breakpoint5) {
        padding-left: 5px;
        margin-right: -3px;
      }

      @media (max-width: $video-update-breakpoint7) {
        margin-right: -2px;
      }
    }

    .large-gradient-text {
      width: 116px;
      height: 78px;
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 700;
      font-size: 70px;
      text-align: right;
      line-height: 82px;
      background: linear-gradient(283.12deg, #ce2028 22.8%, #0655a5 72.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-right: 10%;

      @media (max-width: $video-update-breakpoint2) {
        font-size: 52px;
        width: 87px;
      }

      @media (max-width: $video-update-breakpoint4) {
        font-size: 50px;
      }

      @media (max-width: $video-update-breakpoint5) {
        font-size: 35px;
        width: 55px;
      }

      @media (max-width: $video-update-breakpoint7) {
        font-size: 26px;
        width: 32px;
      }
    }

    .small-gradient-text {
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      background: linear-gradient(283.12deg, #ce2028 22.8%, #0655a5 72.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      flex: none;
      order: 0;
      flex-grow: 0;

      @media (max-width: $video-update-breakpoint2) {
        font-size: 23px;
      }

      @media (max-width: $video-update-breakpoint4) {
        font-size: 20px;
      }
    }

    #cs-hero .row #hero-ticker {
      margin-top: 3vh;
      margin-bottom: -10px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      @media (max-width: $video-update-breakpoint5) {
        margin-top: 43px;
      }

      @media (max-width: $video-update-breakpoint7) {
        margin-top: 100px;
      }

      .row {
        margin: 0;
      }
    }

    #game-schedule-container {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 26px;
      max-width: 1336px;
      min-width: 360px;


      @media (min-width: $breakpoint-sm) {
        max-width: 731px;
        margin-top: -30px;
      }

      @media (min-width: $breakpoint-md) {
        max-width: 805px;
        margin-top: -40px;
      }

      @media (min-width: $breakpoint-lg) {
        max-width: 1400px;
        margin-top: 63px;
      }

      .game-schedule-btn {
        background-color: transparent;
        border: none;
        color: $light-blue;
      }

    }
  }

  .hero-image-content {
    background-image:
      url("../../images/marketing/season2.png"),
      url("../../images/marketing/backgroundCloud.png");
    background-position: 85% 21px, 0px 0px;
    background-size: 416px, 1924px;
    background-repeat: no-repeat;

    &.with-jets {
      background-image: url("../../images/marketing/f35jet.png"),
      url("../../images/marketing/season2.png"), url("../../images/marketing/smallf35jet.png"),
      url("../../images/marketing/backgroundCloud.png");
      background-position: 100% 239px, 67% 21px, 89px 610px, 0px 0px;
      background-size: 535px, 416px, 159px, 1924px;
    }

    @media (max-width: $video-update-breakpoint1) {
      background-image:
      url("../../images/marketing/season2.png"),
      url("../../images/marketing/backgroundCloud.png");
    background-position: 1040px 21px, 0px 0px;
    background-size: 370px, 1921px;

      &.with-jets {
        background-image: url("../../images/marketing/f35jet.png"),
          url("../../images/marketing/season2.png"), url("../../images/marketing/smallf35jet.png"),
          url("../../images/marketing/backgroundCloud.png");
          background-position: 1050px 266px, 751px 21px, 89px 610px, 0px 0px;
          background-size: 447px, 370px, 153px, 1921px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint2) {
      background-image:
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 900px 31px, 0px 0px;
      background-size: 308px, 1924px;
      &.with-jets {
        background-image: url("../../images/marketing/f35jet.png"),
          url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
        background-position: 808px 215px, 561px 31px, 0px 0px;
        background-size: 436px, 308px, 1924px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint3) {
      background-image:
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 750px 50px, 0px 0px;
      background-size: 286px, 1704px;
      &.with-jets {
        background-image: url("../../images/marketing/f35jet.png"),
          url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
        background-position: 745px 200px, 570px 50px, 0px 0px;
        background-size: 318px, 286px, 1704px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint4) {
      background-image:
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 600px 20px, -122px 0px;
      background-size: 277px, 1585px;
      &.with-jets {
      background-image: url("../../images/marketing/f35jet.png"),
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 677px 171px, 508px 20px, -122px 0px;
      background-size: 254px, 277px, 1585px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint5) {
      background-image:
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 600px 20px, -212px -45px;
      background-size: 243px, 1585px;
      &.with-jets {
        background-image: url("../../images/marketing/f35jet.png"),
          url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
        background-position: 523px 146px, 356px 20px, -212px -45px;
        background-size: 254px, 243px, 1585px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint6) {
      background-image:
        url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
      background-position: 500px 20px, -212px -45px;
      background-size: 243px, 1585px;

      .with-jets {
        background-image: url("../../images/marketing/f35jet.png"),
          url("../../images/marketing/season2.png"), url("../../images/marketing/backgroundCloud.png");
        background-position: 443px 116px, 356px 20px, -212px -45px;
        background-size: 254px, 243px, 1585px;
      }
      background-repeat: no-repeat;
    }

    @media (max-width: $video-update-breakpoint7) {
      background-image: url("../../images/marketing/season2.png"),
        url("../../images/marketing/backgroundCloud.png");
      background-position: 203px 18px, -212px -45px;
      background-size: 163px, 1585px;
      &.with-jets {
        background-image: url("../../images/marketing/season2.png"),
        url("../../images/marketing/backgroundCloud.png");
        background-position: 203px 18px, -212px -45px;
        background-size: 163px, 1585px;
      }
      background-repeat: no-repeat;
    }
  }

  .hero-image-logo {
    background-image: url("../../images/marketing/bob-logo-desktop2.png"),
        url("../../images/marketing/small-hero-cloud.png"),
        url("../../images/marketing/small-white-hero-cloud.png"),
        url("../../images/marketing/hero-slogan.png");
      background-position: 50% 134px, 1164px -92px, 1613px 60px, 50% 473px;
      background-size: 909px, 713px, 307px, 992px;
      background-repeat: no-repeat;

      @media (max-width: $video-update-breakpoint1) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 114px, 931px -92px, 1301px 60px, 50% 404px;
        background-size: 816px, 658px, 284px, 806px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint2) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 119px, 721px -57px, 1168px 83px, 50% 364px;
        background-size: 653px, 550px, 266px, 640px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint3) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 143px, 721px -42px, 1016px 113px, 50% 364px;
        background-size: 608px, 550px, 159px, 622px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint4) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 105px, 620px -51px, 849px 75px, 50% 319px;
        background-size: 631px, 546px, 268px, 762px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint5) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 104px, 453px -32px, 1607px 52px, 50% 303px;
        background-size: 464px, 438px, 307px, 661px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint6) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 50% 105px, 489px 2px, 633px 81px, 50% 269px;
        background-size: 479px, 307px, 139px, 556px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint7) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan2.png");
        background-position: 0px 94px, 1607px 52px, 39px 210px;
        background-size: 372px, 307px, 303px;
        background-repeat: no-repeat;
      }
    &.with-jets {
      background-image: url("../../images/marketing/bob-logo-desktop2.png"),
        url("../../images/marketing/small-hero-cloud.png"),
        url("../../images/marketing/small-white-hero-cloud.png"),
        url("../../images/marketing/hero-slogan.png");
      background-position: 20% 134px, 1164px -92px, 1613px 60px, 34% 473px;
      background-size: 909px, 713px, 307px, 992px;
      background-repeat: no-repeat;

      @media (max-width: $video-update-breakpoint1) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 87px 114px, 931px -92px, 1301px 60px, 191px 404px;
        background-size: 816px, 658px, 284px, 806px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint2) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 42px 119px, 721px -57px, 1168px 83px, 154px 364px;
        background-size: 653px, 550px, 266px, 640px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint3) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 92px 143px, 721px -42px, 1016px 113px, 154px 364px;
        background-size: 608px, 550px, 159px, 622px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint4) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 6px 105px, 620px -51px, 849px 75px, 94px 319px;
        background-size: 631px, 546px, 268px, 762px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint5) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 0px 104px, 453px -32px, 1607px 52px, 38px 303px;
        background-size: 464px, 438px, 307px, 661px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint6) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-hero-cloud.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan.png");
        background-position: 0px 105px, 489px 2px, 633px 81px, 41px 269px;
        background-size: 479px, 307px, 139px, 556px;
        background-repeat: no-repeat;
      }

      @media (max-width: $video-update-breakpoint7) {
        background-image: url("../../images/marketing/bob-logo-desktop2.png"),
          url("../../images/marketing/small-white-hero-cloud.png"),
          url("../../images/marketing/hero-slogan2.png");
        background-position: 0px 94px, 1607px 52px, 39px 210px;
        background-size: 372px, 307px, 303px;
        background-repeat: no-repeat;
      }
    }
  }

  .bob-faded-logo-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: -22px;
    background-size: cover;
    padding: 4.5rem 0 0;

    @media (max-width: 1750px) {
      background-position-x: -75px;
    }

    @media (max-width: $video-update-breakpoint2) {
      background-position-x: left;
      background-position-y: top;
    }

    @media (max-width: $video-update-breakpoint3) {
      background-position-x: -55px;
    }

    @media (max-width: $video-update-breakpoint4) {
      background-image: url("../../images/marketing/battle-of-the-bids-tablet.png");
      background-position-x: left;
    }

    @media (max-width: $video-update-breakpoint7) {
      background-image: url("../../images/marketing/battle-of-the-bids-mobile.png");
      background-position-x: left;
    }
  }

  .bob-logo-position-desktop {
    max-width: initial;
  }

  .bob-main-logo-desktop,
  .bob-main-logo-desktop+img {
    margin-right: 0 !important;
    margin-left: 18% !important;

    @media (max-width: $video-update-breakpoint1) {
      margin-left: 26% !important;
    }

    @media (max-width: $video-update-breakpoint4) {
      margin-left: 28% !important;
    }

    @media (max-width: $video-update-breakpoint6) {
      margin-left: 0 !important;
      margin-bottom: 135px;
    }
  }
}

.loading-container {
  margin: auto;
  height: 50vh;
  padding-top: 300px;
  width: 150px;
}