@import "../common/variables.scss";

div#points {
  top: 228px !important;
  transform: translateX(-50%);
  left: 70% !important;
}

.points-tooltip-wrapper {
  position: fixed;
  display: flex;
  top: 150px;
  padding: 0 !important;
  justify-content: right;
  width: 90%;
  z-index: 5;

  @media only screen and (max-height: 668px) {
    top: 15%;
  }

  @media only screen and (min-height: 669px) and (max-height: 800px) {
    top: 80px;
  }

  @media only screen and (max-width: 500px) {
    left: 4%;
  }

  @media only screen and (min-width: 1850px) {
    width: 1850px;
  }

  @media only screen and (max-height: 414px) and (orientation: landscape) {
    justify-content: center;
    top: 70px;
  }

  .points-tooltip {
    border: 1px #395296 solid;
    background-color: $light;
    width: 100%;
    max-width: 350px;
    min-width: 325px;
    padding: 16px 20px;
    z-index: 10;
    border-radius: 25px;

    .close-button {
      cursor: pointer;
      text-align: right;
      padding-bottom: 5px;
      font-size: 20px;
    }

    .heading {
      font-weight: bold;
      padding: 3px 0;
      text-align: center;
      font-size: 20px;
      display: flex;
      justify-content: space-evenly;

      div {
        background-color: #0655a5;
        border-radius: 8px;
        color: $light;
        font-family: WorkSans;
        font-weight: bold;
        font-size: 16px;
        width: 120px;
        height: 30px;
        padding: 3px 0;
      }
    }

    table {
      min-width: 100%;
      border-collapse: collapse;

      td {
        background-color: #fff !important;
      }
    }

    tbody tr {
      border: 0 none !important;
    }

    table td {
      font-family: WorkSans;
      font-weight: 300 !important;
      width: 50%;
      min-width: 150px;
      text-align: center !important;
      font-size: 14.5px !important;
      border: 0 none;
      color: #24ad52 !important;
      padding: 7px 0 !important;
    }

    table td:first-child {
      border-right: 1px solid #dddddd;
      color: $dark !important;
      left: auto;
    }

    @media only screen and (max-height: 414px) and (orientation: landscape) {
      height: 75vh;
      overflow-y: scroll;
    }
  }
}

div#changebetinfo {
  width: 260px;
}

div#accuracycalculated {
  width: 260px;
}

div#pointscalculated {
  width: 260px;
}

div#bonuspoints {
  width: 250px;
}

div#potential-winnings {
  width: 260px;
}

.bet-accuracy-tooltip {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 15px;
}

.points-calculated-tooltip {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 15px;
}
