@import "../common/variables.scss";

.leaderboard-container {
  margin-top: 45px;
  .leaderboard-hero-container {
    background: linear-gradient(180deg, rgba(140, 127, 203, 1) 0%, rgba(255, 255, 255, 0) 15%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 70px;
    text-align: center;
  }
  .bob-logo {
    padding: 20px 0 32px;
  }

  .winners-container {
    display: flex;
    flex-wrap: wrap;

    .title {
      color: #df1e2d;
      padding-top: 30px;
    }

    .winner-card-container {
      font-size: 15px;
      padding-bottom: 30px;
      text-align: center;
      width: 50%;

      .player-company {
        margin: 0 auto;
        max-width: 170px;
        min-height: 54px;
      }

      .date {
        p {
          margin-bottom: 0;
        }
      }
    }

    .round-text {
      color: #0054a6;
      font-size: 18px;
      padding-bottom: 18px;
    }

    .prize-text {
      background-clip: text;
      background-image: linear-gradient(255.13deg, #ce2028 4.95%, #0054a6 93.35%);
      color: transparent;
    }

    .score {
      font-size: 18px;
    }

    .divider-container {
      margin: 0 auto;
      max-width: 100px;
    }

    .avatar-container {
      margin: 0 auto;
      padding-bottom: 10px;
      position: relative;
      width: 75px;

      .player-avatar {
        border-radius: 50%;
      }
    }

    .play-button {
      bottom: 0;
      position: absolute;
      right: 0;
      width: 40px;
    }
  }

  .placeholder-container {
    background-color: rgba(88, 44, 114, 0.1);
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 15px;
    width: 75px;
  }

  .section-title {
    color: $bob-red;
    font-weight: 700;
    text-align: left;
    padding-left: 14px;
  }

  @media (min-width: $breakpoint-sm) {
    .leaderboard-hero-container {
      background-blend-mode: color;
      background-image: linear-gradient(
          330deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 235, 235, 0.2) 50%,
          rgba(140, 127, 203, 0.7) 90%
        ),
        url("../../images/marketing/leaderboard-clouds.png");
      // background-position: top 50px left 0;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 1200px;
    }
    .bob-logo {
      width: 450px;
    }
    .winners-container {
      .winner-card-container {
        width: 33%;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .leaderboard-hero-container {
      background-image: linear-gradient(
          355deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 235, 235, 0.4514180672268907) 18%,
          rgba(140, 127, 203, 1) 90%
        ),
        url("../../images/marketing/leaderboard-clouds.png");
      background-position: top -80px left;
      background-size: 100%;
    }
    .bob-logo {
      padding: 80px 0 60px;
      width: 800px;
    }

    .winners-container {
      padding: 0 50px;

      .winner-card-container {
        font-size: 18px;
        padding-bottom: 60px;
      }
      .play-button {
        transform: unset;
        width: 50px;
      }

      .avatar-container {
        width: 100px;
      }

      .round-text {
        font-size: 28px;
      }
    }
  }

  @media (min-width: $breakpoint-lg) {
    .section-title {
      padding-left: 100px;
    }

    .winners-container {
      padding: 0 100px;
      padding-top: 75px;

      .winner-card-container {
        width: calc(100% / 6);
      }
    }

    .placeholder-container {
      padding: 22px;
      width: 100px;
    }
  }
}

.help-center-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
    174deg,
    rgba(185, 223, 255, 0) 21%,
    rgba(185, 223, 255, 0.6474964985994398) 65%,
    rgba(190, 192, 255, 0.8799894957983193) 100%
  );
  background-position: bottom right;
  background-repeat: no-repeat;

  padding-top: 100px;
  position: relative;
  p {
    color: $dark-blue;
    font-weight: 700;
    padding-bottom: 100px;
    text-align: center;
    margin-bottom: 0;
  }

  a {
    border-bottom: 1px solid $dark-blue;
  }

  .jet {
    padding-bottom: 30px;
    width: 150px;
  }

  .cloud {
    bottom: 0;
    max-width: 500px;
    position: absolute;
    right: 0;
    transform: translate(50%, 30%);
  }
}

.round-selection-header {
  margin: 50px 115px;

  @media (max-width: $breakpoint-md) {
    margin: auto 40px;
  }

  @media (max-width: $breakpoint-sm) {
    margin: auto 20px;
  }
}

.leaderboard-link {
  display: inline-block;
  padding: 5px;

  img {
    max-width: 60%;
  }
}

.table-containers {
  .table-wrapper {
    border-radius: unset;
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: #d8d8d8;
    }

    &::-webkit-scrollbar-thumb {
      background: $bob-red;
    }
  }
  .leaderboard-header-padding {
    padding-top: 70px;
  }
  .leaderboard-table-container {
    font-size: 16px;
    margin: 0 auto;
    padding: 0 10px;
    width: calc(100% - 20px);
    overflow-x: scroll;

    #leaderboard-table {
      background-color: transparent;
    }

    table {
      background-color: transparent;
      min-width: unset;

      td:first-child,
      td:nth-child(2) {
        position: unset;
      }

      td:first-child {
        width: 40px;
      }

      thead {
        border-bottom: 2px solid $light-blue;
        th {
          padding: 10px 0;
        }
      }

      td + td {
        margin-left: 15px;
      }

      .table-row + .table-row {
        border-top: 1px solid rgba(0, 84, 166, 0.3);
      }

      .table-row.current-player {
        border-top: 2px solid rgba(0, 84, 166, 0.7);
        border-bottom: 2px solid rgba(0, 84, 166, 0.7);
      }

      .rank-content {
        width: 50px;
      }

      .player-name-content {
        width: 140px;
        text-transform: capitalize;
      }

      .row-company-content {
        width: 150px;
      }

      .total-points-content-td {
        background-color: rgba(0, 84, 166, 0.15);
      }

      .points-content,
      .bonus-points-content,
      .total-points-content,
      .bet-accuracy-content,
      .winnings-content {
        width: 125px;
      }

      .bonus-points-content,
      .total-points-content,
      .bet-accuracy-content,
      .winnings-content {
        margin: 0 auto;
      }

      .points-content,
      .points-header {
        padding-left: 10px;
      }

      .bonus-points-header {
        padding-right: 10px;
        white-space: nowrap;
      }

      .bet-accuracy-header {
        padding-left: 10px;
        white-space: nowrap;
      }

      .total-points-container {
        text-align: center;
        background-color: rgba(0, 84, 166, 0.15);
        margin: 0 5px;
      }
      .total-points-content {
        text-align: center;
      }
    }

    @media (min-width: $breakpoint-md) {
      overflow-x: hidden;

      #leaderboard-table {
        width: 100%;
      }

      table {
        .company-header {
          padding-right: 30px;
        }

        .points-content,
        .bonus-points-content,
        .total-points-content,
        .bet-accuracy-content {
          width: 100px;
        }

        .winnings-content {
          width: unset;
        }
      }
    }

    @media (min-width: $breakpoint-lg) {
      padding: 0 100px;
      width: calc(100% - 50px);

      table {
        .player-name-content,
        .bonus-points-content,
        .total-points-content,
        .bet-accuracy-content,
        .row-company-content {
          width: unset;
          margin-right: 20px;
        }

        .bet-accuracy-header {
          padding: 10px;
        }

        .total-points-content {
          width: 140px;
        }
      }
    }
  }
}

.table-containers {
  .total-players {
    margin: 30px 0;
  }
}

@media (min-width: $breakpoint-sm) {
  .table-containers {
    &.main {
      background-blend-mode: color;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 75%,
        rgba(190, 192, 255, 0.8799894957983193) 100%
      );
      background-position: top right;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .help-center-container {
    .jet {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(-150%);
    }
    .cloud {
      transform: translate(30%, 30%);
      width: 450px;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .help-center-container {
    .jet {
      transform: translateX(-200%);
    }
    .cloud {
      transform: translate(0, 30%);
    }
  }
}
