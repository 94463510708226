@import "../common/variables.scss";

.total-wrapper {
  display: flex;
  font-family: WorkSans;
  font-weight: bold;
  color: $dark;
  font-size: 18px;
  justify-content: flex-end;
}

.rank-message {
  margin-left: auto;
  margin-right: 75px;
}

.total-points {
  font-weight: bold;
  text-align: right;
}

.place-callout {
  text-align: center;
  flex: auto;
  font-weight: bold;
  padding: 0 0 30px 0;
}

.scoreboard-points {
  background-color: transparent;
  border: 0 none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: #9ce7ff;
  text-decoration: underline;
  cursor: pointer;
}

.score-background {
  background: white;
  display: block;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #fff;
}

.points-link {
  cursor: pointer;
  color: #582c72;
  text-align: left;
  padding-left: 5px;
}

.score-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(circle, #af3540 47%, #3d3d68 100%);
  padding: 26px 36px;
  border-radius: 18px;
  width: 100%;
  margin: 0px auto 60px;
  box-shadow: 0 0px 14px 1px rgba(189, 2, 2, 0.34);

  @media (min-width: $scoreboard-breakpoint4) {
    flex-direction: row;
    align-items: center;
  }

  // FIXED BANNER START
  &.fixed-auction-header {
    position: fixed;
    left: 0;
    z-index: 2;
    width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 10px 15px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    padding: 0.875rem;

    @media (min-width: $scoreboard-breakpoint1) {
      padding: 0.5rem 1.625rem;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (min-width: $scoreboard-breakpoint5) {
      justify-content: flex-start;
    }

    @media (min-width: $scoreboard-breakpoint3) {
      align-items: center;
    }

    @media only screen and (max-width: 1023px), (min-width: 1261px) {
      top: 62px;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1260px) {
      top: 120px;
    }

    @media only screen and (max-width: $scoreboard-breakpoint4) {
      & > * {
        flex: inherit;
      }

      @media only screen and (max-width: 550px) {
        flex-direction: column;
      }
    }

    .logo-wrapper {
      padding: 0.9375rem 1.25rem;
      margin-bottom: 0;
      width: 24.3125rem;
      height: 4.5625rem;
      display: none;

      @media only screen and (min-width: $scoreboard-breakpoint3) {
        display: flex;
      }

      img {
        width: 7.3125rem;
        max-width: none;
      }

      .round-info {
        font-size: 1.25rem;

        div {
          margin-bottom: 0;
        }
      }
    }

    .scoreboard-start-center {
      flex-direction: column;
      justify-content: center;
      align-self: center;
      margin: 0 0 0.626rem;
      align-items: center;
      max-width: none;

      @media (min-width: $scoreboard-breakpoint1) {
        margin-bottom: 0;
      }

      @media (min-width: $scoreboard-breakpoint2) {
        flex-direction: row;
      }

      @media (min-width: $scoreboard-breakpoint3) {
        flex-direction: column;
        max-width: 25.625rem;
      }

      @media (min-width: $scoreboard-breakpoint5) {
        flex-direction: row;
        justify-content: flex-start;
        max-width: calc(100% - 41.4375rem);
        padding-left: 2.5625rem;
      }

      @media (min-width: $scoreboard-breakpoint6) {
        max-width: calc(100% - 63.125rem);
      }
    }

    .scoreboard-start-right {
      flex-direction: row;
      align-self: center;

      @media (min-width: $scoreboard-breakpoint1) {
        width: auto;
      }

      @media (min-width: $scoreboard-breakpoint5) {
        margin-left: auto;
      }
    }

    .scoreboard-start-countdown-title {
      font-size: 1rem;
      margin-bottom: 0;

      @media (min-width: $scoreboard-breakpoint1) {
        font-size: 1.15625rem;
      }

      @media (min-width: $scoreboard-breakpoint2) {
        margin: 0;
      }

      @media (min-width: $scoreboard-breakpoint3) {
        font-size: 1.4375rem;
        line-height: 1.6875rem;
      }

      @media (min-width: $scoreboard-breakpoint5) {
        margin-right: 2.5625rem;
      }
    }

    .scoreboard-start-countdown-wrap {
      @media (min-width: $scoreboard-breakpoint2) {
        margin: 0 auto;
      }

      @media (min-width: $scoreboard-breakpoint5) {
        margin: 0;
      }
    }

    .scoreboard-start-countdown .top-row .cell {
      font-size: 1.375rem;
      line-height: 1.5625rem;

      @media (min-width: $scoreboard-breakpoint3) {
        font-size: 2.5rem;
        line-height: 2.9375rem;
        margin-bottom: 0;

        &:not(:last-of-type) {
          margin-right: 2.5625rem;
        }
      }
    }

    .scoreboard-start-countdown .bottom-row .cell {
      font-size: 0.75rem;

      @media (min-width: $scoreboard-breakpoint1) {
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-top: 0.625rem;

        &:not(:last-of-type) {
          margin-right: 0.4375rem;
        }
      }

      @media (min-width: $scoreboard-breakpoint3) {
        font-size: 1.25rem;
        line-height: 1.4375rem;
        margin-top: -0.3125rem;

        &:not(:last-of-type) {
          margin-right: 2.5625rem;
        }
      }
    }

    .header-stats {
      flex-direction: row;
      width: 100%;
      margin-left: -0.75rem;

      @media (min-width: $scoreboard-breakpoint1) {
        flex-direction: column;
        width: auto;
        margin-left: 0;
        margin-top: -0.4375rem;
      }

      @media (min-width: $scoreboard-breakpoint6) {
        flex-direction: row;
        margin-left: -0.6875rem;
        margin-top: 0;
      }
    }

    .header-stats__button,
    .header-stats__button:first-child:last-child {
      height: 3.625rem;
      font-size: 0.875rem;
      margin-bottom: 0;
      margin-left: 0.75rem;

      @media (min-width: $scoreboard-breakpoint1) {
        height: 2.5625rem;
        width: 17.125rem;
        font-size: 1.125rem;
        margin-left: 0;
        margin-top: 0.4375rem !important;
      }

      @media (min-width: $scoreboard-breakpoint3) {
        height: 3.625rem;
        width: 18.75rem;
      }

      @media (min-width: $scoreboard-breakpoint6) {
        margin-left: 0.6875rem;
        margin-top: 0 !important;
      }
    }

    .header-stats__button.my-season-button + .property-count-desktop,
    .header-stats__button.my-season-button ~ .property-count-mobile {
      @media (min-width: $scoreboard-breakpoint1) {
        width: 100%;
      }

      @media (min-width: $scoreboard-breakpoint6) {
        width: 18.75rem;
      }
    }

    .property-count {
      font-size: 16px;
      width: auto;
      padding: 10px 18px;
      height: 35px;

      @media screen and (max-width: 1200px) {
        width: 185px;
        font-size: 14px;
        padding: 10px;
      }
    }

    .submit-changes {
      width: 254px;
      height: 41px;
      font-size: 18px;
    }

    .my-season {
      flex-direction: row;

      strong {
        margin-right: 5px;
      }
    }
  }
  // FIXED BANNER END

  .my-season-button {
    flex-direction: row;
    border: 1px solid #e50300;
    background: #e50300;
    color: #ffffff;
    border-radius: 5px;
    text-transform: uppercase;

    strong {
      margin-right: 5px;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: $scoreboard-breakpoint4) {
    padding: 15px;
    margin-bottom: 15px;
  }
}

.property-count-desktop,
.property-count-mobile {
  border-radius: 5px;
}

.logo-wrapper {
  display: flex;
  border: 1px solid #ffffff;
  padding: 10px 16px;
  border-radius: 5px;
  color: #ffffff;
  font-family: WorkSans, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 26px;
  box-sizing: border-box;
  max-height: 6.25rem;

  @media (min-width: $scoreboard-breakpoint4) {
    width: 31.25rem;
    justify-content: space-between;
  }

  img {
    width: 200px;
    margin-right: 20px;
  }

  .round-info {
    text-align: right;
    align-self: center;
    font-size: 1.25rem;

    @media (min-width: $scoreboard-breakpoint4) {
      font-size: 1.75rem;
    }

    div:first-of-type {
      margin-bottom: 0.375rem;
    }
  }
}

.scoreboard-start-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: $scoreboard-breakpoint4) {
    width: 300px;
    margin: auto 0;
  }
}

.bids-column {
  text-align: center;
}

.header-stats {
  display: flex;
  justify-content: center;
  margin-left: -0.6875rem;

  @media (min-width: 768px) {
    margin-left: -1.1875rem;
  }

  @media only screen and (min-width: $scoreboard-breakpoint4) {
    flex-direction: column;
    margin-left: 0;
  }

  @media only screen and (min-width: 1200px) {
    .property-count-desktop {
      padding: 10px;
    }
  }

  @media only screen and (min-width: 700px) {
    .property-count-desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border: 1px solid #fff;
      padding: 10px;
      margin-top: 0px;
      height: 58px;
    }

    .property-count-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 699px) {
    .property-count-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      font-size: 0.875rem;
      color: #fff;
      border: 1px solid #fff;
    }

    .property-count-desktop {
      display: none;
    }
  }
}

.header-stats__button {
  width: calc(50% - 0.38125rem);
  height: 3.625rem;
  margin-left: 0.6875rem;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    margin-left: 1.1875rem;
    font-size: 1.375rem;
  }

  @media (min-width: $scoreboard-breakpoint4) {
    width: 100%;
    margin-left: 0;

    &:first-child {
      margin-right: 0;
      margin-bottom: 0.625rem;
    }
  }

  &:first-child:last-child,
  &.property-count-desktop,
  &.property-count-mobile {
    width: 100%;

    @media (min-width: 550px) {
      max-width: 25.5rem;
    }
  }
}

.header-stats__button.my-season-button + .property-count-desktop,
.header-stats__button.my-season-button ~ .property-count-mobile {
  width: calc(50% - 0.38125rem);
  max-width: initial;

  @media (min-width: $scoreboard-breakpoint4) {
    width: 18.75rem;
  }
}

.scoreboard-actions {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 10px;

  .auction-series {
    font-size: 16px;
    font-weight: 600;
    color: #5e7489;
  }

  &.hide {
    display: none;
  }

  .auction-dates {
    font-size: 16px;
    font-weight: 600;
    color: #e1001a;
  }

  .change-two-bets {
    color: #000;
    font-size: 16px;
    text-align: right;

    @media only screen and (max-width: 415px) {
      font-size: 13px;
    }

    .labeltext {
      position: relative;
      left: -310px;
      width: 300px;

      @media only screen and (min-width: 705px) {
        width: 700px;
        left: -710px;
      }
    }
  }
}

.change-bet-info-button {
  border: 2px solid #000;
  font-size: 12px;
  margin-left: 4px;
  border-radius: 50%;

  .change-bet-info-i {
    border: 0;
    background-color: transparent;
    padding: 1px 5px;
    font-weight: 600;
  }
}

.change-bet-info-points {
  border: 2px solid #582c72;
  font-size: 12px;
  margin-left: 4px;
  border-radius: 50%;

  .change-bet-info-i {
    border: 0;
    background-color: transparent;
    padding: 1px 5px;
    font-weight: 600;
  }
}

.change-bet-info-style {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 15px;
}

.change-your-bet-space {
  padding-left: 2px;
}

button:focus {
  outline: none;
}

.change-bet-container {
  .change-bet-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}

.change-bet-dialog {
  position: fixed;
  top: 150px;
  width: 95%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  padding: 35px 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  z-index: 3;

  @media only screen and (max-height: 414px) and (orientation: landscape) {
    top: 65px;
  }

  .btn-close {
    border: 0 none;
    background: transparent;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    color: #000;
    opacity: 1;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .heading {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .body-text {
    font-size: 15px;
  }

  .action-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #582c72;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 30px;
    cursor: pointer;
    max-width: 300px;
    width: 100%;
    border-radius: 40px;
  }
}

.bet-warning-icon {
  position: relative;
  bottom: 2px;
  padding-right: 10px;
}

.bet-error-dialog,
.invalid-bet-dialog {
  position: fixed;
  top: 150px;
  width: 95%;
  max-width: 440px;
  left: 50%;
  transform: translateX(-50%);
  padding: 35px 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  z-index: 4;

  .heading {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #000000;
  }

  .body-text {
    font-size: 15px;
    color: #000000;
  }

  .action-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #582c72;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 30px;
    cursor: pointer;
    max-width: 400px;
    width: 100%;
    border-radius: 40px;
  }
}

.no-more-bets-dialog,
.review-properties-dialog {
  position: fixed;
  top: 150px;
  width: 95%;
  max-width: 610px;
  left: 50%;
  transform: translateX(-50%);
  padding: 35px 15px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  z-index: 4;

  @media only screen and (max-height: 414px) and (orientation: landscape) {
    top: 55px;
    padding: 25px 15px;
  }

  .heading {
    text-transform: uppercase;
    font-size: 19px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #000000;
  }

  .body-text {
    font-size: 15px;
    color: #000000;
    margin: 0 40px 0 40px;
  }

  .action-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #582c72;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 30px;
    cursor: pointer;
    max-width: 400px;
    width: 100%;
    border-radius: 40px;
  }
}

.submit-changes {
  height: 58px;
  border-radius: 5px;
  text-transform: uppercase;
  color: $light;
  font-family: "WorkSans";
  text-align: center;
  background-color: #ce2028;
  border: 0 none;
  line-height: 32px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
  }
}

.see-my-rank {
  font-family: "WorkSans";
  font-weight: bold;
  font-size: 22px;
  height: 69px;
  width: 119px;
  border-radius: 4px;
  background: linear-gradient(227.91deg, #a7233e 0%, #c32c4c 100%);
  border: 0 none;
  color: $light;
  cursor: pointer;
}

.my-season {
  border: 1px solid $light;
  border-radius: 5px;
  font-size: 14px;
  color: $light;
  padding: 6px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  line-height: 22px;

  &:last-child {
    margin-right: 0;
  }

  div {
    font-weight: 100;
  }
}

.scoreboard-cell-property {
  width: 10%;

  @media only screen and (min-width: 551px) {
    width: auto;
  }
}

.your-bet-cell {
  width: 17%;
  text-align: left !important;

  .inputbid {
    font-family: "WorkSans" !important;
  }
}

.scoreboard-cell-bid {
  width: 8%;
  text-align: right !important;
  min-width: 92px;

  .inputbid {
    font-family: "WorkSans" !important;
  }
}

.scoreboard-cell-increment {
  width: 12%;
  text-align: right !important;

  .inputbid {
    font-family: "WorkSans" !important;
  }
}

.scoreboard-cell-checkbox {
  width: 5%;
  text-align: left;

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.scoreboard-cell-bidCount {
  width: 8%;
  text-align: center !important;
}

.scoreboard-cell-remaining {
  width: 10%;
  text-align: right !important;

  .red {
    color: #f00;
  }

  .ended {
    color: #808080;
  }
}

.scoreboard-cell-status {
  width: 15%;
  text-align: right !important;

  .status-content {
    &.reserve-met {
      color: #73e593;
    }

    &.next-bid-meets-reserve {
      color: #9ce7ff;
    }
  }
}

.scoreboard-cell-points {
  width: 10%;
  text-align: right !important;
  padding-right: 20px;
}

.current-player-rank {
  position: absolute;
  width: 97%;
  height: 53px;
  border: 4px solid #2a8fe1;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;

  div {
    min-width: 206px;
    box-sizing: border-box;
    background-color: #2a8fe1;
    border-radius: 4px 4px 0 0;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -39px;
    padding: 5px 0;
  }
}

.leaderboard-player {
  background-color: transparent;
  border: 0 none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: #9ce7ff;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.leaderboard-header-sort {
  border: none;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  color: #ffffff;
}

.leaderboard-header-sort-selected {
  border: transparent;
  background-color: transparent;
  border-color: none !important;
  color: #ce2028;
  outline: none !important;
}

.leaderboard-my-rank {
  float: right;
  background-color: transparent;
  border: solid 1px;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: #e50300;
  cursor: pointer;
  padding: 12px 20px 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  outline: none !important;

  &:hover {
    color: #e6454d;
  }
}

.leaderboard-cell {
  text-align: right;
}

.leaderboard-cell-right {
  text-align: right !important;
}

.leaderboard-cell-center {
  text-align: center !important;
}

.leaderboard-row-active {
  background-color: #0d423a !important;
  color: white;
}

.leaderboard-message {
  color: #e6454d;
  font-size: 16px;
  min-height: 200px;
}

.scoreboard-start-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 21.25rem;
  margin: 0.9375rem auto;

  @media (min-width: $scoreboard-breakpoint1) {
    max-width: 25.625rem;
  }

  @media (min-width: $scoreboard-breakpoint4) {
    margin: 0 auto;
  }
}

.scoreboard-start-countdown-title {
  color: #ffffff;
  font-family: WorkSans, sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 auto 0.75rem;

  @media (min-width: $scoreboard-breakpoint4) {
    font-size: 1.625rem;
    margin: 0;
  }
}

.scoreboard-start-countdown {
  color: #ffffff;
  font-family: WorkSans, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: column;

  .top-row {
    font-size: 1.6875rem;
    line-height: 2.0625rem;
    letter-spacing: 0.221875rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    @media (min-width: $scoreboard-breakpoint1) {
      font-size: 1.375rem;
    }

    @media (min-width: $scoreboard-breakpoint4) {
      font-size: 2.5rem;
    }

    .cell {
      width: 64px;
      text-align: center;
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;

    .cell {
      font-size: 0.875rem;
      width: 4rem;
      text-align: center;

      @media (min-width: $scoreboard-breakpoint1) {
        font-size: 1.375rem;
      }

      @media (min-width: $scoreboard-breakpoint4) {
        font-size: 1.25rem;
      }
    }
  }
}

.spinner-logo {
  animation: spinner-logo-spin infinite 6s linear;
}

.spinner {
  grid-column: 1 / 5;
  text-align: center;
}

@keyframes spinner-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scoreboard-prize-container {
  margin-top: 30px;
  width: 95%;
  max-width: 1420px;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: 910px) {
      flex-direction: column;
    }
  }
}

.points-row {
  .scoreboard-prize-points {
    border: 0.5px solid #b6b4b4;
    font-size: 16px;
    border-radius: 5px;
    color: $light;
    padding: 10px 20px;
    justify-content: center;
    line-height: 22px;
    margin: 10px 20px;
    border-style: outset;
  }
}

/*
LEADERBOARD NAV STYLES - TODO: MOVE OUT OF HERE
*/

.leaderboard-nav {
  display: flex;
  height: 57px;
  align-items: center;
  box-sizing: border-box;

  .nav-links {
    margin-left: auto;

    a {
      font-size: 19px;
      margin-right: 20px;
      text-decoration: none;
      color: #627487;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;

      &.selected {
        color: #ce2028;
        border-bottom: 1px solid #ce2028;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* Input Checkbox Toggle Styling */
.switch {
  display: inline-block;
  height: 22px;
  position: relative;
  width: 44px;
}

.switch-player-change-bet-remaining {
  display: inline-block;
  height: 22px;
  position: relative;
  font-style: normal;
  font-family: "WorkSans";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.switch input#changeTwoBets {
  display: none;
}

.slider {
  background-color: #2a4462;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 16px;
  top: 0.15em;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}

input#changeTwoBets:checked + .slider {
  background-color: #00a125;
}

input#changeTwoBets:checked + .slider:before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

table#scoreboard-table {
  thead {
    &.default {
      height: 63px;
      left: 0 !important;
    }

    &.fixed {
      position: sticky;
      left: 5px;
      top: 50px;
      height: 63px;
      width: 1400px;

      .thead_property {
        position: fixed;
        left: 0px;
        padding-left: 13px;
        width: 323px;
      }
      .thead_bidtype {
        position: absolute;
        left: 544px;
      }
      .thead_bidincrement {
        position: absolute;
        left: 677px;
      }
      .bids-column {
        position: absolute;
        left: 816px;
      }
      .thead_remaining {
        position: absolute;
        left: 992px;
      }
      .header-status {
        position: absolute;
        left: 1206px;
      }
      .thead_points {
        position: absolute;
        left: 1330px;
        padding: 0;
      }

      @media only screen and (max-width: 550px) {
        .thead_property {
          width: 140px;
        }
        .thead_bidtype {
          position: absolute;
          left: 424px;
        }
        .thead_bidincrement {
          position: absolute;
          left: 583px;
        }
        .bids-column {
          position: absolute;
          left: 727px;
        }
        .thead_remaining {
          position: absolute;
          left: 940px;
        }
        .header-status {
          position: absolute;
          left: 1186px;
        }
        .thead_points {
          float: left;
          position: absolute;
          left: 1330px;
          padding: 0;
        }
      }

      @media only screen and (min-width: 1024px) and (max-width: 1260px) {
        top: 103px;
      }
    }
  }
}

/*
  Responsive Layouts
*/
@media only screen and (max-width: $scoreboard-breakpoint4) {
  .score-heading > * {
    .logo-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .round-info {
        line-height: 17px;
      }

      img {
        flex: 1 1 40%;
        height: 65px;
        width: auto;
      }
    }

    .top-row {
      justify-content: space-around;
    }

    .bottom-row {
      justify-content: space-around;
    }

    .header-stats {
      flex-direction: row;

      > * {
        justify-items: center;
      }

      .my-season:first-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: $scoreboard-breakpoint4) {
  .total-wrapper {
    margin-top: 15px;
  }
}

.header-stats-property {
  margin-left: -170px;
  width: 140%;
}

.header-property-ends {
  font-family: WorkSans;
  color: #ffffff;
  text-align: center;
  font-size: 19px;
  padding-bottom: 8px;
}

.thead_yourbet {
  text-align: left;
}

@media only screen and (max-width: 550px) {
  .thead_yourbet {
    text-align: center;
  }
}

.tooltip-wrapper {
  display: flex;
}

.tooltip-element {
  flex: 1;
}
