@import "./variables.scss";

.prizes-section-container {
  font-family: "WorkSans";
  padding: 0px 5px 28px 5px;
  @media (max-width: $video-update-breakpoint5) {
    margin-top: -56px;
  }

  .prizes-number-container {
    font-weight: 700;
    padding: 70px 14px 32px;
  }

  .prizes-number-title {
    font-size: 22px;
    line-height: 35px;
    background: linear-gradient(41deg, #0054a6 1.79%, #582c72 48.47%, #df1e2d 60.39%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .prizes-sub-title {
    color: $dark-blue;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    text-align: center;
  }

  .prizes-places-container {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-left: 12px;
    gap: 27px;
  }

  .place-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex-wrap: wrap;
    min-height: 90px;
    width: 33%;
  }

  .icon-container {
    margin-right: 1rem;
    margin-bottom: 0.5rem;

    img {
      width: 23px;
    }
  }

  .text {
    color: $dark-blue;
    line-height: 36px;
  }

  .award {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }

  .place {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .divider {
    display: none;
  }

  .prizes-video-container {
    position: relative;
    margin: 0 auto;
    padding-top: 30px;
  }

  .video-thumbnail {
    margin: 0 auto;
  }

  .play-button.cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
  }

  @media (min-width: $breakpoint-sm) {
    .prizes-number-title {
      font-size: 30px;
      line-height: 48px;
    }

    .prizes-sub-title {
      font-size: 16px;
      line-height: 24px;
    }

    .prizes-places-container {
      margin-left: 0;
      gap: 74px;
    }

    .place-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }

    .icon-container {
      img {
        width: 38.5px;
      }
    }

    .award {
      font-size: 16px;
      line-height: 24px;
    }

    .place {
      font-size: 16px;
      line-height: 24px;
    }

    .prizes-video-container {
      margin: 0 auto;
      max-width: 712px;
    }

    .divider-container {
      .prize-divider {
        border: 1px solid #0054a6;
        margin: 10px 0;
        opacity: 0.6;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .prizes-number-title {
      font-size: 50px;
      line-height: 80px;
    }

    .prizes-sub-title {
      font-size: 24px;
      line-height: 36px;
    }

    .prizes-places-container {
      margin-top: 40px;
      margin-bottom: 50px;
      gap: 0;
    }

    .place-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      flex-wrap: wrap;
      min-height: 90px;
      width: 33%;
    }

    .icon-container {
      margin-right: 1rem;

      img {
        width: 63px;
      }
    }

    .award {
      font-size: 24px;
      line-height: 36px;
    }

    .place {
      font-weight: 400;
      font-size: 24px;
    }

    .video-thumbnail {
      max-width: 900px;
    }

    .prizes-video-container {
      margin: 0 auto;
      max-width: 1240px;
    }

    .divider-container {
      .divider {
        border: 1px solid #0054a6;
        margin: 10px 0;
        opacity: 0.6;
      }
    }

    .divider-container {
      .prize-divider {
        border: 1px solid #0054a6;
        margin: 10px 0;
        opacity: 0.6;
      }
    }

    @media (min-width: $breakpoint-lg) {
      .video-thumbnail {
        max-width: 1000px;
      }
    }
  }
}
