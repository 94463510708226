@import './variables.scss';

$modal-height-desktop: 40vw;
$modal-width-desktop: 70vw;

$modal-height-mobile: 33vw;
$modal-width-mobile: 75vw;

.message-modal,
.rules-content-modal {
  position: fixed;
  background-color: black;
  top: 25%;
  width: $modal-width-desktop;
  // max-width       : 904px;
  left: 50%;
  transform: translate(-50%, -20%);
  border-radius: 5px;
  z-index: 2000;
  //min-height: 50vw;
  height: 90vh;
  //max-height: 80vh;

  @media (max-width: 1024px) {
    height: 50vh;
    width: $modal-width-mobile;
  }

  @media only screen and (max-width: 600px) {
    // width: calc(100% - 40px);
    position: fixed;
    margin: 0px;
    box-sizing: border-box;
  }

  .message-modal-header {
    text-align: center;
    // background-color: $light;
    border-radius: 4px 4px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    h2 {
      padding-top: 24px;
      padding-bottom: 24px;
      font-weight: bold;
      font-size: 16px !important;
      text-transform: uppercase;
      color: #000;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #808080;
  }

  .message-modal-video {
    height: 100%;
    width: 100%;
  }

  .rules-body-accept {
    @media only screen and (max-height: 800px) {
      bottom: 0 !important;
      padding-bottom: 89px;
    }

    @media (max-width: 600px) {
      bottom: 0 !important;
      padding-bottom: 142px;
    }

    @media only screen and (min-height: 800px) {
      bottom: 150px !important;
    }
  }

  .message-modal-video-body {
    height: 100%;
    position: absolute;
    width: $modal-width-desktop;

    @media (max-width: 1024px) {
      width: $modal-width-mobile;
    }
  }

  .message-modal-body {
    position: absolute;
    top: 77px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden !important;
    overflow-x: hidden;
    // color     : $dark;
    // margin-left : 7px;
    overflow-y: auto;

    table {
      width: 100%;
    }

    h3 {
      font-weight: normal !important;
      font-size: 14px !important;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 14px !important;
    }

    h5 {
      font-size: 14px !important;
    }

    p {
      font-size: 14px !important;
    }

    li {
      font-size: 14px !important;
    }
  }
}

.message-modal {
  background: rgba(0, 0, 0, 0.6);
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    transform: unset;
    border-radius: unset;
    .message-modal-video-body {
      height: 50%;
      max-width: 1440px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        height: 75%;
      }
    }
}