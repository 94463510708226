@import "./variables.scss";

.hall-of-fame {
    &-container {
        color: black;
        font-family: 'WorkSans';
        padding-top: 20px;

        @media (min-width: $breakpoint-sm) {
            padding-top: 30px;
        }

        position: relative;

        & .points,
        .accuracy {
            font-size: 18px;
        }

        & .text-image-container {
            align-items: center;
            display: flex;
            padding-bottom: 28px;

            @media (min-width: 768px) {
                flex-direction: column-reverse;
                margin-top: -100px;
                padding-bottom: 0;
                padding-right: 28px;
            }

            @media (min-width: 1024px) {
                flex-direction: row-reverse;
                margin-top: -170px;
                padding-right: 0;
            }
        }

        .cloud {
            display: none;

            @media (min-width: $breakpoint-md) {
                bottom: 0;
                display: block;
                position: absolute;
                right: 0;
                width: 400px;
                overflow-y: hidden;
            }
        }
    }

    &-logo-container {
        margin: 0 auto;
        text-align: center;
        width: 275px;

        @media (min-width: 768px) {
            width: 500px;
        }

        @media (min-width: 1024px) {
            padding-top: 90px;
            width: initial;
        }
    }

    &-bob-logo {
        width: 815px;
    }

    &-hero-container {
        background:
            linear-gradient(333.5deg, rgba(206, 32, 40, 0.77) -17.4%, rgba(88, 44, 114, 0.77) 47.73%, rgba(6, 85, 165, 0.77) 112.18%),
            url("../../images/marketing/prize-gray-layer.png");
        background-blend-mode: color;
        background-size: 100% 100%;
        padding-top: 45px;
        position: relative;

        .small-cloud-top-right {
            display: none;
            position: absolute;

            @media (min-width: 768px) {
                display: block;
                right: 0;
                width: 200px;
            }

            @media (min-width: 1024px) {
                width: 500px;
                top: 0;
                transform: translate(0, 100%);
            }
        }

        @media (min-width: 1024px) {
            height: 840px;
        }
    }

    &-season-1-winner-container {
        padding: 0 10px;

        @media (min-width: 1024px) {
            padding: 0 80px;
        }
    }

    &-season-1-title {
        color: #24335a;
        padding: 20px 0;
        text-align: center;

        @media (min-width: $breakpoint-sm) {
            padding: 40px 0;
        }

        @media (min-width: $breakpoint-md) {
            padding: 60px 0;
        }
    }

    &-grand-prize-winner {
        color: #ce2028;
    }

    &-grand-prize-winner-name {
        color: #000000;
        font-size: 35px;

        @media (min-width: 1024px) {
            font-size: 40px;
        }
    }

    &-grand-prize-winner-sub-name {
        font-size: 24px;
        padding-bottom: 24px;
    }

    &-charity-container {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &-charity-text-container {
        max-width: 227px;
    }

    &-charity-text {
        background-clip: text;
        background-image: linear-gradient(255.13deg, #CE2028 4.95%, #0054A6 93.35%);
        color: transparent;
        font-size: 18px;
        text-align: center;
        max-width: 250px;

        @media (min-width: $breakpoint-lg) {
            max-width: 200px;

            .brother-sister {
                font-size: 16px;
            }

        }
    }

    &-charity-points {
        text-align: center;
        ;
    }

    &-grand-prize-winner-quote {
        @media (min-width: 768px) {
            max-width: 458px;
        }

        @media (min-width: 1024px) {
            font-size: 18px;
            max-width: 640px;
        }
    }

    &-winner-image-container {
        position: relative;
        width: 130px;

        .play-video-text {
            display: none;
            text-decoration: underline;

            @media (min-width: 768px) {
                display: inline;
            }
        }

        .play-button-container {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(0, -20%);

            @media (min-width: 768px) {
                transform: translate(-60%, -20%);
            }

            @media (min-width: 1024px) {
                left: 5%;
                top: 50%;
                transform: unset;
            }
        }

        .play-button {
            width: 44px;

            @media (min-width: 1024px) {
                width: 65px;
            }
        }

        @media (min-width: 1024px) {
            width: 450px;
        }
    }
}

.cursor {
    cursor: pointer;
}

.top-round-winners-container {
    padding: 0 10px;

    @media (min-width: 1024px) {
        padding: 0 80px;
    }

    .title {
        color: #df1e2d;
        padding-top: 30px;
    }

    .round-winners-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
    }

    .winner-card-container {
        font-size: 15px;
        padding-bottom: 30px;
        text-align: center;
        width: 50%;

        @media (min-width: 768px) {
            width: 33%;
        }

        @media (min-width: 1024px) {
            font-size: 18px;
            padding-bottom: 60px;
            width: 25%;
        }

        .player-company {
            margin: 0 auto;
            max-width: 170px;
        }
    }

    .round-text {
        color: #0054a6;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 18px;

        @media (min-width: $breakpoint-md) {
            font-size: 28px;
        }
    }

    .prize-text {
        background-clip: text;
        background-image: linear-gradient(255.13deg, #CE2028 4.95%, #0054A6 93.35%);
        color: transparent;
    }

    .score {
        font-size: 18px;
    }

    .divider-container {
        margin: 0 auto;
        max-width: 100px;
    }

    .avatar-container {
        margin: 0 auto;
        padding-bottom: 10px;
        position: relative;
        width: 75px;

        @media (min-width: 1024px) {
            width: 160px;
        }
    }

    .play-button {
        bottom: 0;
        position: absolute;
        right: 0;
        width: 40px;

        @media (min-width: 1024px) {
            transform: unset;
            width: 65px;
        }
    }
}

.testimonial-carousel {
    padding: 0 10px;
    text-align: center;

    .section-title {
        color: #24335A;
        margin: 0 auto;
        max-width: 290px;
        text-align: center;
        padding-bottom: 16px;

        @media (min-width: 768px) {
            max-width: unset;
        }
    }

    .arrows {
        height: 35px;
        width: 35px;

        @media (min-width: 768px) {
            display: none;
        }
    }

    .testimonial-image-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 768px) {
            padding: 0 50px;
        }
    }

    .testimonial-winner-image-1,
    .testimonial-winner-image-2,
    .testimonial-winner-image-4,
    .testimonial-winner-image-5 {
        display: none;
        filter: grayscale(1);
    }

    .testimonial-winner-image-3 {
        padding-bottom: 16px;
        width: 112px;

        @media (min-width: 768px) {
            width: 140px;
        }

        @media (min-width: 1024px) {
            width: 200px;
        }
    }

    @media (min-width: 768px) {

        .testimonial-winner-image-2,
        .testimonial-winner-image-4 {
            display: block;
            width: 92px;
        }
    }

    @media (min-width: 1024px) {
        margin: 0 auto;
        max-width: 90%;

        .testimonial-winner-image-1,
        .testimonial-winner-image-5 {
            display: block;
            width: 98px;
        }

        .testimonial-winner-image-2,
        .testimonial-winner-image-4 {
            width: 126px;
        }
    }

    .testimonial-name {
        font-size: 18px;
    }

    .testimonial-title {
        font-size: 14px;
    }

    .testimonial-text {
        margin: 0 auto;
        max-width: 540px;

        @media (min-width: $breakpoint-md) {
            text-align: left;
        }

    }

    .testimonial-quote-container {
        align-items: center;
        display: flex;
        position: relative;

        .arrows {
            display: none;
            height: 35px;
            width: 35px;

            @media (min-width: 768px) {
                display: block;
            }

            @media (min-width: 1024px) {
                height: 60px;
                width: 60px;
            }
        }

        .testimonial-quote,
        .testimonial-unquote {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        .testimonial-text-container {
            margin: 0 auto;
            position: relative;
        }

        .testimonial-quote {
            left: -20px;
            position: absolute;
            top: -20px;
        }

        .testimonial-unquote {
            bottom: -20px;
            position: absolute;
            right: -20px;
        }

        @media (min-width: $breakpoint-sm) {
            min-height: 200px;
        }

        @media (min-width: $breakpoint-md) {
            margin: 0 auto;
            max-width: 900px;

            .testimonial-quote {
                left: -50px;
                top: -30px;
            }

            .testimonial-unquote {
                bottom: -30px;
                right: -50px;
            }
        }
    }
}

.divider {
    border-top: 0.5px solid #0054a6;
    margin: 10px 0;
}

.bold-title {
    font-weight: 700;
}

.section-title {
    font-size: 22px;

    @media (min-width: 1024px) {
        font-size: 36px;
    }
}