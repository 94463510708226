@import "../common/variables.scss";

.botButton {
  width: 168px;
  height: 46px;
  position: fixed;
  bottom: 62px;
  right: 12px;
  box-sizing: border-box;
  border-style: none;
  border-radius: 23px;
  background-color: red;
  color: white;
  z-index: 11;
  display: inline-block;
  font-size: 0.875em;

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      background-color: white;
      color: red;
    }
  }
  
  @media (min-width: $leadBot-breakpoint1) {
    border-radius: 8px;
    bottom: 54px;
    padding: 10px 10px;
  }
}

.icon {
  margin-right: 6px;
}