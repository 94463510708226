@import "../common/variables.scss";

.round-selection-trigger {
  background-color: transparent;
  border: 0 none;
  color: $bob-red;
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 28px;
  margin-right: 2.1875rem;
  position: relative;

  img {
    border: 1px solid $bob-red;
    border-radius: 50%;
    padding: 2px;
    margin-left: 7px;
    transform: rotateX(0);
    transition: transform ease-in-out 0.2s;
  }

  &.active {
    img {
      transform: rotateX(180deg);
    }
  }

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 30px;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 36px;
  }
}

.round-selection-scoreboard {
  background-color: transparent;
  border: 0 none;
  color: #582c72;
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 28px;
  margin-right: 2.1875rem;
  position: relative;

  img {
    border: 1px solid #582c72;
    border-radius: 50%;
    padding: 2px;
    margin-left: 7px;
    transform: rotateX(0);
    transition: transform ease-in-out 0.2s;
  }

  &.active {
    img {
      transform: rotateX(180deg);
    }
  }
}

.round-selection {
  font-size: 15px;
  font-weight: normal;

  .round-selection-dropdown {
    position: absolute;
    background-color: #fff;
    color: #000;
    border: 1px solid #414141;
    border-radius: 5px;
    top: 47px;
    max-height: 180px;
    overflow-y: auto;
    z-index: 3;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    border: 1px solid transparent;

    &:nth-child(even) {
      background-color: #efefef;
    }

    &.current {
      border: 1px solid #cf0002;

      & button {
        font-weight: bold;
      }

      span {
        font-weight: bold;
      }
    }

    button {
      font-weight: normal;

      span {
        font-weight: 300;
      }
    }
  }

  button {
    background-color: transparent;
    border: 0 none;
    padding: 12px;
    display: block;

    span {
      font-weight: 300;
    }
  }
}
