@import "./variables.scss";

tr > td {
    img.caret {
        position: absolute;
        right: 10px;
        top: 15px;
    }
}

/** round table styling **/
.round-table-container {
    font-size: 14px;
    min-height: 250px;
    max-width: 500px;
    width: 100%;

    @media (min-width: $breakpoint-sm) {
        max-width: 728px;
        margin: 0 auto;
    }

    @media (min-width: $breakpoint-md) {
        max-width: 750px;
        margin: initial;
        padding: 0 50px 0 20px;
        font-size: 16px;
    }

    .round-table {
        max-width: 100%;
        min-width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 30px;
        overflow: hidden;
        border-collapse: collapse;

        thead {
            position: relative;
            background-color: transparent;
            border-bottom: 2px solid $purple;

            tr {
                background-color: $purple;
                border: none !important;
            }

            th {
                padding: 3px 10px;
                line-height: 18px;
                font-size: 12px;
                border-right: solid 2px #fff;
                color: #fff;
                text-align: center;

                @media (min-width: $breakpoint-sm) {
                    padding: 5px 28px;
                    line-height: 20px;
                    font-size: 14px;
                }

                @media (min-width: $breakpoint-md) {
                    padding: 5px 15px;
                    line-height: 24px;
                    font-size: 16px;
                }
            }
        }

        tbody {
            tr:nth-of-type(odd) {
                border: none !important;
            }

            tr:nth-of-type(even) {
                background-color: rgba(37, 6, 19, 0.03);
                border: none !important;
            }

            tr>td {
                border-right: solid 3px #fff;
            }

            td:first-child {
                background-color: initial;
            }

            td {
                padding: 10px 0;
                text-align: center !important;
                font-size: 14px;

                @media (min-width: $breakpoint-md) {
                    font-size: 16px;
                }
            }
        }

    }
}


/** scoring table styling **/
.rules-table-container {
    margin-top: 10px;

    .rules-table {
        width: 100%;
        min-width: 100%;
        box-shadow: 0px 2.24px 22.8px 0px rgba(2, 74, 148, 0.35);
        border-radius: 43px;

        th:first-child,
        td:first-child {
            border-right: solid 1px #ddd;
        }

        thead  {
            border-bottom: none !important;
            font-size: 14px;
        }

        thead > tr,
        tbody > tr {
            border: none !important;
        }
    
        thead > tr > th {
            padding: 15px 35px !important;
            font-size: 14px;
            text-align: center;   
            line-height: 16px;  
            font-weight: 700;

            div {
                background-color: #0655A5;
                border-radius: 7px;
                color: #fff;
                width: 100px;
                height: 25px;
                padding: 5px;
            }
        }
        
        tbody > tr > td {
            padding: 15px 25px !important;
            text-align: center !important;
            padding: 12px 15px !important;
            font-size: 18px;     
            font-weight: 600 !important;       
            &:nth-child(2) {
                color: #39CB6A;
            }
        }

        @media (min-width: $breakpoint-sm) {
            thead > tr > th {
                font-size: 20px;
                line-height: 24px;
                
                div {
                    width: 145px;
                    height: 36px;
                    padding: 6px;
                }
            }

            tbody > tr > td {
                font-size: 18px;
                line-height: 22px;
            }
        }


        @media (min-width: $breakpoint-md) {
            thead > tr > th {
                font-size: 24px;
                line-height: 30px;
                
                div {
                    width: 180px;
                    height: 45px;
                    padding: 8px;
                }
            }

            tbody > tr > td {
                font-size: 22px;
                line-height: 30px;
            }
        }
      }
}
