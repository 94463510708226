@import "variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px 20px;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div {
    color: $light;
    margin: 14px 10px;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px 20px;
  }

  .login-link {
    border: 1px solid #ce2028;
    cursor: pointer;
  }

  .signup-link {
    border: 1px solid #0655a5;
    margin-right: 20px;
    cursor: pointer;
  }
}

.sub-nav {
  display: flex;
  color: $light;
  font-family: "WorkSans";
  justify-content: center;
  margin: 0 auto;

  a {
    color: $light;
    margin: 14px;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      text-decoration: none;
      text-decoration-line: line-through;
      text-decoration-color: red;
      background-color: rgba(255, 0, 0, 0.35);
      box-shadow: 0px 0px 20px 10px rgba(255, 0, 0, 0.15);
    }
  }
}

.back-nav {
  text-transform: uppercase;
  color: $medium-blue;
  font-size: 22px;

  a {
    color: inherit;
    text-decoration: none;
  }
}

#navbarSupportedContent {
  justify-content: center;
}

.header-logo {
  width: 125px;

  img {
    width: 116px;
    max-width: 116px;
    height: auto;
  }
}

.profile {
  display: flex;
}

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 25px;
  align-self: center;
}

.bob-rules {
  position: absolute;
  width: 60%;
  margin: 0 auto;
  top: 130px;
  left: 20%;
  background-color: #f1f1f1;
  box-shadow: 0px 0px 10px 10px grey;
  z-index: 1;

  .modalTopHeading {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    background-color: #fff;
    padding: 20px;
  }

  .modalHeading {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    color: #504f4f;
  }

  .modalContent {
    padding: 20px;
    color: #9a9a9a;
    height: 300px;
    overflow-y: scroll;
  }

  .modalFooter {
    background-color: #fff;
    padding: 20px;
    display: flex;
    font-size: 18px;
    justify-content: center;

    .modalCancel {
      padding: 10px 0;
      width: 30%;
      text-align: center;
      background-color: #9f9f9f;
      color: #fff;
      margin-right: 20px;
      border-radius: 5px;
      cursor: pointer;
    }

    .modalContinue {
      padding: 10px 0;
      width: 30%;
      text-align: center;
      background-color: #ab2440;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.mobile-profile__button {
  background-color: transparent;
  border: 0;
  line-height: 20px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: $light;
  text-transform: uppercase;
  white-space: nowrap;
}

.login-spinner {
  animation: spinner-logo-spin infinite 6s linear;
  max-height: 55%;
  width: auto;
}

.loggin-message {
  color: $dark;
  font-size: 14px;
  margin: 5px 5px 16px 5px;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  color: black;
  animation: 1s blink infinite;
}
.loader__dot:nth-child(2) {
  color: black;
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  color: black;
  animation-delay: 500ms;
}

@media only screen and (max-width: 1024px) {
  div.collapse.show {
    position: absolute;
    top: 0;
    left: 0;
    width: 289px;
    height: 100vh;
    transition: all 0.4s ease-in-out;
  }

  .mobile-profile {
    display: block;
    list-style: none;
    color: white;
    text-transform: uppercase;
    padding-bottom: 0 !important;

    ul {
      padding-left: 0;
    }

    ul li {
      list-style: none;
      padding-bottom: 1rem;
      margin: 0 0.5rem;
    }

    a {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 520px) {
  .container-fluid {
    flex-wrap: nowrap !important;

    .header-logo {
      flex: 1 1 100%;

      a.narbar-brand {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .nav-right {
      flex: 1 1 100%;
    }
  }
}

.nav-logos {
  display: flex;
  margin: 0 auto;
}

div.nav-right {
  display: none;

  @media (min-width: 1025px) {
    display: block;
  }
}

// ---------------------- Tablet Treatment
// -- Portrait
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-flex {
    justify-content: space-between !important;
  }

  .main-content {
    margin-top: 30px;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
}

// -- Large Screen (Desktop)
@media only screen and (min-width: 1261px) {
  .mobile-profile {
    display: none;
  }
}

.loginModal {
  margin: 0 auto;
  width: 90%;
  max-width: 442px;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  border-radius: 4px;
}

.dropdown-caret-container {
  margin-right: 15px;

  .mobile-link {
    display: flex;
    .selected {
      border-bottom: 1px solid red;
    }

    .caret-image {
      margin-left: 5px;
      &.active {
        transform: rotateX(180deg);
      }
    }
  }

  .desktop-link {
    display: none;
    @media (min-width: 1025px) {
      display: block;
    }
  }
}

section#cs-header nav {
  .bob-accordion {
    align-items: center;
    display: flex;
    min-height: 60px;

    @media (min-width: 1025px) {
      display: none;
    }

    .content-container {
      display: -webkit-flex;
      display: flex;
      border-radius: 2px;
      -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
      flex-direction: column;
      margin-left: 20px;
      min-width: 200px;
      a {
        color: #000;
        padding-left: 20px;
        &:hover, &:active {
          border: 1px solid #582c72;
          border-radius: 2px;
          background: rgba(88,44,114,.2);
          font-weight: 700;
        }
        font-weight: 400;
      }
    }
  }
}

.navbar-light .navbar-nav .dropdown-caret-container .nav-link.mobile-link {
  font-size: 15px;

  @media (min-width: 1025px) {
    display: none;
  }
}