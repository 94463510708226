@import "./variables.scss";

.help-container {
  color: $dark;
  font-family: "WorkSans";
  padding-top: 20px;
  position: relative;

  @media (min-width: $breakpoint-sm) {
    padding-top: 30px;
  }

  .cloud {
    display: none;

    @media (min-width: $breakpoint-sm) {
      display: block;
      position: absolute;
      width: 134px;
      overflow-y: hidden;
      left: 0;
      top: 0;
    }
  }

  .help-hero-container {
    background: url("../../images/marketing/help-hero-bg.png");
    background-size: 100% 100%;
    padding-top: 45px;
    position: relative;
    height: 408px;

    @media (min-width: $breakpoint-sm) {
      height: 437px;
    }

    @media (min-width: $breakpoint-md) {
      height: 728px;
    }

    .help-heading-container {
      text-align: center;

      @media (min-width: $breakpoint-sm) {
        margin-top: 30px;
        padding: 0 20px;
        text-align: initial;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
      }

      @media (min-width: $breakpoint-md) {
        padding: 0 40px;
        margin-top: 100px;
      }

      .help-title {
        color: $dark-blue;
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        padding: 25px 0;

        @media (min-height: $breakpoint-sm) {
          padding: 15px 0;
        }

        @media (min-width: $breakpoint-md) {
          font-size: 36px;
          line-height: 24px;
        }
      }

      .support-text {
        font-size: 16px;
        line-height: 24px;
        max-width: 355px;
        text-align: center;
        margin: 0 auto;
        padding: 5px 0;

        @media (min-width: $breakpoint-sm) {
          text-align: initial;
          margin: initial;
          padding: initial;
          line-height: 28px;
        }

        @media (min-width: $breakpoint-md) {
          max-width: 583px;
          font-size: 18px;
        }
      }

      .support-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
        margin-top: 20px;

        @media (min-width: $breakpoint-sm) {
          gap: 20px;
        }

        .support-option {
          width: 354px;
          height: 60px;
          border-radius: 76px;
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0px 4px 105px 0px hsla(210, 98%, 29%, 0.25);
          text-align: center;

          @media (min-width: $breakpoint-sm) {
            width: 374px;
          }

          @media (min-width: $breakpoint-md) {
            width: 503px;
          }
        }

        .number {
          color: $light-blue;
          font-size: 22px;
          font-weight: 700;
          height: 60px;
          line-height: 57px;

          img {
            padding-right: 15px;
            padding-bottom: 5px;
          }

          a {
            text-decoration: none;
            &:hover {
              color: unset;
            }
          }

          @media (min-width: $breakpoint-sm) {
            line-height: 62px;
          }

          @media (min-width: $breakpoint-md) {
            height: 90px;
            font-size: 28px;
            line-height: 90px;
          }
        }

        .email {
          color: $bob-red;
          font-size: 16px;
          font-weight: 700;
          line-height: 63px;
          text-transform: uppercase;
          height: 60px;
          line-height: 66px;

          img {
            padding-right: 5px;
            padding-bottom: 5px;
          }

          a {
            text-decoration: none;
            cursor: pointer;
          }

          @media (min-width: $breakpoint-sm) {
            font-size: 18px;
            line-height: 62px;
          }

          @media (min-width: $breakpoint-md) {
            height: 90px;
            font-size: 24px;
            line-height: 95px;
          }
        }
      }
    }
  }

  .help-logo-container {
    margin: 0 auto;
    text-align: center;
    width: 275px;

    @media (min-width: $breakpoint-sm) {
      width: 500px;
    }

    @media (min-width: $breakpoint-md) {
      width: initial;
      padding-top: 50px;
    }
  }

  .help-bob-logo {
    width: 815px;

    @media (min-width: $breakpoint-sm) {
      width: 456px;
    }

    @media (min-width: $breakpoint-md) {
      width: 815px;
    }
  }

  .help-faq-container {
    .help-faq-header-container {
      text-align: center;
      padding: 12px;

      .faq-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 47px;
        color: $dark-blue;
      }

      .faq-heading {
        font-size: 16px;
        line-height: 28px;
      }
    }

    .table-container {
      .faq-section {
        .h-table {
          max-width: 100%;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 30px;
          overflow: hidden;
          min-width: 100%;

          tbody > tr.question {
            border: solid 1px gray;
            position: relative;
            cursor: pointer;
            width: 100%;

            td {
              text-align: left;
              padding-left: 10px; 
              width: 100%;
              white-space: normal;
              
              p {
                margin: 0;
                margin-right: 30px;
              }
            }
          }

          tbody > tr.answer {
            overflow: hidden;
            width: 100%;
            display: none;

            td {
              text-align: left;
              padding-left: 10px;
              width: 100%;
              p {
                white-space: pre-line;
              }
            }
          }

          thead > tr {
            position: relative;
            border: solid 1px gray;
            width: 100%;
          }

          tr > td {
            text-align: left;
            padding-left: 10px;
          }

          thead > tr > td:first-of-type {
            color: $purple;
            font-size: 16px;
            line-height: 19px;
          }

          td:first-child {
            background-color: transparent !important;
          }

          td > div {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            opacity: 0.1;
            top: 0px;
          }
        }
      }
    }
  }
}

.help-footer-container {
  background: linear-gradient(
      333.35deg,
      rgba(206, 32, 40, 0.77) -48.61%,
      rgba(88, 44, 114, 0.4543) 14.2%,
      rgba(6, 85, 165, 0.77) 72.94%
    ),
    url("../../images/marketing/help-footer-bg.png");
  background-blend-mode: color;
  background-size: 100% 100%;
  height: 265px;

  .help-footer-text {
    font-size: 16px;
    color: $dark-blue;
    font-weight: 400;
    text-align: center;
    padding-top: 55px;
    cursor: pointer;

    u {
      font-weight: 600;
    }

    @media (min-width: $breakpoint-sm) {
      padding-top: 26px;
      height: 140px;
      font-size: 18px;
    }

    @media (min-width: $breakpoint-md) {
      padding-top: 80px;
      height: 313px;
    }
  }

  @media (min-width: $breakpoint-sm) {
    height: 140px;
  }

  @media (min-width: $breakpoint-md) {
    height: 215px;
  }

  .jet {
    position: absolute;
    bottom: 2%;
    right: 15%;

    @media (min-width: $breakpoint-sm) {
      bottom: 1%;
    }

    @media (min-width: $breakpoint-lg) {
      bottom: 1%;
      right: 29%;
    }
  }

  .cloud-r {
    position: absolute;
    right: -35%;
    bottom: 0%;
    width: 313px;
    height: 142px;

    @media (min-width: $breakpoint-sm) {
      right: -9%;
      bottom: -1%;
    }

    @media (min-width: $breakpoint-lg) {
      width: 588px;
      height: 214px;
      right: 1%;
    }
  }

  .cloud-l {
    display: none;

    @media (min-width: $breakpoint-lg) {
      display: block;
      width: 295px;
      height: 193px;
      left: 1%;
    }
  }
}
