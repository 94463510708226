@import "variables.scss";

.main-content.about-tenx {
  .page-heading {
    margin-top: 20px;
  }

  .about-tenx-container {
    text-align: center;

    .about-tenx-frame {
      margin-top: 30px;
      height: 7928px;
      min-width: 1440px;

      @media (max-width: $about-tenx-breakpoint2) {
        // 1202px - 1440px
        height: 7642px;
        min-width: 1200px;
      }

      @media (max-width: $about-tenx-breakpoint3) {
        // 1031px - 1201px
        height: 7250px;
        min-width: 1024px;
      }

      @media (max-width: $about-tenx-breakpoint4) {
        // 993px - 1030px
        height: 8932px;
        min-width: 991px;
      }

      @media (max-width: $about-tenx-breakpoint5) {
        // 769px - 992px
        height: 11055px;
        min-width: 767px;
      }

      @media (max-width: $about-tenx-breakpoint6) {
        // 601px - 768px
        height: 9990px;
        min-width: 598px;
      }

      @media (max-width: $about-tenx-breakpoint7) {
        // 501px - 600px
        height: 10214px;
        min-width: 504px;
      }

      @media (max-width: $about-tenx-breakpoint8) {
        // 401px - 500px
        height: 9962px;
        min-width: 416px;
      }

      @media (max-width: $about-tenx-breakpoint9) {
        // 301px - 400px
        height: 9727px;
        min-width: 301px;
      }

      @media (max-width: $about-tenx-breakpoint10) {
        // under 300px
        height: 10448px;
        min-width: 200px;
      }
    }

    .contact-form-container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 1440px;

      .about-tenx-form {
        height: 500px;
      }

      .contact-form-image {
        background-image: url('../../images/marketing/loopnet-contact-form.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        min-height: 350px;
        width: 100%;
      }

      .title {
        color: transparent;
        background-clip: text;
        background-image: linear-gradient(255.13deg, #CE2028 4.95%, #0054A6 93.35%);
        border-top: 15px solid $light-blue;
        border-bottom: 15px solid $light-blue;
        font-size: 32px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 18px;
        font-weight: 700;
      }

      .text {
        line-height: 30px;
        margin: 15px 0;
      }

      @media (min-width: $breakpoint-sm) {
        margin-top: 50px;
        flex-direction: row;

        .contact-form-image {
          margin-right: 20px;
        }
      }

      @media (min-width: $breakpoint-md) {
        .title {
          font-size: 46px;
        }
      }

      @media (max-width: $about-tenx-breakpoint2) {
        max-width: 1200px;
      }

      @media (max-width: $about-tenx-breakpoint3) {
        max-width: 1024px;
      }

      @media (max-width: $about-tenx-breakpoint4) {
        max-width: 991px;
      }

      @media (max-width: $about-tenx-breakpoint5) {

        max-width: 767px;
      }

      @media (max-width: $about-tenx-breakpoint6) {
        max-width: 598px;
      }

      @media (max-width: $about-tenx-breakpoint7) {

        max-width: 504px;
      }

      @media (max-width: $about-tenx-breakpoint8) {
        max-width: 416px;
      }

      @media (max-width: $about-tenx-breakpoint9) {
        max-width: 301px;
      }

      @media (max-width: $about-tenx-breakpoint10) {
       max-width: 200px;
      }
    }
  }
}
