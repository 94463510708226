@import "../common/variables.scss";

.prizes {
  &-container {
    color: $dark-blue;
    font-family: 'WorkSans';
  }

  &-hero-container {
    background:
      linear-gradient(333.5deg,
        rgba(206, 32, 40, 0.77) -17.4%,
        rgba(88, 44, 114, 0.77) 47.73%,
        rgba(6, 85, 165, 0.77) 112.18%),
      url("../../images/marketing/prize-gray-layer.png");
    background-blend-mode: color;
    background-size: 100% 100%;
    height: 720px;

    .small-hero-cloud {
      position: absolute;
      display: none;
    }

    .small-prizes-cloud {
      display: none;
    }
  }

  &-logo-container {
    width: initial;
    text-align: center;
    margin: 0 auto;
    padding-top: 60px;
  }

  .bob-logo {
    width: 275px;
  }

  &-title-section {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  &-trophy-container {
    height: 376px;
    margin-top: 80px;
    margin-bottom: 71px;
  }

  &-trophy-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 50px;

    .trophy-container {
      position: relative;

      .trophy {
        width: 110px;
      }

      .confetti {
        position: absolute;
        min-width: 262px;
        max-height: 216px;
        right: -65%;
        top: -24%;
      }
    }

    .trophy-container.silver {
      margin-right: 10px;

      .trophy {
        width: 110;
      }

      .confetti {
        min-width: 262px;
        max-height: 216px;
        position: absolute;
        right: -71%;
        top: -50%;
      }
    }

    .rewards {
      text-align: center;
    }
  }

  &-section-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin: auto;
  }

  &-trophy-title-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }

  &-trophy-title-value {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  &-trophy-title-plus {
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
  }

  &-trophy-title-charity {
    color: $dark;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }

  .title-bigger {
    font-size: 36px;
  }

  &-section-gradient-title:nth-of-type(2) {
    background: linear-gradient(270.62deg, #CE2028 40.83%, #582C72 48.93%, #0054A6 62.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: none;
  }

  &-section-gradient-title {
    background: $light-gradient-text-bg;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 4px 4px 4px rgba(36, 51, 90, 0.3);
  }

  .gradient-bigger {
    font-size: 24px;
  }

  // TODO: consider removing?
  .btn {
    border: 0 none;
    border-radius: 82px;
    background: $purple;
    cursor: pointer;
    color: #fff;
    opacity: 1;
    padding: 15px 50px;
    font-weight: bold;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .divider-container {
    margin: 0 auto;
    max-width: 93%;

    .divider {
      position: relative;
      border: 1px solid $purple;
      margin: 10px 0;
    }

  }

  &-bottom-section-container {
    text-align: center;
    height: 1192px;
    padding: 10px 0;
    background:
      linear-gradient(157deg, rgba(6, 85, 165, 0.4) -4.96%, rgba(88, 44, 114, 0.4) 80.51%, rgba(206, 32, 40, 0.4) 136.63%),
      url("../../images/marketing/prizes-bottom-bg.png");
    background-size: 100% 100%;
    background-blend-mode: color;

    .marketing-footer-text {
      color: $dark;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      margin: 0 auto;
      padding: 0 5px;
      max-width: 322px;
    }

    .bonus-footer-text {
      color: $dark;
      margin: 0 auto;
      font-size: 18px;
      line-height: 32px;
      max-width: 334px;
      width: 97%;
    }

    .wreath-section-container {
      margin-top: 50px;
      margin-bottom: 60px;
    }

    .round-awards-container {
      margin-top: 25px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;

      .awards-border {
        height: 75px;
        border-right: 1px solid $purple;
        margin-left: 20px;
        margin-right: 20px;
      }

      .round-awards {
        align-self: center
      }

      .footer-text {
        color: $dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
      }

      .footer-text:nth-child(2) {
        width: 100%;
      }
    }

    .how-to-play-container {
      width: 314px;
      margin: 0 auto;
      margin-top: 45px;
      text-align: center;

      .how-to-play-text {
        color: $dark;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
      }

      .how-to-play-btn-container {
        margin-top: 15px;

        .btn-htp {
          width: 314px;
          height: 65px;
          margin-top: 20px;
          margin-bottom: 10px;
          border: 0 none;
          border-radius: 82px;
          background: $purple;
          font-size: 20px;
          cursor: pointer;
          color: #fff;
          opacity: 1;
          padding: 15px 50px;
          font-weight: bold;

          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .small-bottom-left-cloud {
      display: none;
    }

    .small-bottom-right-cloud {
      display: block;
      position: absolute;
      width: 291px;
      right: 0;
      bottom: 6%;
    }


    .small-jet {
      display: none;
    }

  }

  @media (min-width: $breakpoint-sm) {
    &-hero-container {
      height: 800px;
      background-size: cover;

      .small-hero-cloud {
        display: block;
        position: absolute;
        width: 254px;
        top: 0%;
        right: 12%;
        transform: translate(36%, 43%);
      }

      .small-prizes-cloud {
        display: none;
      }
    }

    &-logo-container {
      margin: 0 auto;
      padding-top: 80px;


      .bob-logo {
        width: 456px;
      }
    }

    &-section-title {
      font-size: 24px;
      text-align: center;
    }

    &-trophy-section {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 20px;

      .trophy-container {
        .trophy {
          width: 185px;
        }

        .confetti {
          min-width: 262px;
          max-height: 280px;
          position: absolute;
          right: -23%;
          top: -28%;
        }
      }

      .trophy-container.silver {
        margin-right: 30px;

        .trophy {
          width: 185px;
        }

        .confetti {
          min-width: 262px;
          max-height: 280px;
          position: absolute;
          right: -22%;
          top: -16%;
        }

      }

      .rewards {
        white-space: nowrap;
        text-align: center;
      }

      &-section-gradient-title {
        font-size: 36px;
        white-space: nowrap;
      }

      .gradient-bigger {
        font-size: 36px;
        line-height: 42.23px;
        white-space: nowrap;
      }

      .title-bigger {
        font-size: 60px;
        line-height: 70.38px;
      }

    }

    &-trophy-section:nth-of-type(2) {
      height: 350px;
      margin-top: -115px;
      margin-bottom: 53px;
    }

    &-trophy-title-number {
      font-size: 36px;
      line-height: 54px;
    }

    &-trophy-title-value {
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      line-height: 42px;
    }

    &-trophy-title-plus {
      font-size: 40px;
      line-height: 60px;
    }

    &-trophy-title-charity {
      color: $dark;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    &-trophy-title-charity {
      width: 100%;
    }

    &-bottom-section-container {
      height: 1368px;
      padding: 25px 0;

      .marketing-footer-text {
        margin: 0 auto;
        font-size: 18px;
        line-height: 32px;
        max-width: 712px;
      }

      .bonus-footer-text {
        color: $dark;
        margin: 0 auto;
        font-size: 18px;
        line-height: 32px;
        max-width: 625px;
      }

      .wreath-section-container {
        margin-top: 27px;
        margin-bottom: 29px;
      }

      .round-awards-container {
        margin-top: 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;

        .awards-border {
          height: 100px;
          border-right: 1px solid $purple;
          margin-left: 35px;
          margin-right: 35px;
        }

        .footer-text {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .how-to-play-container {
        width: 314px;
        margin: 0 auto;
        margin-top: 45px;
        text-align: center;

        .how-to-play-text {
          color: $dark;
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
        }

        .how-to-play-btn-container {
          margin-top: 15px;

          .btn-htp {
            width: 314px;
            height: 65px;
            margin-top: 20px;
            margin-bottom: 10px;
            border: 0 none;
            border-radius: 82px;
            background: $purple;
            font-size: 20px;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            padding: 15px 50px;
            font-weight: bold;

            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .small-bottom-left-cloud {
        display: block;
        position: absolute;
        width: 490px;
        left: -1%;
        bottom: 8%;
      }

      .small-bottom-right-cloud {
        display: block;
        position: absolute;
        width: 313px;
        right: 0%;
        bottom: 9%;
      }

      .small-jet {
        display: block;
        position: absolute;
        width: 152px;
        right: 17%;
        bottom: 12%;
        transform: translate(-19%, 16%);
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &-hero-container {
      height: 877px;
      padding-top: 25px;
      background-size: 100% 100%;

      .small-hero-cloud {
        display: block;
        position: absolute;
        top: 4%;
        right: 9%;
        width: 300px;
        transform: translate(34%, 40%);
      }

      .small-prizes-cloud {
        display: none;
      }
    }

    &-logo-container {
      padding-top: 117px;

      .bob-logo {
        width: 815px;
      }
    }

    &-section-title {
      font-size: 30px;
      line-height: 42.3px;
    }

    &-trophy-section {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 20px;
      margin-top: 93px;

      .trophy-container {
        flex-shrink: 0;

        .trophy {
          width: 227px;
        }

        .confetti {
          min-width: 350px;
          position: absolute;
          right: -31%;
          top: -20%;
        }
      }

      .trophy-container.silver {
        .trophy {
          width: 227px;
        }

        .confetti {
          min-width: 350px;
          max-height: 280px;
          position: absolute;
          right: -29%;
          top: -16%;
        }
      }

      .rewards {
        text-align: center;
      }

      &-section-gradient-title {
        font-size: 36px;
        white-space: nowrap;
      }

      .gradient-bigger {
        font-size: 54px;
        line-height: 48px;
      }

      .title-bigger {
        font-size: 89px;
        line-height: 113px;
      }

    }

    &-trophy-section:nth-of-type(2) {
      height: 300px;
      margin-top: 0;
      margin-bottom: 53px;
    }

    &-trophy-title-number {
      font-size: 36px;
      line-height: 54px;
    }

    &-trophy-title-value {
      font-size: 30px;
      font-weight: 500;
      line-height: 60px;
    }

    &-trophy-title-plus {
      font-size: 52px;
      line-height: 96px;
    }

    &-trophy-title-charity {
      font-size: 22px;
      line-height: 32px;
    }

    &-trophy-title-charity {
      width: 100%;
    }

    &-bottom-section-container {
      height: 1534px;
      padding: 30px 0;

      .marketing-footer-text {
        font-size: 20px;
        line-height: 32px;
        max-width: 780px;
        margin: 0 auto;
      }

      .bonus-footer-text {
        color: $dark;
        font-size: 20px;
        line-height: 32px;
        max-width: 832px;
        margin: 0 auto;
      }

      .wreath-section-container {
        margin-bottom: 120px;
      }

      .round-awards-container {
        margin-top: 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;

        .awards-border {
          height: 142px;
          border-right: 1px solid $purple;
          margin-left: 50px;
          margin-right: 50px;
        }

      }

      .how-to-play-container {
        width: 314px;
        margin: 0 auto;
        margin-top: 45px;
        text-align: center;

        .how-to-play-text {
          color: $dark;
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
        }

        .how-to-play-btn-container {
          margin-top: 15px;

          .btn-htp {
            width: 314px;
            height: 65px;
            margin-top: 20px;
            margin-bottom: 10px;
            border: 0 none;
            border-radius: 82px;
            background: $purple;
            font-size: 20px;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            padding: 15px 50px;
            font-weight: bold;

            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .small-bottom-left-cloud {
        display: block;
        position: absolute;
        width: 500px;
        left: 0;
        bottom: 10%;
      }

      .small-bottom-right-cloud {
        display: block;
        position: absolute;
        width: 588px;
        right: 0%;
        bottom: 10%;
      }

      .small-jet {
        display: block;
        position: absolute;
        width: 152px;
        right: 30%;
        bottom: 14%;
        transform: translate(0%, 40%);
      }

    }
  }

  @media (min-width: $breakpoint-lg) {
    &-hero-container {
      height: 877px;
      padding-top: 25px;
      background-size: 100% 100%;

      .small-hero-cloud {
        display: block;
        position: absolute;
        top: -3%;
        right: 10%;
        width: 615px;
        transform: translate(31%, 40%);
      }

      .small-prizes-cloud {
        display: block;
        position: absolute;
        top: 18%;
        left: 0%;
      }
    }


    &-logo-container {
      margin: 0 auto;
      text-align: center;
      padding-top: 130px;

      .bob-logo {
        width: 815px;
      }
    }

    &-title-section {
      margin-top: 76px;
      margin-bottom: 40px;
    }

    &-section-title {
      font-size: 36px;
      line-height: 42.3px;
    }

    &-trophy-section {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 20px;
      margin-top: 93px;

      .trophy-container {
        margin-right: 83px;

        .trophy {
          width: 327px;
        }

        .confetti {
          min-width: 710px;
          max-height: 536px;
          position: absolute;
          right: -60%;
          top: -48%;
        }
      }

      .trophy-container.silver {
        margin-right: 71px;

        .trophy {
          width: 327px;
        }

        .confetti {
          min-width: 710px;
          max-height: 536px;
          position: absolute;
          right: -35%;
          top: -50%;
        }
      }

      .silver {
        padding-right: 75px;
      }

      .rewards {
        text-align: center;
      }

      &-section-gradient-title {
        font-size: 36px;
        white-space: nowrap;
      }

      .gradient-bigger {
        font-size: 60px;
        line-height: 70px;
      }

      .title-bigger {
        font-size: 96px;
        line-height: 113px;
      }

    }

    &-trophy-section:nth-of-type(2) {
      height: 700px;
      margin-top: 0;
      margin-bottom: 53px;
    }

    &-trophy-title-number {
      font-size: 36px;
      line-height: 54px;
    }

    &-trophy-title-value {
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
    }

    &-trophy-title-plus {
      font-size: 64px;
      line-height: 96px;
    }

    &-trophy-title-charity {
      font-size: 28px;
      line-height: 32px;
    }

    &-trophy-title-charity {
      width: 100%;
    }


    .divider-container {
      max-width: 85%;
    }

    &-bottom-section-container {
      height: 1641px;
      padding: 30px 0;

      .marketing-footer-text {
        font-size: 20px;
        line-height: 32px;
        width: 53%;
        max-width: 768px;
        margin: 0 auto;
      }

      .bonus-footer-text {
        color: $dark;
        font-size: 20px;
        line-height: 32px;
        max-width: 787px;
        margin: 0 auto;
      }

      .wreath-section-container {
        margin-bottom: 120px;
      }

      .bonus-section-container {
        position: relative;
        padding-top: 33px;
        z-index: 1;
      }

      .round-awards-container {
        margin-top: 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;

        .awards-border {
          height: 142px;
          border-right: 1px solid $purple;
          margin-left: 50px;
          margin-right: 50px;
        }

        .footer-text {
          font-size: 20px;
          line-height: 32px;
        }

      }

      .how-to-play-container {
        width: 314px;
        margin: 0 auto;
        margin-top: 45px;
        text-align: center;

        .how-to-play-text {
          color: $dark;
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
        }

        .how-to-play-btn-container {
          margin-top: 15px;

          .btn-htp {
            width: 314px;
            height: 65px;
            margin-top: 20px;
            margin-bottom: 10px;
            border: 0 none;
            border-radius: 82px;
            background: $purple;
            font-size: 20px;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            padding: 15px 50px;
            font-weight: bold;

            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .small-bottom-left-cloud {
        display: block;
        position: absolute;
        width: 730px;
        left: 0;
        bottom: -28%;
        z-index: -1;
      }

      .small-bottom-right-cloud {
        display: block;
        position: absolute;
        width: 588px;
        right: 0%;
        bottom: -40%;
      }

      .small-jet {
        display: block;
        position: absolute;
        width: 152px;
        right: 17%;
        bottom: -18%;
        transform: translate(-19%, 16%);
        z-index: 12;
      }

    }
  }

}