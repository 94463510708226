@import "../common/variables.scss";

.placard-layout {
  width: 100%;
  max-width: 1675px;
  margin: 0 auto;

  h2 {
    text-align: center;
    color: $dark;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    margin: 34px 0;

    @media only screen and (max-width: 1100px) {
      font-size: 19px;
    }
  }

  .page-heading-no-properties {
    font-size: 20px;
    color: red;
    text-align: center;
    margin: 50px 0 50px 0;
    font-weight: 100;

    @media (min-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
    }

    .date {
      color: white;
    }
  }

  .landing-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 0 20px;

    button {
      margin-left: auto;
      border: 0 none;
      font-size: 16px;
      color: $light;
      background: $purple;
      border-radius: 31px;
      width: 174px;
      text-align: center;
      font-weight: 300;
      padding: 7px 18px;
    }

    @media only screen and (max-width: 1770px) {
      width: 93%;
      margin: 0 auto;
    }
  }

  .propertiescount {
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .placard-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    @media only screen and (max-width: 400px) {
      display: block;
      width: 100% !important;
    }

    @media only screen and (max-width: 1770px) {
      margin: 0 auto;
    }
  }

  .spinner-logo {
    animation: spinner-logo-spin infinite 6s linear;
  }

  .spinner {
    grid-column: 1 / 5;
    text-align: center;
  }

  @keyframes spinner-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

@media only screen and (min-width: 651px) {
  .max-1920 {
    padding: 2em !important;
  }

  .bob-top {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100%;
  }

  .landing-header {
    align-items: center;
  }
}

@media only screen and (max-width: 650px) {
  .cs-section-content {
    .row.max-1920 {
      padding: 0;
    }
  }

  .landing-header {
    flex-direction: column;
    align-items: left;

    .properties {
      line-height: 1em;
    }

    button {
      margin: 0 auto;
      margin-left: 0px !important;
    }
  }
}

.placard-item {
  background-color: transparent;
  flex: 1 1 24%;
  transition: border ease-in 0.25s;
  position: relative;
  color: $dark;
  margin: 10px;
  border: 1px solid #627487;
  border-radius: 10px;
  padding: 11px;

  .property-details {
    font-family: "WorkSans" !important;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    div:nth-child(1) {
      text-align: left;
      font-weight: bold;
    }

    div:nth-child(2) {
      text-align: right;
    }

    .border-bottom-1px {
      border-bottom: 1px solid #ccc;
    }

    div.property-meta {
      ul {
        padding-left: 25px;
        list-style: none;

        li::before {
          content: "•";
          color: red;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    .propertyimage {
      height: 202px;
      img {
        object-fit: cover;
        background-size: cover;
        background-position: center center;
        height: 202px;
        width: 100%;
      }
    }
    .overlay-container {
      position: relative;
      display: inline-block;
    }

    .gray-filter {
      filter: grayscale(100%);
      opacity: 0.8;
    }

    .overlay-container .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(88, 44, 144, 0.8);
      transition: background-color 700ms ease-in-out;
    }

    .overlay-text-top {
      font-family: "WorkSans";
      position: absolute;
      width: 300px;
      top: 13%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      opacity: 0;
    }

    .overlay-text-bottom {
      font-family: "WorkSans";
      position: absolute;
      width: 260px;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s ease-in;
    }

    .overlay-text-error {
      font-family: "WorkSans";
      position: absolute;
      width: 260px;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .large-number {
      font-size: 25px;
      font-weight: 600;
    }

    .overlay-container .overlay-text-top {
      opacity: 1;
    }

    .overlay-container .overlay-text-bottom {
      opacity: 1;
    }

    .logos {
      height: 65px !important;
      border-radius: 5px;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(255, 255, 255, 0)),
        color-stop(60%, #ffffff)
      );
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 60%);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 60%);
      align-items: flex-start;

      & > div {
        margin-left: auto;
        padding-right: 25px;
      }

      img:nth-child(1) {
        max-width: 100px;
        max-height: 63px;
      }
    }

    .placard-data1 {
      padding: 13px 0;
      border-bottom: 1px solid #ccc;

      div {
        text-align: left;
        font-weight: 100;
      }

      div:first-child {
        font-size: 17px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 25px;
      }

      div:empty::after {
        content: " ";
        white-space: pre;
      }

      div:last-child {
        font-size: 17px;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .placard-data2 {
      font-size: 15px;
      padding: 10px 0;
      height: 67px;

      ul {
        padding-left: 0;
        margin-bottom: 0;
      }

      li {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        list-style-position: inside;
        list-style-type: disc;
      }
    }

    .property-type-seemore {
      padding: 5px 0 !important;
      border-bottom: 1px solid #ccc;
    }

    .placard-seemore {
      padding: 10px 0;
      font-size: 16px;
      color: #0056b6;
      font-weight: 600;
      cursor: pointer;
    }

    .float-twocolumns {
      padding: 0;
      height: auto;
      margin-bottom: 0;
    }
  }

  .float-twocolumns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 10px 0;
    margin-bottom: 18px;
  }

  .highlight-text {
    padding-top: 12px;
  }

  .property-bid {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 16px;
    letter-spacing: 0.04rem;

    div {
      font-weight: normal !important;
    }

    .current-bid,
    .starting-bid {
      color: $bob-red;
    }

    .sold-unsold {
      color: $purple;
    }
  }

  .submit-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
  }

  .submit-bet-controls {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    transition: border-color 0.3s;
  }

  .bet-controls {
    flex: 1;
  }

  .bet-controls:nth-child(1) {
    flex-basis: 40%;
  }

  .submit-middle-div {
    flex-grow: 1;
    width: 60%;
  }

  .submit-last-div {
    flex-grow: 1;
    display: none;
    margin-left: 5px;
    transition: all 1s linear;

    &.show {
      display: block;
    }
  }

  .background-border {
    border: 1px solid red;
    width: 100%;
  }

  .remove-bet-btn {
    color: #582c72;
    border-radius: 5px;
    height: 42px;
    width: 115px;
    border: 1px solid #582c72;
    background-color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
  }
  .property-submit-btn {
    color: white;
    border-radius: 0px 5px 5px 0px;
    height: 42px;
    width: 115px;
    background-color: #627487;
    border: none;
    padding: 12px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;

    &.active-submit {
      background-color: #582c72;
    }
  }

  .inputbid {
    box-sizing: border-box;
    font-family: "WorkSans" !important;
    font-size: 16px;
    padding: 8px 10px;
    width: 100%;
    height: 42px;
    text-align: center;
    background-color: transparent;
    color: #7e7e7e;
    border-collapse: collapse;
    border: 1px solid #627487;
    border-radius: 5px 0px 0px 5px;
    display: inline-block;

    &:focus {
      outline: $purple;
      border: 1px solid $purple;
    }
  }

  .bet-input {
    display: flex;
    width: 100%;

    .inputbid {
      box-sizing: border-box;
      font-size: 16px;
      padding: 8px 10px;
      width: 255px;
      min-width: 75%;
      text-align: center;
      background-color: transparent;
      border-collapse: collapse;
      border: 1px solid #627487;
      border-radius: 5px;
      display: inline-block;

      &.read-only {
        cursor: pointer;
        color: $purple;
        border: 1px solid $purple;
      }

      @media only screen and (max-width: 768px) {
        width: 222px;
        min-width: 80%;
      }
    }

    .submit-button {
      border: 1px solid $purple;
      background: $purple;
      color: #ffffff;
      width: 133px;
      height: 40px;
      margin-left: -40px;
      border-radius: 0px 5px 5px 0px;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      cursor: pointer;

      &:disabled {
        border: 1px solid #627487;
        background: #627487;
        cursor: initial;
      }

      @media only screen and (max-width: 768px) {
        width: 106px;
      }
    }
  }

  /* Input Checkbox Toggle Styling */
  .switch {
    display: inline-block;
    height: 22px;
    position: relative;
    width: 44px;
  }

  .switch input {
    display: none;
  }

  &.selected {
    //  border: 2px solid #582c72;
    border: 2px solid $purple;
    border-radius: 10px;
    margin: 10px;

    .inputbid {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #582c72;
      line-height: inherit;

      &:focus {
        outline: $purple;
        border: 1px solid $purple;
      }

      &::placeholder {
        color: #fff;
      }
    }

    .logos {
      //  background: linear-gradient(90deg, hsla(0, 0%, 29%, 0), #2f723e 16%, #fff 77%);
    }
  }
}

.property-select {
  text-overflow: "";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../images/downArrow.png) no-repeat 60px center;
  background-position-x: 346px;
  color: $light;
  padding: 4px 17px 4px 10px;
  margin: 0px 10px;
  height: 50px;
  width: 382px;
  border: 1px solid #999;
  font-size: 16px;
  border-radius: 5px;
}

.property-select option {
  overflow: hidden;
  font-size: 16px;
  position: absolute;
  background-color: #081426;
}

.property-container {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.property-stats-container {
  display: flex;
  flex-direction: column;
  flex: 4;
  margin: 0px 30px 0px 20px;
}

.property-selection {
  width: 400px;
  flex: 1;
  margin-left: 30px;
}

.pizza-tracker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 85px;
  margin-right: 10px;
}

.pizza-tracker-card {
  width: 32%;
  padding: 30px 0 30px;
  display: flex;
  align-content: center;
  background-image: url(../../images/red-divider.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #2d394d;
  border-radius: 5px;
  box-shadow: 1px 1px 10px #213852;
}

.graph-container {
  padding-top: 20px;
  width: 100%;
  height: 540px;
}

div[data-highcharts-chart] {
  height: 100%;
  width: 100%;
}

.pizza-tracker-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: "WorkSans";
  color: $light;
  font-size: 14px;
  padding: 0 20px 0 20px;
}

.tracker-figure {
  font-family: "WorkSans";
  font-size: 25px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.bid-glow {
  text-shadow: 8px 4px 21px rgba(73, 242, 80, 0.59);
}

.current-bid {
  color: #49f250;
}

.average-bet {
  color: #34d5df;
}

.market-guidance {
  color: #d2cd42;
}

.tracker-label {
  width: 140px;
  text-align: center;
}

.rolloff {
  color: #ce2028;
}

.tracker-deliminator {
  line-height: 10px;
}

.property-card-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
  margin: 0px;
  border-bottom: 1px solid #ccc;

  .property-card-border {
    width: 2px;
    background-color: grey;
  }

  .property-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 6px 0px;
  }

  .box {
    font-family: "WorkSans" !important;
    min-height: 33px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    line-height: 1;

    &.property-header {
      text-align: right !important;
      padding-right: 10px;
    }

    &.property-value {
      text-align: left !important;
      font-weight: 300 !important;
      padding-left: 10px;
      color: #0054a6;
    }
  }
}

.bid-amount-currency {
  margin-left: 5px;
}
