@import "../common/variables.scss";

.slash-section-wrapper {
  color: #000000;
  font-weight: 300;
  margin-top: 0 !important;
  background: #ffffff;
  line-height: 1.5;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
}

.title {
  color: $dark-blue;
  font-size: 22px;
  font-weight: 700;

  @media (min-width: $breakpoint-sm) {
    font-size: 24px;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 36px;
  }
}

.splash-header {
  color: #24335a;
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 50px;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: $video-update-breakpoint2) {
    font-size: 2rem;
  }

  @media (max-width: $video-update-breakpoint4) {
    font-size: 1rem;
  }
}

.prize-container {
  display: flex;
  flex-wrap: wrap;
}

.prize-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.prize-item img {
  margin-right: 10px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  align-self: center;
}

.view-box-informed {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 3%;

  @media (max-width: $video-update-breakpoint6) {
    margin: 0 auto;
  }

  // @media (min-width: $video-update-breakpoint2) {
  //   margin-top: 5%;
  //   margin-left: 3%;
  //   align-items: normal;
  // }
}

.social-media-tags {
  padding: 30px 0 25px;
  text-align: left;
  @media (max-width: $breakpoint-sm) {
    text-align: center;
  }
}

.social-media-tags a + a {
  padding-left: 24px;
  @media (max-width: 320px) {
    padding-left: 16px;
  }
}

.informed-block {
  margin: 0 auto;
}

.social-media-badges {
  width: 55%;
  padding: 20px 0px 42px 0px;

  @media (max-width: $video-update-breakpoint6) {
    padding: 10px 0px 17px 0px;
  }
}

.informed-text {
  text-align: left;
  font-size: 1.3rem;
  padding-top: 25px;

  @media (max-width: $video-update-breakpoint2) {
    font-size: 1rem;
    max-width: 493px;
    padding-top: 0px;
  }

  @media (max-width: $video-update-breakpoint4) {
    padding-bottom: 7px;
    font-size: 0.6rem;
    max-width: 293px;
  }

  @media (max-width: $video-update-breakpoint3) {
    font-size: 0.8rem;
    max-width: 393px;
  }
}

.more-questions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 30px 30px;

  @media (max-width: $video-update-breakpoint6) {
    font-size: 10px;
  }
}

.informed-bg {
  min-height: 27vh;
  margin: 0;
  padding: 0;
  height: 41vw;
  max-height: 800px;
  background: linear-gradient(
      326.63deg,
      rgba(206, 32, 40, 0.77) -26.71%,
      rgba(6, 85, 165, 0.37) 83.93%
    ),
    linear-gradient(108.78deg, rgba(255, 255, 255, 0.5) 8.43%, rgba(0, 110, 221, 0.5) 102.08%),
    url("../../images/marketing/backgroundCloud.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: color, normal, normal;

  &.with-jet {
    background: linear-gradient(
        326.63deg,
        rgba(206, 32, 40, 0.77) -26.71%,
        rgba(6, 85, 165, 0.77) 83.93%
      ),
      linear-gradient(108.78deg, rgba(255, 255, 255, 0.5) 8.43%, rgba(0, 110, 221, 0.5) 102.08%),
      url("../../images/marketing/inverted-jet.png");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: color, normal, normal;

    .informed-logo {
      min-height: 27vh;
      margin: 0;
      padding: 0;
      height: 41vw;
      max-height: 800px;
      background: url("../../images/marketing/informBobLogo.png"),
        url("../../images/marketing/season2inform.png"),
        url("../../images/marketing/small-inform-cloud.png");
      background-size: 30%, 14%, 30%, cover;
      background-position: 92% 48%, 86% 62%, left 79%, center;
      background-repeat: no-repeat;
      background-blend-mode: color;

      @media (max-width: $video-update-breakpoint4) {
        min-height: 30vh;
        background: none;
      }
    }
  }

  .informed-logo {
    min-height: 27vh;
    margin: 0;
    padding: 0;
    height: 41vw;
    max-height: 800px;
    background: none;

    @media (min-width: 769px) {
      background: url("../../images/marketing/informBobLogo.png"),
        url("../../images/marketing/season2inform.png"),
        url("../../images/marketing/prizes-cloud-right.png");
      background-size: 50%, 20%, 30%;
      background-position: 95% 30%, 73% 57%, right 79%;
      background-repeat: no-repeat;
      background-blend-mode: color;
    }
    @media (max-width: $video-update-breakpoint4) {
      min-height: 30vh;
    }
  }

  @media (max-width: $video-update-breakpoint4) {
    &.with-jet {
      background: linear-gradient(
          326.63deg,
          rgba(206, 32, 40, 0.77) -26.71%,
          rgba(6, 85, 165, 0.77) 83.93%
        ),
        linear-gradient(108.78deg, rgba(255, 255, 255, 0.5) 8.43%, rgba(0, 110, 221, 0.5) 102.08%),
        url("../../images/marketing/inverted-jet.png");
      background-size: cover, cover, 130%;
      background-position: 50%, 50%, -1% 39%;
      background-repeat: no-repeat;
    }
    background-size: cover, cover, 130%;
    background-position: center, center, -1% 39%;
    background-repeat: no-repeat;
  }

  @media (max-width: $video-update-breakpoint6) {
    &.with-jet {
      min-height: 37vh;
      background: linear-gradient(
          326.63deg,
          rgba(206, 32, 40, 0.77) -26.71%,
          rgba(6, 85, 165, 0.37) 83.93%
        ),
        linear-gradient(108.78deg, rgba(255, 255, 255, 0.5) 8.43%, rgba(0, 110, 221, 0.5) 102.08%);
      background-position: center;
      background-size: cover;
    }
    min-height: 37vh;
    background: linear-gradient(
        326.63deg,
        rgba(206, 32, 40, 0.77) -26.71%,
        rgba(6, 85, 165, 0.37) 83.93%
      ),
      linear-gradient(108.78deg, rgba(255, 255, 255, 0.5) 8.43%, rgba(0, 110, 221, 0.5) 102.08%);
    background-position: center;
    background-size: cover;
  }
}

.button-wrapper {
  width: 100%;
  text-align: left;
  @media (max-width: $video-update-breakpoint6) {
    text-align: center;
  }
}

a.informed-button {
  &:hover {
    color: white;
  }
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
  @media (min-width: $breakpoint-md) {
    margin: 0;
  }
}

.informed-button {
  background-color: #582c72;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 700;
  line-height: 1;
  align-self: center;
  padding: 0;
  border-radius: 82px;
  height: 62px;
  width: 62%;
  border: none;
  outline: none;

  &:hover {
    -webkit-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    -moz-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
  }

  @media (max-width: $video-update-breakpoint2) {
    font-size: 13px;
    height: 41px;
    width: 230px;
  }

  @media (max-width: $video-update-breakpoint4) {
    font-size: 17px;
    height: 51px;
    width: 80%;
  }
}

.borderdivider {
  padding: 3px;
  background-image: url("../../images/marketing/largeBlackdivider.svg");
  background-repeat: no-repeat;
  background-position: 99% 59%;

  @media (max-width: $video-update-breakpoint7) {
    background-image: url("../../images/marketing/black-divider.svg");
    background-position: 98% 52%;
  }
}

.hof-container {
  margin: 9% 4%;
  min-width: 325px;

  .splash-header {
    font-size: 24px;

    @media (min-width: $breakpoint-md) {
      font-size: 36px;
    }
  }
}

.hof-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(88, 44, 114, 0.2);
  border-radius: 30px;
  min-width: 200px;
}

.hof-section {
  min-width: 200px;
  flex: 1;
  margin: 25px 15px;

  .image-container {
    display: inline-block;
    position: relative;
    .blue-overlay {
      background-color: #1982ec40;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
    }
  }

  @media (max-width: $video-update-breakpoint3) {
    flex-basis: calc(39% - 20px);
  }
  @media (max-width: $video-update-breakpoint5) {
    flex-basis: calc(50% - 20px);
  }
}

.hof-button-wrapper {
  min-width: none;
  margin-top: 20px;
  @media (max-width: $video-update-breakpoint3) {
    min-width: 80%;
  }
}

.hof-text {
  margin: 20px 40px;
}

.hof-button {
  background-color: #582c72;
  font-family: WorkSans, Helvetica, Arial, sans-serif;
  font-size: 17px;
  color: white;
  font-weight: 700;
  line-height: 1;
  align-self: center;
  padding: 0;
  border-radius: 82px;
  height: 62px;
  width: 87%;
  border: none;
  outline: none;
  text-transform: uppercase;

  &:hover {
    -webkit-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    -moz-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
  }

  @media (max-width: $video-update-breakpoint1) {
    font-size: 13px;
    height: 55px;
    width: 200px;
  }

  @media (max-width: $video-update-breakpoint2) {
    font-size: 13px;
    width: 200px;
  }

  @media (max-width: $video-update-breakpoint3) {
    font-size: 16px;
    width: 280px;
  }

  @media (max-width: $video-update-breakpoint6) {
    font-size: 17px;
  }
}

.hof-container img {
  filter: brightness(0.9);
}
