.property-type-selection-trigger {
	background-color: transparent;
	border: 0 none;
	color: #582C72;
	font-weight: 700;
	font-size: 18px;
	text-transform: uppercase;
	cursor: pointer;


	img {
		color: #582C72;
		border: 1px solid #fff;
		border-radius: 50%;
		padding: 2px;
		margin-left: 2px;
		transform: rotateX(0);
		transition: transform ease-in-out 0.2s;
	}

	&.active {
		img {
			transform: rotateX(180deg);
		}
	}

}

.property-type-selection-trigger {
	padding-right: 30px;
}

.property-type-selection {

	font-size: 15px;
	font-weight: normal;

	.property-type-selection-dropdown {
		position: absolute;
		background-color: #fff;
		border: 1px solid #414141;
		color: #000;
		border-radius: 5px;
		top: 55px;
		max-height: 260px;
		overflow-y: auto;
		z-index: 3;

		.property-type-selection-label {
			float: right;
			padding-top: 10px;
			padding-right: 10px;
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	li {
		border: 1px solid transparent;


		&.selected {
			background-color: #EFEFEF;
		}

		&:first-child {
			border-bottom: 1px solid black;
		}

		&.none-selected button {
			font-weight: bold !important;

		}

		button {
			font-weight: normal;

			span {
				font-weight: 300;
			}
		}
	}

	button {
		background-color: transparent;
		border: 0 none;
		padding: 12px;

		span {
			font-weight: 300;
		}
	}
}

@media only screen and (min-width: 1024px) {
	.property-type-selection-dropdown {
		left: 18%;
		transform: translateX(-50%);
		width: 300px;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 1024px) {
	.property-type-selection-dropdown {
		left: 30%;
		transform: translateX(-50%);
		top: 50px !important;
		width: 300px;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 992px) {
	.property-type-selection-dropdown {
		left: 38%;
		top: 50px !important;
	}
}

@media only screen and (max-width: 812px) {
	.property-type-selection-dropdown {
		left: 36%;
	}
}

@media only screen and (max-width: 768px) {
	.property-type-selection-dropdown {
		left: 38%;
	}
}

@media only screen and (max-width: 667px) {
	.property-type-selection-dropdown {
		left: 44%;
	}
}

@media only screen and (max-width: 415px) {
	.property-type-selection-dropdown {
		left: 50%;
	}
}

@media only screen and (min-width: 325px) {
	.property-type-selection-trigger {
		padding-right: 0;
	}
}