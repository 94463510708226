@import "../common/variables.scss";

.game-rules {
    &-container {
        color: $dark;
        margin-top: 50px;
    }

    &-hero-container {
        background: url("../../images/marketing/back-how-to-play.png");
        background-size: 100% 100%;
        background-blend-mode: color;
        height: 809px;

        .hero-cloud {
            display: none;
        }

        .hero-cloud-right {
            display: none;
        }

    }

    &-logo-container {
        padding-top: 20px;
        padding-bottom: 10px;
        text-align: center;
    }

    &-bob-logo {
        width: 273px;
    }

    &-section-steps-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        .steps-sub-title {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            font-size: 16px;
            width: 50%;
            font-weight: 600;

            .heading {
                display: flex;
                align-items: end;
                white-space: nowrap;
                margin-bottom: 13px;

                .number {
                    font-family: 'Poppins';
                    color: $purple;
                    font-weight: 800;
                    font-size: 100px;
                    line-height: 150px;
                    text-transform: uppercase;
                    opacity: 0.4;
                    text-shadow: 2px 2px 8px 0px rgba(255, 255, 255, 1);
                    margin-bottom: -46px;
                    font-size: 100px;
                    line-height: 150px;
                    margin-bottom: -35px;
                }

                span {
                    font-size: 16px;
                }
            }


            .text {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }

    &-title {
        color: $dark-blue;
        text-align: center;
        margin: auto;
    }

    &-section-title {
        font-size: 22px;
        font-weight: 700;
        color: $dark-blue;
        text-align: center;
        flex-wrap: wrap;
        display: block;
    }

    &-howto-container {
        display: flex;
        flex-direction: column;

        .howto-card-container {
            margin: 0 auto;
            max-width: 529px;
            width: 100%;
            padding: 25px;

            .title-text {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 20px;
                text-align: center;
            }

            .subtitle-text {
                font-size: 18px;
                font-weight: 400;
                font-size: 16px;
            }

            .video-container {
                position: relative;
                margin-top: 20px;

                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 75px;
                }
            }
        }

        .divider-container {
            margin: 0 auto;
            max-width: 93%;

            .divider {
                border: 1px solid $purple;
                margin: 50px 0;
                opacity: 0.6;
            }

        }
    }

    &-footer-container {
        height: 394px;
        font-size: 22px;
        align-items: center;

        background-blend-mode: color;
        background:
            linear-gradient(159.11deg, rgba(6, 85, 165, 0.77) -4.96%, rgba(88, 44, 114, 0.77) 80.51%, rgba(206, 32, 40, 0.77) 136.63%),
            url("../../images/marketing/backgroundCloud.png");
        background-position: bottom;
        background-repeat: no-repeat;

        &.with-jets {
            background:
                linear-gradient(339.11deg, rgba(6, 85, 165, 0.77) -4.96%, rgba(88, 44, 114, 0.77) 80.51%, rgba(206, 32, 40, 0.77) 136.63%),
                url("../../images/marketing/how-to-play-buttom-bg.png");
            background-size: cover;
            background-blend-mode: color;
            background-position: center;
            background-repeat: no-repeat;
        }

        @media (min-width: $breakpoint-md) {
            background:
            linear-gradient(159.11deg, rgba(6, 85, 165, 0.77) -4.96%, rgba(88, 44, 114, 0.77) 80.51%, rgba(206, 32, 40, 0.77) 136.63%),
            url('../../images/marketing/backgroundCloud.png'),
            url('../../images/marketing/small-hero-cloud.png');
            background-position: center, bottom, bottom right;
            background-repeat: no-repeat;
        }



        &-hall-of-fame-section {
            display: flex;
            flex-direction: column;
            padding-top: 100px;
        }

        .question-col {
            margin-top: 180px;

            .question {
                font-size: 16px;
                font-weight: 400;
                color: $dark-blue;
            }
        }

        .section-title,
        .bold-title {
            font-family: 'Poppins';
            color: $dark-blue;
            font-weight: 700;
            font-size: 22px;
            line-height: 40px;
            text-transform: uppercase;
            display: block;
            width: 352px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            margin-bottom: 20px;
        }


        .btn {
            border: 0 none;
            font-size: 20px;
            border-radius: 82px;
            background: $purple;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            padding: 15px 50px;
            font-weight: bold;

            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
                color: #fff;
            }
        }


    }


    @media (min-width: $breakpoint-sm) {
        &-hero-container {
            background: url("../../images/marketing/back-how-to-play.png");
            background-size: 100% 100%;
            background-blend-mode: color;
            height: 809px;

            .hero-cloud {
                position: absolute;
                width: 500px;
                left: 0;
                top: 4%;
            }
        }

        &-logo-container {
            padding-top: 32px;
            padding-bottom: 45px;
        }

        &-bob-logo {
            width: 456px;
        }

        &-section-steps-container {
            padding: 5px;

            .steps-sub-title {
                font-size: 18px;
                width: 33%;
                padding: 1rem 1rem;

                .heading {
                    .number {
                        margin-bottom: -35px;
                    }

                    span {
                        font-size: 18px;
                    }
                }

                .text {
                    font-size: 18px;
                }
            }


        }

        &-section-title {
            font-size: 24px
        }

        .section-title,
        .bold-title {
            line-height: 60px;
        }

        &-howto-container {
            flex-direction: row;
            margin-bottom: 60px;
            padding: 10px;

            .howto-card-container {
                padding: 0 6px;
                width: 50%;

                .subtitle-text {
                    font-size: 18px;
                    height: 732px;
                }
            }

        }

        &-footer-container {
            height: 507px;

            &-hall-of-fame-section {
                padding-top: 120px;
            }
        }

    }

    @media (min-width: $breakpoint-md) {
        &-hero-container {
            background: url("../../images/marketing/back-how-to-play.png");
            background-size: 100% 100%;
            background-blend-mode: color;
            height: 785px;

            .hero-cloud {
                position: absolute;
                width: 500px;
                left: 0;
                top: 4;
            }

            .hero-cloud-right {
                width: 747px;
                position: absolute;
                right: 0;
                top: 3%;
            }
        }

        &-logo-container {
            padding-top: 115px;
            padding-bottom: 53px;
            text-align: center;

        }

        &-bob-logo {
            width: 815px;
        }

        &-section-steps-container {
            padding: 0 35px;

            .steps-sub-title {

                .heading {
                    margin-bottom: 20px;

                    .number {
                        font-size: 150px;
                        margin-bottom: -20px;
                    }

                    span {
                        font-size: 20px;
                    }
                }

                .text {
                    font-size: 18px;
                }
            }
        }

        &-section-title {
            font-size: 36px;
        }

        .section-title,
        .bold-title {
            font-size: 36px;
            line-height: 72px;
            width: 590px;
        }

        &-howto-container {
            margin: 120px 0;
            padding: 0 35px;

            .howto-card-container {
                padding: 0 10px;
                width: 25%;

                .title-text {
                    text-wrap: nowrap;
                }

                .subtitle-text {
                    height: 569px;
                }
            }

            .video-container {
                .play-button {
                    width: 107px;
                }
            }
        }

        &-footer-container {
            height: 661px;

            &-hall-of-fame-section {
                padding-top: 230px;
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        &-section-steps-container {
            padding: 0 50px;

            .steps-sub-title {
                width: 20%;

                .text {
                    font-size: 18px;
                }
            }
        }

        &-howto-container {
            padding: 0 50px;
        }

    }
}

.bold-title {
    font-weight: 700;
}