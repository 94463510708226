@import "./variables.scss";

.competitive-edge-container {
  padding: 24px 10px;

  .red-border {
    border: 1px solid $bob-red;
  }

  .blue-border {
    border: 1px solid $light-blue;
  }

  .stat-title {
    color: $purple;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: 700;
  }

  .stat-subtitle {
    font-size: 22px;
  }

  .stats-box-container {
    .stats-box {
      border-bottom: 2px solid $light-blue;
      margin: 0 30px 40px;
      padding-bottom: 40px;
    }
    .stats-box:nth-of-type(odd) {
      border-bottom: 2px solid $bob-red;
    }
  }

  .join-text-container {
    text-align: left;
    p {
      font-size: 20px;
      padding-left: 10px;
      margin: 10% 0;
      border-left: 1px solid $light-blue;
      &:nth-of-type(even) {
        border-left: 1px solid $bob-red;
      }

      @media (max-width: $breakpoint-md) {
        font-size: 16px;
      }
    }
    margin-bottom: 40px;
  }

  .join-text {
    color: $light-blue;
    font-size: 24px;
    font-family: "WorkSans";
    font-weight: 700;

    @media (max-width: $breakpoint-md) {
      font-size: 21px;
    }

    @media (max-width: $breakpoint-sm) {
      font-size: 18px;
    }
  }

  .image-container + .image-container {
    margin-top: 30px;
  }

  .ten-x-it-button {
    background-color: $purple;
    border-radius: 82px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin: 20px auto 5px;
    max-width: 314px;
    padding: 14px 50px;
    text-decoration: none;
    width: 100%;

    &:hover {
      -webkit-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      -moz-box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
      box-shadow: 0px 0px 28px 2px rgba(88, 44, 114, 0.5);
    }

    a {
      text-decoration: none;
      font-weight: 700;

      &:hover {
        color: white;
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    padding: 0 50px;
    .subtitle {
      padding-top: 20px;
    }
    .stats-box-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .stats-box {
        margin: 0;
        margin-bottom: 90px;
        width: 40%;
      }
    }

    .join-text-container {
      p {
        margin-top: 40px;
      }
    }

    .image-container + .image-container {
      margin-top: 40px;
    }

    .ten-x-it-button {
      margin: 50px auto 85px;
    }

    &.end-of-season {
      padding: 50px;
    }
  }

  @media (min-width: $breakpoint-md) {
    .subtitle {
      margin: 34px auto 63px;
      max-width: 800px;
    }

    .stats-box-container {
      .stats-box {
        width: 20%;
      }
    }

    .join-text-company-logos-container {
      display: flex;
      padding: 0 139px;
      .join-text-container {
        width: 50%;
        padding-right: 96px;
      }

      .company-logos {
        width: 50%;
      }
    }
  }
}
