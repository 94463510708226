@import "../common/variables.scss";

.sub-header-container {
  background: rgba(255, 255, 255, 0.95);
  position: sticky;
  padding-bottom: 15px;
  top: -1px;
  z-index: 3;

  &.end-of-season {
    top: 57px;
  }

  &.sticky-active {
    -webkit-box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.15);
    padding-top: 70px;
  }

  .sub-header {
    display: flex;
    flex-direction: column;
    max-width: 1675px;
    position: relative;
    margin: 0 auto;

    .property-count-container {
      display: flex;
      flex-direction: row;

      .property-count {
        font-size: 16px;
        line-height: 24px;
        align-self: center;
        margin-right: 20px;

        span {
          color: $bob-red;
        }
      }

      .submit-button {
        border: 1px solid $purple;
        background: $purple;
        color: #ffffff;
        width: 225px;
        height: 37px;
        border-radius: 31px;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
          opacity: 0.6;
          cursor: initial;
        }
      }
    }

    .round-list {
      font-size: 1.125rem;
      width: 7.5rem;
      font-weight: 600;
      color: #e1001a;

      .arrow {
        transform: rotate(90deg);
        position: relative;
        top: -17px;
        left: 110px;
        width: 0;
        height: 0;
      }
    }

    .round-property-filter-container {
      display: flex;
      white-space: nowrap;
    }

    .sub-header-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $light;
      margin: 10px auto 0;

      a {
        color: #627487;
        font-size: 26px;
        font-weight: 500;
        line-height: 30.5px;
        text-transform: uppercase;
        text-decoration: none;

        &.selected {
          color: $dark;
          font-weight: bold;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: -11px;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: $bob-red;
          }
        }
      }

      a + a {
        margin-top: 20px;
        @media (min-width: $breakpoint-sm) {
          margin-top: unset;
          margin-left: 20px;
        }
      }
    }
  }

  .sub-header:last-of-type {
    margin-bottom: initial;
  }
}

@media only screen and (min-width: 300px) {
  .sub-header-container {
    margin-top: initial;

    .sub-header {
      .property-count-container {
        justify-content: center;
        margin-top: 35px;

        .property-count {
          font-size: 14px;
        }
      }

      .round-list {
        font-size: 1.125rem;
        width: 7.5rem;
        font-weight: 600;
        color: #e1001a;

        .arrow {
          transform: rotate(90deg);
          position: relative;
          top: -17px;
          left: 110px;
          width: 0;
          height: 0;
        }
      }

      .round-property-filter-container {
        justify-content: space-around;
        margin-top: 25px;
        margin-left: 20px;
        margin-right: 20px;
      }

      .sub-header-nav {
        // flex-direction: row;
        margin: 10px auto 10px;

        a {
          font-size: 16px;
          line-height: 18.77px;
        }
      }

      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm) {
  .sub-header-container {
    &.end-of-season {
      top: 64px;
    }
    .sub-header {
      flex-direction: row;

      .property-count-container {
        flex-direction: column;
        margin-left: auto;
      }

      .sub-header-nav {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        a {
          font-size: 20px;
        }

        &.no-property-selection {
          justify-content: center;
        }

        a {
          white-space: nowrap;
          margin-right: 1.85rem;
        }
      }

      &:not(:first-of-type) {
        margin-top: initial;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-md) {
  .sub-header-container {
    margin-top: 57px;

    .sub-header {
      max-width: 1675px;

      &:first-of-type {
        flex-direction: row;
        padding-top: 40px;
        justify-content: space-between;

        button {
          line-height: 2.3em;
        }

        .sub-header-nav {
          flex-direction: row;
          text-align: center;
          margin-top: 0;

          a {
            &:first-of-type {
              margin-left: auto;
            }

            &:last-of-type {
              margin-right: auto;
            }
          }
        }
      }
    }

    &.end-of-season {
      margin-top: 0;
      top: 0;
      &.sticky-active {
        padding-top: 0;
      }
      .sub-header {
        &:first-of-type {
          padding-top: 80px;
        }
      }
    }
  }
}
