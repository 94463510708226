@import "variables.scss";


.error-screen-container {
    position: absolute;
    top     : 20%;
    left    : 15%;
    color   : $light;

    h2 {
        font-size: 36px;
    }

    div {
        font-size: 20px;
        margin-bottom: 2rem;
    }

    button {
        border: 0 none;
        background: transparent;
        color: $light;
        text-decoration: underline;
        padding: 0;
        font-size: 18px;
    }

}