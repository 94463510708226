@import "./variables.scss";

.end-of-season-container {
  background: linear-gradient(
    300.01deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(206, 32, 40, 0.15) 15.16%,
    rgba(6, 85, 165, 0.4) 100%
  );

  background-color: white;
  padding: 100px 10px;
  position: relative;
  text-align: center;

  .logo-container {
    margin: 0 auto;
    width: fit-content;
    position: relative;
  }

  .bob-logo {
    max-width: 1100px;
    width: 95%;
  }

  .season-2-number {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    z-index: 1;
  }

  .subtitle {
    background: linear-gradient(91.64deg, #0054a6 -5.88%, #ce2028 119.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    font-weight: 700;
  }

  .bob-logo,
  .subtitle {
    position: relative;
    z-index: 2;
  }

  .winner-container {
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 800px;
    text-align: left;
    p {
      margin-bottom: 0;
    }

    .player-highlight {
      display: flex;
      margin: 20px 0;

      .headshot-container {
        height: fit-content;
        position: relative;
        max-width: 25%;
        width: fit-content;

        .play-button {
          cursor: pointer;
          position: absolute;
          bottom: -10px;
          right: 0;
          width: 35px;
        }
      }

      .player-headshot {
        max-height: 200px;
        width: auto;
      }

      .player-content {
        margin-left: 10px;
      }

      .title {
        color: $bob-red;
        font-size: 20px;
      }

      .name,
      .company {
        color: $dark-blue;
        font-weight: 700;
      }

      .name {
        font-size: 28px;
      }

      .company {
        font-size: 16px;
      }
    }

    .excerpt {
      margin-bottom: 20px;
      &.tablet {
        display: none;
      }
    }

    .stats {
      display: flex;

      div + div {
        border-left: 0.8px solid #0054a696;
      }

      div {
        background: linear-gradient(90deg, #0054a6 0, #ce2028 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 33%;
        p {
          display: inline-block;
          text-align: left;
        }
      }
      .padding-left {
        padding-left: 20px;
      }
    }
  }

  .season-2-button {
    background-color: $purple;
    border-radius: 82px;
    color: white;
    display: block;
    font-weight: 700;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 300px;
    padding: 20px 50px;
    text-decoration: none;
  }

  @media (min-width: $breakpoint-sm) {
    .season-2-number {
      max-width: 500px;
    }
    .winner-container {
      margin-bottom: 55px;
      .excerpt {
        margin-bottom: 20px;
        display: none;
        &.tablet {
          display: block;
        }
      }

      .player-highlight {
        .headshot-container {
          flex-shrink: 0;
          max-width: 33%;

          .play-button {
            width: 50px;
          }
        }

        .title {
          font-size: 22px;
        }
        .name {
          font-size: 35px;
        }
        .company {
          font-size: 20px;
        }

        .title,
        .name,
        .company {
          line-height: 40px;
        }
      }

      .stats {
        justify-content: space-between;
        .charity {
          max-width: 200px;
        }
        .padding-left {
          padding-left: 80px;
        }
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .winner-container {
      margin-bottom: 60px;
      .player-highlight {
        .title {
          font-size: 36px;
        }
        .name {
          font-size: 50px;
        }
        .company {
          font-size: 30px;
        }
        .title,
        .name,
        .company {
          line-height: 45px;
        }
      }
    }
  }
}
