@import "../common/variables.scss";

#lottery-container {

    .jets {
        display: none;
    }

    .leaderboard-table-container {
        display: flex;
        margin: 0 auto;
        overflow-x: scroll;
        padding-bottom: 16px;
        width: calc(100% - 20px);
        &.with-jets {
            display: block;
        }

        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
            background: #D8D8D8;
        }

        &::-webkit-scrollbar-thumb {
            background: $bob-red;
        }

        .what-is-content-container {
            h2 {
                font-weight: bold;
            }

            align-self: center;
            display: none;
            max-width: 650px;
            margin: 0 auto;
            padding: 0px 50px;

            @media (min-width: $breakpoint-md) {
                display: block;
            }
        }
    }

    .section-title {
        color: $bob-red;
        font-weight: 700;
        padding-left: 14px;
    }

    table {
        min-width: 800px;
    }

    thead {
        th {
            padding-bottom: 30px;
            padding-right: 30px;
        }
        .player-header, .company-header {
            padding-right: 120px;
        }
    }

    tbody {
        border-bottom: 2px solid $light-blue;
        border-top: 2px solid $light-blue;
    }

    .table-row {
        border-top: 1px solid rgba(0, 84, 166, 0.3);
    }

    .round-cell, .player-cell, .company-cell, .winnings-cell {
        padding: 15px 0;
    }

    .round-cell, .round-header {
        padding-left: 5px;
    }

    @media (min-width: $breakpoint-sm) {
        background-image: url('../../images/marketing/prizes-cloud-right.png');
        background-position: top -50px right -50px;
        background-repeat: no-repeat;
        background-size: 400px;

        .leaderboard-table-container {
            overflow: hidden;
        }
        table {
            min-width: unset;
        }
    }

    @media (min-width: $breakpoint-md) {
        background-position: bottom right;

        .leaderboard-table-container {
            overflow: hidden;
            position: relative;

            table {
                margin: 0;
                width: 50%;
            }
        }

        .jets {
            display: block;
            position: absolute;
            right: 0;
            top: 70px;
            width: 50%;
            transform: translate(0%, 10%);
        }
    }

    @media (min-width: $breakpoint-lg) {
        .section-title {
            padding-left: 100px;
        }

        .jets {
            top: 30px;
        }
    }
}