// Put values we will use frequently in here
// import it into your specific less file

$medium-blue: #0056b6;
$link-blue: #0059b3;
$light-blue: #0054a6;
$dark-blue: #24335a;
$purple: #582c72;

$bob-red: #ce2028;
$active-col-highlight: #0d423a;

$dark-gradient-text-bg: linear-gradient(
  270.62deg,
  #ce2028 -27.17%,
  #582c72 46.93%,
  #0054a6 106.73%
);
$light-gradient-text-bg: linear-gradient(283.12deg, #ce2028 22.8%, #0655a5 72.43%);

$background-black: #081426;
$transparent-white: rgba(255, 255, 255, 0.5);

// the greys don't mean much right now because we have so many. You can use these vars but they aren't very useful right now
$accent-grey-a: #e5e5e5;
$accent-grey-b: #c8c8c8;
$accent-grey-c: #e1e1e1;
$accent-grey-d: #e7e7e7;

$dark-grey: #9f9f9f;

$light: #ffffff;
$dark: #000000;

$table-width-small: 1242px;
$table-width: 1372px;

$season-table-width-desktop: 1395px;

$body-max-width: 1918px;

// breakpoint variables
$scoreboard-breakpoint1: 660px;
$scoreboard-breakpoint2: 940px;
$scoreboard-breakpoint3: 1225px;
$scoreboard-breakpoint4: 1400px;
$scoreboard-breakpoint5: 1600px;
$scoreboard-breakpoint6: 1880px;

$scoreboard-edit-buttons-breakpoint1: 610px;

$header-breakpoint1: 1025px;
$header-breakpoint2: 769px;

$subheader-breakpoint1: 645px;

$leaderboard-breakpoint1: 650px;

$video-update-breakpoint1: 1600px;
$video-update-breakpoint2: 1440px;
$video-update-breakpoint3: 1201px;
$video-update-breakpoint4: 1030px;
$video-update-breakpoint5: 992px;
$video-update-breakpoint6: 768px;
$video-update-breakpoint7: 600px;

$about-tenx-breakpoint1: 1600px;
$about-tenx-breakpoint2: 1440px;
$about-tenx-breakpoint3: 1201px;
$about-tenx-breakpoint4: 1030px;
$about-tenx-breakpoint5: 992px;
$about-tenx-breakpoint6: 768px;
$about-tenx-breakpoint7: 600px;
$about-tenx-breakpoint8: 500px;
$about-tenx-breakpoint9: 400px;
$about-tenx-breakpoint10: 300px;

$leadBot-breakpoint1: 768px;

$breakpoint-xs: 375px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1440px;
