@import "./variables.scss";

.watch-live-container {
  margin-top: 55px;

  .hero {
    background-image: linear-gradient(
        345.68deg,
        rgba(206, 32, 40, 0.4) -16.47%,
        rgba(88, 44, 114, 0.4) 45.01%,
        rgba(52, 137, 255, 0.137) 104.45%
      ),
      url("../../images/marketing/watch-now-bg.png");
    padding: 28px 10px;
    position: relative;
    text-align: center;

    .hero-video-container {
      margin: 0 auto;
      max-width: 700px;
    }

    .bob-logo,
    .title,
    .subtitle {
      position: relative;
      z-index: 2;
    }

    .cloud {
      left: 0;
      mix-blend-mode: luminosity;
      position: absolute;
      z-index: 0;
      width: 100%;
      max-width: 300px;
    }

    .second-cloud {
      display: none;
    }
  }

  .bob-logo {
    margin-bottom: 20px;
    max-width: 270px;
  }

  .title {
    margin: 0 auto;
    max-width: 270px;
    padding-bottom: 14px;
  }

  .previous-round-container {
    padding: 44px 10px;
    text-align: center;

    .title {
      font-size: 24px;
      margin: 0;
      max-width: 300px;
      text-align: left;
    }

    .date {
      text-align: left;
      padding-bottom: 20px;
    }
  }

  .video-player-container {
    .title {
      padding-bottom: 12px;
    }
    .title, .description {
      font-size: 16px;
      text-align: left;
    }
  }

  .game-recap-container {
    background-image: linear-gradient(
      112deg,
      rgba(185, 223, 255, 0.6699054621848739) 31%,
      rgba(197, 185, 255, 0.4822303921568627) 56%,
      rgba(255, 175, 178, 0.5466561624649859) 89%
    );
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    height: 475px;
    padding: 44px 10px;
    text-align: center;
    position: relative;

    .button {
      background-color: $purple;
      border-radius: 80px;
      color: white;
      font-weight: 700;
      margin: 0 auto;
      max-width: 300px;
      padding: 20px 40px;
    }

    .jet {
      bottom: 30px;
      left: 50%;
      margin-top: 60px;
      max-width: 300px;
      mix-blend-mode: luminosity;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  @media (min-width: $breakpoint-sm) {
    .bob-logo {
      max-width: 460px;
      padding-bottom: 54px;
    }

    .hero {
      padding: 28px 20px;

      .cloud {
        max-width: 600px;
      }

      .second-cloud {
        bottom: 100px;
        display: block;
        left: 0;
        position: absolute;
        z-index: 0;
      }
    }

    .title {
      max-width: unset;
      padding-bottom: 25px;
    }

    .previous-round-container {
      .title {
        max-width: unset;
      }
    }

    .video-section-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > :nth-child(odd) {
        margin-right: 5px;
      }
      > :nth-child(even) {
        margin-left: 5px;
      }
    }

    .video-player-container {
      width: calc(50% - 10px);
    }

    .game-recap-container {
      display: flex;
      flex-direction: row-reverse;
      height: unset;
      justify-content: space-between;
      padding-bottom: 70px;

      .content-container {
        align-self: start;
        margin-left: 15px;
        max-width: 450px;
        margin: 0 auto;
        width: calc(50% - 15px);
      }

      .jet {
        align-self: start;
        bottom: unset;
        height: auto;
        left: unset;
        margin-right: 15px;
        margin-top: unset;
        max-width: unset;
        position: unset;
        transform: unset;
        width: calc(50% - 15px);
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .bob-logo {
      max-width: 816px;
    }

    .hero {
      padding: 56px 20px;
    }

    .subtitle {
      margin: 0 auto;
      max-width: 780px;
      padding-bottom: 54px;
    }

    .video-player-container {
      width: calc(33% - 15px);
    }
  }
}
