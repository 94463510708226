@import '../common/variables.scss';
.video-player {
    margin-bottom: 20px;

    .thumbnail-container {
        position: relative;   
    }

    .thumbnail-image {
        width: 100%;
        cursor: pointer;
    }

    .play-button {
        cursor: pointer;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
    }
    
    .yt-video {
        height: 354px;
        margin: 0 auto;
        max-width: 700px;
        width: 100%;
    }

    @media (min-width: $breakpoint-md) {
        .play-button {
            width: 128px;
        }
    }
}