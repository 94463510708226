@import "../common/variables.scss";

/* ANIMATIONS */

.anim-row-new-bid {
  animation: row-purple-flash 1s 3 linear; // repeats 3 times
}

.anim-cell-text-flash {
  font-weight: 800;
  animation: text-flash 1s infinite linear;
}

.anim-cell-point-increase {
  animation: text-green-to-white 120s infinite step-end;
}

.anim-cell-point-decrease {
  animation: text-red-to-white 120s infinite step-end;
}

.anim-row-status-sold {
  animation: row-purple-flash 1s 3 linear; // repeats 3 times
}

.anim-cell-status-time-extended-reserve-met {
  .status-content {
    display: none;
  }

  div::after {
    content: "Reserve met";
    animation: text-flash-reserve-met 1s linear infinite alternate;
  }
}

.anim-cell-status-time-extended-reserve-not-met {
  .status-content {
    display: none;
  }

  div::after {
    content: "Reserve not met";
    animation: text-flash-reserve-not-met 1s linear infinite alternate;
  }
}

.anim-cell-status-time-extended-next-bid-meets-reserve {
  .status-content {
    display: none;
  }

  div::after {
    content: "Next bid meets reserve";
    animation: text-flash-bid-meets-reserve 1s linear infinite alternate;
  }
}

.post-anim-row-status-not-sold {
  > *,
  .property-address a {
    color: #1f1f1f !important;
  }
  background-color: #c8c8c8;
}

.post-anim-row-status-sold {
  background-color: #e0d5e7 !important;
}

/* Animation Keyframes  */
@keyframes row-purple-flash {
  0% {
    background-color: white;
  }

  50% {
    background-color: #e0d5e7;
  }

  100% {
    background-color: white;
  }
}

@keyframes text-flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text-flash-reserve-met {
  0% {
    content: "Time Extended";
    color: #e50300;
  }

  50% {
    color: transparent;
  }

  100% {
    content: "Reserve met";
    color: #73e593;
  }
}

@keyframes text-flash-reserve-not-met {
  0% {
    content: "Time Extended";
    color: #e50300;
  }

  50% {
    color: transparent;
  }

  100% {
    content: "Reserve not met";
    color: #fff;
  }
}

@keyframes text-flash-bid-meets-reserve {
  0% {
    content: "Time Extended";
    color: #e50300;
  }

  50% {
    color: transparent;
  }

  100% {
    content: "Next bid meets reserve";
    color: #9ce7ff;
  }
}

@keyframes text-green-to-white {
  from {
    color: #73e593;
  }

  to {
    color: #fff;
  }
}

@keyframes text-red-to-white {
  from {
    color: #ce2028;
  }

  to {
    color: #fff;
  }
}
